import React, { useState } from "react";
import {
  IconButton,
  IconButtonProps,
  Tooltip,
  ClickAwayListener,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import ErrorIcon from "@material-ui/icons/Error";

const useStyles = makeStyles((theme) => ({
  deleteIcon: {
    color: theme.palette.text.hint,
    "&:hover": {
      color: theme.palette.error.main,
    },
  },
}));

const DeleteButton: React.FC<IconButtonProps> = (props) => {
  const classes = useStyles();
  const [count, setCount] = useState(0);

  return (
    <ClickAwayListener onClickAway={() => setCount(0)}>
      <IconButton
        className={classes.deleteIcon}
        {...props}
        onClick={(evt) => {
          evt.stopPropagation();
          if (count < 1) {
            setCount(count + 1);
            return;
          }
          props.onClick?.(evt);
        }}
      >
        {count === 0 && <DeleteIcon />}
        {count > 0 && (
          <Tooltip title="再次点击确认删除">
            <ErrorIcon color="error" />
          </Tooltip>
        )}
      </IconButton>
    </ClickAwayListener>
  );
};

export default DeleteButton;
