import {
  Collapse,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  makeStyles,
  Switch,
  TextField,
  Avatar,
} from "@material-ui/core";
import LockIcon from "@material-ui/icons/Lock";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import React, { useState } from "react";
import {
  OkrsQuery,
  useUpdateOneOkrMutation,
  useUsersQuery,
} from "../../generated/graphql";
import { rawStrToPlainText } from "../../lib/draft-js";

interface EditorProps {
  okrId: string;
  objectives: OkrsQuery["okrs"][0]["objectives"];
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: 300,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  userSelector: {
    marginTop: -20,
    minWidth: 170,
  },
  iconO: {
    borderRadius: 10,
    height: 20,
    fontSize: 17,
    backgroundColor: "#a97cd7",
    marginRight: 5,
  },
  iconKr: {
    borderRadius: 10,
    height: 20,
    fontSize: 17,
    backgroundColor: "#969bd9",
    marginRight: 5,
  },
}));

const PermissionEditor = (props: EditorProps) => {
  const { okrId, objectives } = props;
  const [open, setOpen] = useState(false);

  const classes = useStyles();

  const { data } = useUsersQuery();
  const [updateOkr] = useUpdateOneOkrMutation();

  return (
    <>
      <IconButton size="small" onClick={() => setOpen(true)}>
        <LockIcon />
      </IconButton>
      <Drawer
        anchor="right"
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{
          style: {
            width: 700,
          },
        }}
      >
        <List subheader={<ListSubheader>OKR 权限配置</ListSubheader>}>
          {[...objectives]
            .sort((a, b) => a.order - b.order)
            .map((objective, idx) => (
              <div key={objective.id}>
                <ListItem>
                  <Avatar className={classes.iconO}>
                    {"O" + (idx + 1).toString()}
                  </Avatar>
                  <ListItemText
                    id={objective.id}
                    primary={rawStrToPlainText(objective.description)}
                  />
                  <ListItemSecondaryAction>
                    <Switch
                      edge="end"
                      onChange={(e) =>
                        updateOkr({
                          variables: {
                            where: { id: okrId },
                            data: {
                              privacy: {
                                objectives: [
                                  {
                                    id: objective.id,
                                    isPrivate: e.target.checked,
                                    visibleToUserIds: [],
                                  },
                                ],
                              },
                            },
                          },
                        })
                      }
                      checked={objective.private}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
                {objective.private && (
                  <ListItem>
                    <Autocomplete
                      className={classes.userSelector}
                      multiple
                      size="small"
                      options={
                        data?.users.map((u) => ({
                          id: u.id,
                          name: u.name,
                          email: u.email,
                          pinyinName: u.pinyinName,
                        })) || []
                      }
                      getOptionLabel={(user) => user.name}
                      getOptionSelected={(option, value) =>
                        option.id === value.id
                      }
                      value={objective.visibleToUsers.map((u) => ({
                        id: u.id,
                        name: u.name,
                        email: u.email,
                        pinyinName: u.pinyinName,
                      }))}
                      renderInput={(params) => (
                        <TextField {...params} placeholder="选择可见用户" />
                      )}
                      filterOptions={createFilterOptions({
                        stringify: (option) =>
                          `${option.email || ""}${option.name || ""}${
                            option.pinyinName || ""
                          }`,
                      })}
                      onChange={(e, selectedUsers) =>
                        updateOkr({
                          variables: {
                            where: { id: okrId },
                            data: {
                              privacy: {
                                objectives: [
                                  {
                                    id: objective.id,
                                    isPrivate: objective.private,
                                    visibleToUserIds: selectedUsers.map(
                                      (u) => u.id
                                    ),
                                  },
                                ],
                              },
                            },
                          },
                        })
                      }
                    />
                  </ListItem>
                )}
                <Collapse in={true}>
                  {objective.keyResults.map((keyresult, idx) => (
                    <List key={keyresult.id}>
                      <ListItem className={classes.nested}>
                        <Avatar variant="square" className={classes.iconKr}>
                          {`K${idx + 1}`}
                        </Avatar>
                        <ListItemText
                          id={keyresult.id}
                          primary={rawStrToPlainText(keyresult.description)}
                        />
                        <ListItemSecondaryAction>
                          <Switch
                            edge="end"
                            onChange={(e) =>
                              updateOkr({
                                variables: {
                                  where: { id: okrId },
                                  data: {
                                    privacy: {
                                      keyResults: [
                                        {
                                          id: keyresult.id,
                                          isPrivate: e.target.checked,
                                          visibleToUserIds: [],
                                        },
                                      ],
                                    },
                                  },
                                },
                              })
                            }
                            checked={keyresult.private}
                          />
                        </ListItemSecondaryAction>
                      </ListItem>
                      {keyresult.private && (
                        <ListItem className={classes.nested}>
                          <Autocomplete
                            className={classes.userSelector}
                            multiple
                            size="small"
                            options={
                              data?.users.map((u) => ({
                                id: u.id,
                                name: u.name,
                                email: u.email,
                                pinyinName: u.pinyinName,
                              })) || []
                            }
                            getOptionLabel={(user) => user.name}
                            getOptionSelected={(option, value) =>
                              option.id === value.id
                            }
                            value={keyresult.visibleToUsers.map((u) => ({
                              id: u.id,
                              name: u.name,
                              email: u.email,
                              pinyinName: u.pinyinName,
                            }))}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="选择可见用户"
                              />
                            )}
                            filterOptions={createFilterOptions({
                              stringify: (option) =>
                                `${option.email || ""}${option.name || ""}${
                                  option.pinyinName || ""
                                }`,
                            })}
                            onChange={(e, selectedUsers) =>
                              updateOkr({
                                variables: {
                                  where: { id: okrId },
                                  data: {
                                    privacy: {
                                      keyResults: [
                                        {
                                          id: keyresult.id,
                                          isPrivate: keyresult.private,
                                          visibleToUserIds: selectedUsers.map(
                                            (u) => u.id
                                          ),
                                        },
                                      ],
                                    },
                                  },
                                },
                              })
                            }
                          />
                        </ListItem>
                      )}
                    </List>
                  ))}
                </Collapse>
                <Divider />
              </div>
            ))}
        </List>
      </Drawer>
    </>
  );
};

export default PermissionEditor;
