import React, { useLayoutEffect, useState } from "react";
import {
  Drawer,
  makeStyles,
  Box,
  Typography,
  IconButton,
  Avatar,
  FormControlLabel,
  Checkbox,
  ClickAwayListener,
  Divider,
} from "@material-ui/core";
import EditableText, { Raw } from "../../components/EditableText";
import CloseIcon from "@material-ui/icons/Close";
import { useAuth } from "../../lib/auth";
import {
  useCreateOneCommentMutation,
  useCommentsQuery,
  CommentsQuery,
} from "../../generated/graphql";
import { COMMENT_HINT } from "../../constants";
import CommentToolbar from "./CommentToolbar";
import { useQueryParam, StringParam } from "use-query-params";
import { resolveComments } from "./resolve-comments";
import { rawStrToPlainText } from "../../lib/draft-js";

export type LocalComment = {
  type: "objectiveDescription" | "keyResultDescription" | "reviewDescription";
  targetId: string;
  rawState: Raw;
};

export const DRAWER_WIDTH = 300;

const useStyles = makeStyles((theme) => ({
  root: { width: DRAWER_WIDTH, height: "100%" },
  header: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  main: {
    flex: 1,
    overflow: "auto",
  },
}));

const useCardStyles = makeStyles((theme) => ({
  commentsCard: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    "&.active": {
      position: "relative",
      "&::before": {
        content: `""`,
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        height: 4,
        backgroundColor: theme.palette.primary.main,
        borderTopLeftRadius: theme.shape.borderRadius,
        borderTopRightRadius: theme.shape.borderRadius,
      },
    },
    "&:last-of-type": {
      marginBottom: theme.spacing(2),
    },
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  quote: {
    borderLeft: `4px solid ${theme.palette.grey[400]}`,
  },
  commentContentFlag: {
    width: "2px",
    height: "16px",
    marginRight: "4px",
    background: "#ccc",
  },
  commentContentContainer: {
    width: "100%",
    height: "20px",
    overflow: "hidden",
    margin: "2px 0",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
}));

const CommentsCard: React.FC<{
  comments?: CommentsQuery["comments"];
  okr?: {
    id: string;
    authorId: string;
  };
  localComment?: LocalComment;
  onCancelLocal?: () => void;
  onFocus?: () => void;
  active?: boolean;
  commentContent?: string;
}> = ({
  localComment,
  comments,
  onCancelLocal,
  onFocus,
  active,
  okr,
  commentContent,
}) => {
  const classes = useCardStyles();
  const { user } = useAuth();
  const [createOneComment] = useCreateOneCommentMutation({
    refetchQueries: ["comments"],
    awaitRefetchQueries: true,
  });

  return (
    <Box
      className={`${classes.commentsCard} ${active ? "active" : ""}`}
      mt={2}
      mx={2}
      p={1}
      onClick={onFocus}
    >
      {localComment && (
        <>
          <Box display="flex" alignItems="center">
            <Avatar src={user?.avatar || ""} className={classes.avatar} />
            <Box mx={1}>
              <Typography variant="body2">{user?.name}</Typography>
            </Box>
          </Box>
          <Box mt={1}>
            <EditableText
              placeholder={COMMENT_HINT}
              rawStr=""
              onBlur={(raw) => {
                if (raw.blocks.every((block) => !block.text)) {
                  onCancelLocal?.();
                }
              }}
              autoFocus
              allowMention
              onSubmit={async (raw) => {
                await createOneComment({
                  variables: {
                    data: {
                      content: JSON.stringify(raw),
                      keyResult:
                        localComment.type === "keyResultDescription"
                          ? {
                              connect: {
                                id: localComment.targetId,
                              },
                            }
                          : undefined,
                      objective:
                        localComment.type === "objectiveDescription"
                          ? {
                              connect: {
                                id: localComment.targetId,
                              },
                            }
                          : undefined,
                      review:
                        localComment.type === "reviewDescription"
                          ? {
                              connect: {
                                id: localComment.targetId,
                              },
                            }
                          : undefined,
                    },
                    effect: {
                      commentContent: JSON.stringify(localComment.rawState),
                    },
                  },
                });
                onCancelLocal?.();
              }}
              onFocus={onFocus}
              resetAfterSubmit
            />
          </Box>
        </>
      )}
      {comments &&
        comments.map((comment, idx) => (
          <Box key={comment.id} mb={1}>
            {idx !== 0 && (
              <Box mb={2} mt={1}>
                <Divider />
              </Box>
            )}
            {commentContent && !comment.previousComment && (
              <Box display="flex" alignItems="center" mb={1}>
                <Box className={classes.commentContentFlag}></Box>
                <Box className={classes.commentContentContainer}>
                  {rawStrToPlainText(commentContent)}
                </Box>
              </Box>
            )}
            <Box display="flex" alignItems="center">
              <Avatar
                src={comment.author?.avatar || ""}
                className={classes.avatar}
              />
              <Box mx={1}>
                <Typography variant="body2">{comment.author?.name}</Typography>
              </Box>
              {comment && (
                <Typography variant="caption" color="textSecondary">
                  {new Date(comment.createdAt).toLocaleString()}
                </Typography>
              )}
            </Box>
            {(comment.author?.id === user?.id ||
              okr?.authorId === user?.id) && (
              <CommentToolbar
                flex="1"
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
                comment={comment}
              />
            )}
            <Box mt={1}>
              <EditableText
                readOnly
                rawStr={comment.content || ""}
                onFocus={onFocus}
                allowMention
              />
            </Box>
          </Box>
        ))}
      {comments && (
        <Box mt={1}>
          {active && (
            <Box mt={1}>
              <EditableText
                placeholder="回复"
                rawStr=""
                autoFocus
                allowMention
                onSubmit={async (raw) => {
                  await createOneComment({
                    variables: {
                      data: {
                        content: JSON.stringify(raw),
                        comment: {
                          connect: {
                            id: comments[comments.length - 1].id,
                          },
                        },
                        okr: {
                          connect: {
                            id: okr?.id,
                          },
                        },
                      },
                    },
                  });
                }}
                onFocus={onFocus}
                resetAfterSubmit
              />
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

const getOrderAndCommentContent = (comment: CommentsQuery["comments"][0]) => {
  let order: number = 1;
  let commentContent: string = "";
  if (comment.objective) {
    order = (comment.objective.order || 1) * 1000;
    commentContent = comment.objective.description || "";
  } else if (comment.keyResult) {
    order =
      (comment.keyResult.objective?.order || 1) * 1000 +
      (comment.keyResult.order || 1) * 10;
    commentContent = comment.keyResult.description || "";
  } else if (comment.review && comment.review.objective) {
    // objective's review
    order =
      (comment.review.objective.order || 1) * 1000 +
      comment.review.objective.keyResults.length * 10 +
      1;
    commentContent = comment.review.description || "";
  } else if (comment.review && comment.review.keyResult) {
    // keyResult's review
    order =
      (comment.review.keyResult.objective?.order || 1) * 1000 +
      (comment.review.keyResult.order || 1) * 10 +
      1;
    commentContent = comment.review.description || "";
  }

  return {
    order,
    commentContent,
  };
};

const getSortedCommentGroups = (
  commentsGroups: {
    solved: boolean;
    comments: CommentsQuery["comments"];
  }[]
) => {
  return commentsGroups
    .map((group) => {
      // 回复评论的评论有 previous，所以根据没有 previous 的评论（评论 okr 本身）进行排序
      const orderTargetComment = group.comments.find((v) => !v.previousComment);
      if (orderTargetComment) {
        const { order, commentContent } = getOrderAndCommentContent(
          orderTargetComment
        );
        return {
          order,
          commentContent,
          comments: group.comments,
        };
      } else {
        return {
          order: Infinity,
          commentContent: "",
          comments: group.comments,
        };
      }
    })
    .sort((a, b) => a.order - b.order);
};

const CommentList: React.FC<{
  okr: {
    id: string;
    authorId: string;
  };
  open: boolean;
  onClose?: () => void;
  localComment?: LocalComment | null;
  onCancelLocal?: () => void;
}> = ({ localComment, onCancelLocal, okr, open, onClose }) => {
  const classes = useStyles();
  const [activeKey, setActiveKey] = useQueryParam(
    "active-comment",
    StringParam
  );
  const [showSolved, setShowSolved] = useState(false);

  useLayoutEffect(() => {
    setTimeout(() => {
      document.querySelector(`[data-key="${activeKey}"]`)?.scrollIntoView({
        behavior: "smooth",
      });
      // FIXME: router
    }, 10);
  }, [activeKey]);

  const { data } = useCommentsQuery({
    variables: {
      where: {
        OR: [
          {
            objective: {
              okr: {
                id: {
                  equals: okr.id,
                },
              },
            },
          },
          {
            keyResult: {
              objective: {
                okr: {
                  id: {
                    equals: okr.id,
                  },
                },
              },
            },
          },
          {
            review: {
              objective: {
                okr: {
                  id: {
                    equals: okr.id,
                  },
                },
              },
            },
          },
          {
            review: {
              keyResult: {
                objective: {
                  okr: {
                    id: {
                      equals: okr.id,
                    },
                  },
                },
              },
            },
          },
        ],
      },
    },
    fetchPolicy: "cache-and-network",
    pollInterval: 60_000,
  });
  const { data: followingData } = useCommentsQuery({
    skip: !data,
    variables: {
      where: {
        okr: {
          id: {
            equals: okr.id,
          },
        },
        previousCommentId: {
          not: null,
        },
      },
    },
    fetchPolicy: "cache-and-network",
    pollInterval: 60_000,
  });
  const commentsMatrix = resolveComments(
    (data?.comments || [])
      .concat(followingData?.comments || [])
      .map((comment) => ({
        ...comment,
        id: comment.id || "",
        previousId: comment.previousComment?.id,
        solvedAt: comment.solvedAt,
      }))
  );

  const commentsGroups = showSolved
    ? commentsMatrix
    : commentsMatrix.filter((v) => !v.solved);

  const count =
    commentsGroups.reduce((prev, cur) => (prev += cur.comments.length), 0) +
    (localComment ? 1 : 0);

  return (
    <Drawer
      className={classes.root}
      variant="persistent"
      anchor="right"
      open={open}
      PaperProps={{
        style: {
          width: DRAWER_WIDTH,
          top: 64,
          bottom: 0,
          height: "auto",
        },
      }}
    >
      <Box
        className={classes.header}
        display="flex"
        p={1}
        alignItems="center"
        justifyContent="space-between"
      >
        <Box display="flex" alignItems="center">
          <Typography>评论（{count}）</Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={showSolved}
                onChange={(evt) => setShowSolved(evt.currentTarget.checked)}
              />
            }
            label="显示已解决"
          />
        </Box>
        <IconButton size="small" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box className={classes.main}>
        {localComment && (
          <ClickAwayListener
            onClickAway={() => {
              if (activeKey === "local") {
                setActiveKey("", "replaceIn");
              }
            }}
          >
            <Box data-key="local">
              <CommentsCard
                localComment={localComment}
                onCancelLocal={onCancelLocal}
                okr={okr}
                active={activeKey === "local"}
                onFocus={() => setActiveKey("local", "replaceIn")}
              />
            </Box>
          </ClickAwayListener>
        )}
        {getSortedCommentGroups(commentsGroups).map((commentGroup) => (
          <ClickAwayListener
            key={commentGroup.comments[0].id}
            onClickAway={() => {
              if (activeKey === commentGroup.comments[0].id) {
                setActiveKey("", "replaceIn");
              }
            }}
          >
            <Box data-key={commentGroup.comments[0].id}>
              <CommentsCard
                comments={commentGroup.comments}
                okr={okr}
                onCancelLocal={onCancelLocal}
                active={activeKey === commentGroup.comments[0].id}
                onFocus={() =>
                  setActiveKey(commentGroup.comments[0].id!, "replaceIn")
                }
                commentContent={commentGroup.commentContent}
              />
            </Box>
          </ClickAwayListener>
        ))}
      </Box>
    </Drawer>
  );
};

export default CommentList;
