import React, {
  useState,
  useRef,
  useCallback,
  useMemo,
  useEffect,
  useLayoutEffect,
} from "react";
import { IconButton, Box, Button, makeStyles } from "@material-ui/core";
import Editor, {
  EditorPlugin,
  PluginFunctions,
} from "@draft-js-plugins/editor";
import createInlineToolbarPlugin from "@draft-js-plugins/inline-toolbar";
import createMentionPlugin from "@draft-js-plugins/mention";
import createLinkifyPlugin from "@draft-js-plugins/linkify";
import {
  EditorState,
  convertToRaw,
  convertFromRaw,
  ContentState,
  getDefaultKeyBinding,
  KeyBindingUtil,
  DraftHandleValue,
  Modifier,
  CompositeDecorator,
  SelectionState,
  DraftDecorator,
  RichUtils,
  ContentBlock,
  EditorBlock,
  DefaultDraftBlockRenderMap,
} from "draft-js";
import "draft-js/dist/Draft.css";
import "@draft-js-plugins/inline-toolbar/lib/plugin.css";
import "@draft-js-plugins/mention/lib/plugin.css";
import { useUsersQuery } from "../generated/graphql";
import CommentOutlinedIcon from "@material-ui/icons/CommentOutlined";
import LinkIcon from "@material-ui/icons/Link";
import { emitter } from "../lib/emitter";
import { useQueryParam, StringParam } from "use-query-params";
import {
  ItalicButton,
  BoldButton,
  UnderlineButton,
  CodeBlockButton,
} from "@draft-js-plugins/buttons";
import { Map } from "immutable";
import AddLinkDialog from "./AddLinkDialog";

export type Raw = ReturnType<typeof convertToRaw>;

function convertFromRawStr(rawStr: string): ContentState {
  try {
    return convertFromRaw(JSON.parse(rawStr));
  } catch (error) {
    return ContentState.createFromText(rawStr);
  }
}

const { hasCommandModifier } = KeyBindingUtil;

function keyBindingFnEditor(evt: React.KeyboardEvent<Element>): string | null {
  if (
    hasCommandModifier(
      (evt as unknown) as Parameters<typeof hasCommandModifier>[0]
    )
  ) {
    switch (evt.key) {
      case "Enter":
        return "custom-submit";
      case "b":
        return "bold";
      case "i":
        return "italic";
      case "u":
        return "underline";
      default:
        break;
    }
  }
  return getDefaultKeyBinding(
    (evt as unknown) as Parameters<typeof hasCommandModifier>[0]
  );
}

function readOnlyKeyBindingFn(
  evt: React.KeyboardEvent<Element>
): string | null {
  // 保留只读情况下的复制功能
  if (
    hasCommandModifier(
      (evt as unknown) as Parameters<typeof hasCommandModifier>[0]
    ) &&
    evt.key === "c"
  ) {
    return getDefaultKeyBinding(
      (evt as unknown) as Parameters<typeof hasCommandModifier>[0]
    );
  }
  return "not-handled-command";
}

const useCommentStyles = makeStyles(() => ({
  comment: {
    background: "rgba(255, 235, 59, 0.3)",
    cursor: "pointer",
    position: "relative",
    zIndex: 1,
    "&:hover": {
      background: "rgba(255, 235, 59, 1)",
    },
    "&.active": {
      background: "rgba(255, 235, 59, 1)",
      zIndex: 3,
    },
  },
  customToolbar: {
    display: "flex",
    alignItems: "center",
  },
  customerInlineToolbar: {
    color: "#888",
    width: "36px",
    height: "34px",
    borderRadius: 0,
  },
  link: {
    color: "#646BD9",
    cursor: "pointer",
  },
}));

const CommentSpan: React.FC<{
  contentState?: ContentState;
  entityKey?: string;
}> = ({ children, contentState, entityKey }) => {
  const classes = useCommentStyles();
  const [activeKey, setActiveKey] = useQueryParam(
    "active-comment",
    StringParam
  );

  let id: string | null = null;
  const data = contentState?.getEntity(entityKey || "")?.getData();
  if (data?.id) {
    id = data.id;
  }

  useLayoutEffect(() => {
    if (activeKey === id) {
      setTimeout(() => {
        document.querySelector(`[data-id=${id}]`)?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }, 1000);
    }
  }, [activeKey, id]);

  return (
    <span
      className={`${classes.comment} ${activeKey === id ? "active" : ""}`}
      onClick={(evt) => {
        evt.stopPropagation();
        setActiveKey(id, "replaceIn");
      }}
      data-id={id}
    >
      {children}
    </span>
  );
};

const LocalCommentSpan: React.FC = ({ children }) => {
  return <span style={{ background: "rgb(255, 235, 59)" }}>{children}</span>;
};

const LinkSpan: React.FC<{
  contentState?: ContentState;
  entityKey?: string;
  blockKey?: string;
}> = ({ children, contentState, entityKey }) => {
  const classes = useCommentStyles();

  if (!contentState || !entityKey) return <span>{children}</span>;
  const linkInfo = contentState.getEntity(entityKey).getData();

  if (linkInfo.url) {
    return (
      <span
        className={classes.link}
        onClick={() => {
          window.open(linkInfo.url);
        }}
      >
        {children}
      </span>
    );
  } else {
    return <span>{children}</span>;
  }
};

function getStrategy(targetType: string): DraftDecorator["strategy"] {
  return (contentBlock, callback, contentState) => {
    contentBlock.findEntityRanges((char) => {
      const entityKey = char.getEntity();
      if (!entityKey) {
        return false;
      }
      const type = contentState.getEntity(entityKey).getType();
      return type === targetType;
    }, callback);
  };
}

const decorator = new CompositeDecorator([
  {
    strategy: getStrategy("COMMENT"),
    component: CommentSpan,
  },
  {
    strategy: getStrategy("COMMENT_LOCAL"),
    component: LocalCommentSpan,
  },
  {
    strategy: getStrategy("LINK"),
    component: LinkSpan,
  },
]);

function removeEntity(editorState: EditorState, type: string) {
  const contentState = editorState.getCurrentContent();

  for (const contentBlock of contentState.getBlocksAsArray()) {
    let entitySelection: SelectionState | null = null;
    const selectionState = editorState.getSelection();

    contentBlock.findEntityRanges(
      (char) => {
        const entityKey = char.getEntity();
        if (!entityKey) {
          return false;
        }
        return contentState.getEntity(entityKey).getType() === type;
      },
      (start, end) => {
        entitySelection = selectionState.merge({
          anchorOffset: start,
          focusOffset: end,
        });
      }
    );

    if (!entitySelection) {
      continue;
    }

    const newContentState = Modifier.applyEntity(
      contentState,
      entitySelection,
      null
    );

    return EditorState.push(editorState, newContentState, "apply-entity");
  }

  return editorState;
}

function removeSelectedBlocksStyle(editorState: EditorState): EditorState {
  const newContentState = RichUtils.tryToRemoveBlockStyle(editorState);
  if (newContentState) {
    return EditorState.push(editorState, newContentState, "change-block-type");
  }
  return editorState;
}

function getResetEditorState(editorState: EditorState): EditorState {
  const blocks = editorState.getCurrentContent().getBlockMap().toList();
  const updatedSelection = editorState.getSelection().merge({
    anchorKey: blocks.first().get("key"),
    anchorOffset: 0,
    focusKey: blocks.last().get("key"),
    focusOffset: blocks.last().getLength(),
  });
  const newContentState = Modifier.removeRange(
    editorState.getCurrentContent(),
    updatedSelection,
    "forward"
  );

  const newState = EditorState.push(
    editorState,
    newContentState,
    "remove-range"
  );
  return removeSelectedBlocksStyle(newState);
}

const TODO_TYPE = "todo";
interface TodoBlockProps {
  block: ContentBlock;
  blockProps: {
    onChange: (newEditorState: EditorState) => void;
    getEditorState: () => EditorState;
    focus: () => void;
  };
}
const TodoBlock = (props: TodoBlockProps) => {
  const {
    block,
    blockProps: { getEditorState, onChange, focus },
  } = props;

  const updateDataOfBlock = (newData: unknown) => {
    const editorState = getEditorState();
    const contentState = editorState.getCurrentContent();
    const newBlock = block.merge({
      data: newData,
    }) as ContentBlock;
    const newContentState = contentState.merge({
      blockMap: contentState.getBlockMap().set(block.getKey(), newBlock),
    }) as ContentState;
    return EditorState.push(editorState, newContentState, "change-block-type");
  };

  const updateData = () => {
    const data = block.getData();
    const checked = data.has("checked") && data.get("checked") === true;
    const newData = data.set("checked", !checked);
    setTimeout(() => focus(), 0);
    onChange(updateDataOfBlock(newData));
  };

  const data = block.getData();
  const checked = data.get("checked") === true;

  return (
    <div className="block-todo">
      <input type="checkbox" checked={checked} onChange={updateData} />
      <EditorBlock {...props} />
    </div>
  );
};
const getBlockRendererFn = (
  getEditorState: () => EditorState,
  onChange: (newEditorState: EditorState) => void,
  focus: () => void
) => (block: ContentBlock) => {
  const type = block.getType();
  switch (type) {
    case TODO_TYPE:
      return {
        component: TodoBlock,
        props: {
          block,
          getEditorState,
          onChange,
          focus,
        },
      };
    default:
      return null;
  }
};
const getDefaultBlockData = (blockType: string, initialData = {}) => {
  switch (blockType) {
    case TODO_TYPE:
      return { checked: false };
    default:
      return initialData;
  }
};
const resetBlockType = (editorState: EditorState, newType: string) => {
  const contentState = editorState.getCurrentContent();
  const selectionState = editorState.getSelection();
  const key = selectionState.getStartKey();
  const blockMap = contentState.getBlockMap();
  const block = blockMap.get(key);
  let newText = "";
  const text = block.getText();
  if (block.getLength() >= 2) {
    newText = text.substr(1);
  }
  const newBlock = block.merge({
    text: newText,
    type: newType,
    data: getDefaultBlockData(newType),
  }) as ContentBlock;
  const newContentState = contentState.merge({
    blockMap: blockMap.set(key, newBlock),
    selectionAfter: selectionState.merge({
      anchorOffset: 0,
      focusOffset: 0,
    }),
  }) as ContentState;
  return EditorState.push(editorState, newContentState, "change-block-type");
};
const blockRenderMap = Map({
  [TODO_TYPE]: {
    element: "div",
  },
}).merge(DefaultDraftBlockRenderMap);

const shouldHidePlaceholder = (editorState: EditorState) => {
  const contentState = editorState.getCurrentContent();
  return (
    contentState.hasText() ||
    contentState.getBlockMap().first().getType() !== "unstyled"
  );
};

const EditableText: React.FC<{
  rawStr: string;
  onBlur?: (raw: Raw, state: EditorState) => void;
  onFocus?: () => void;
  onSubmit?: (raw: Raw, state: EditorState) => void;
  onComment?: (raw: Raw, state: EditorState) => void;
  resetAfterSubmit?: boolean;
  placeholder?: string;
  readOnly?: boolean;
  onlyTriggerBlurWhenChanged?: boolean;
  allowMention?: boolean;
  autoFocus?: boolean;
  allowComment?: boolean;
}> = ({
  rawStr,
  onBlur,
  onSubmit,
  onComment,
  resetAfterSubmit,
  onlyTriggerBlurWhenChanged = true,
  allowMention,
  readOnly,
  autoFocus,
  allowComment,
  ...rest
}) => {
  const classes = useCommentStyles();
  const { plugins, InlineToolbar, MentionSuggestions } = useMemo(() => {
    const inlineToolbarPlugin = createInlineToolbarPlugin();
    const linkifyPlugin = createLinkifyPlugin({
      component: (props) => {
        return (
          <span
            className={classes.link}
            onClick={() => {
              window.open(props.href);
            }}
          >
            {props.href}
          </span>
        );
      },
    });
    const { InlineToolbar } = inlineToolbarPlugin;
    const mentionPlugin = createMentionPlugin({
      entityMutability: "IMMUTABLE",
      mentionPrefix: "@",
    });
    const {
      MentionSuggestions,
      keyBindingFn: keyBindingFnMention,
    } = mentionPlugin;

    // 当 Editor 触发 resolvePlugins 时，会默认检查 plugins
    // 若前面的 plugin 中存在 keyBindingFn，则取当前 plugin 的 keyBindingFn
    // 忽略掉之后的 plugin 里的 keyBindingFn
    // 因此将 Editor 和 Mention 的 keyBindingFn 合在一起，组成功能单一的 keyBindingFnPlugin
    const keyBindingFnPlugin = {
      keyBindingFn: (
        evt: React.KeyboardEvent<Element>,
        pluginFuncs: PluginFunctions
      ) => {
        if (allowMention && keyBindingFnMention)
          keyBindingFnMention(evt, pluginFuncs);

        return keyBindingFnEditor(evt);
      },
    };

    const plugins = [
      keyBindingFnPlugin,
      inlineToolbarPlugin,
      linkifyPlugin,
      allowMention && mentionPlugin,
    ].filter(Boolean) as EditorPlugin[];
    return {
      plugins,
      InlineToolbar,
      MentionSuggestions,
    };
  }, [allowMention]);

  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(convertFromRawStr(rawStr))
  );
  const [openLinkModal, setOpenLinkModal] = useState(false);
  const [link, setLink] = useState("");

  const hidePlaceholder = shouldHidePlaceholder(editorState);

  const rawStrRef = useRef(rawStr);
  useEffect(() => {
    if (rawStr !== rawStrRef.current) {
      rawStrRef.current = rawStr;
      setEditorState(EditorState.createWithContent(convertFromRawStr(rawStr)));
    }
  }, [rawStr]);

  useEffect(() => {
    const handler = () => {
      setEditorState(removeEntity(editorState, "COMMENT_LOCAL"));
    };
    emitter.on("cancel-comment", handler);

    return () => {
      emitter.off("cancel-comment", handler);
    };
  }, [editorState, rawStr]);

  const [openMention, setOpenMention] = useState(false);
  const editor = useRef<Editor>(null);
  const [search, setSearch] = useState("");
  const { data } = useUsersQuery({
    variables: {
      where: {
        OR: [
          { name: { contains: search } },
          { email: { contains: search } },
          { pinyinName: { contains: search } },
        ],
      },
    },
  });

  const focus = (): void => {
    editor.current?.focus();
  };

  useEffect(() => {
    if (autoFocus) {
      setTimeout(() => {
        focus();
      }, 0);
    }
  }, [autoFocus]);

  const onBlurFn = useCallback(() => {
    if (readOnly) {
      return;
    }
    const changed =
      JSON.stringify(convertToRaw(editorState.getCurrentContent())) !== rawStr;
    if (!changed && onlyTriggerBlurWhenChanged) {
      return;
    }
    onBlur?.(convertToRaw(editorState.getCurrentContent()), editorState);
  }, [editorState, onBlur, onlyTriggerBlurWhenChanged, rawStr, readOnly]);

  const reset = useCallback(() => {
    setEditorState(getResetEditorState(editorState));
  }, [editorState]);

  const hasText = editorState.getCurrentContent().hasText();

  const onSubmitFn = useCallback(() => {
    if (readOnly || !hasText) {
      return;
    }
    onSubmit?.(convertToRaw(editorState.getCurrentContent()), editorState);
    if (resetAfterSubmit) {
      reset();
    }
  }, [editorState, onSubmit, readOnly, reset, resetAfterSubmit, hasText]);

  const handleKeyCommand = useCallback(
    (command: string): DraftHandleValue => {
      if (command === "custom-submit") {
        onSubmitFn();
        return "handled";
      }
      // RichUtils 已经包含了一些键盘命令的信息
      if (
        command === "bold" ||
        command === "italic" ||
        command === "underline"
      ) {
        setEditorState(
          RichUtils.handleKeyCommand(editorState, command) as EditorState
        );
        return "handled";
      }
      return "not-handled";
    },
    [onSubmitFn]
  );

  const handleOpenChange = useCallback((_open: boolean) => {
    setOpenMention(_open);
  }, []);

  const handleSearchChange = useCallback(({ value }: { value: string }) => {
    setSearch(value);
  }, []);

  const handleChange = useCallback(
    (newEditorState: EditorState) => {
      const contentEqual = newEditorState
        .getCurrentContent()
        .equals(editorState.getCurrentContent());
      if (readOnly && !contentEqual) {
        // a hack implementation of read only
        setEditorState(
          EditorState.createWithContent(
            convertFromRaw(convertToRaw(editorState.getCurrentContent()))
          )
        );
        return;
      } else {
        setEditorState(newEditorState);
      }
    },
    [editorState, readOnly]
  );

  const handleComment = () => {
    // currently, we only support one local comment
    const contentState = removeEntity(
      editorState,
      "COMMENT_LOCAL"
    ).getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      "COMMENT_LOCAL",
      "MUTABLE",
      {
        id: null,
      }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const selectionState = editorState.getSelection();
    const contentStateWithLink = Modifier.applyEntity(
      contentStateWithEntity,
      selectionState,
      entityKey
    );
    const nextEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithLink,
    });
    setEditorState(nextEditorState);
    onComment?.(
      convertToRaw(nextEditorState.getCurrentContent()),
      nextEditorState
    );
  };

  const handleBeforeLink = () => {
    const selection = editorState.getSelection();
    const contentState = editorState.getCurrentContent();
    const currentBlock = contentState.getBlockForKey(selection.getAnchorKey()); // 获取焦点位置
    const entityKey = currentBlock.getEntityAt(selection.getFocusOffset());
    const entity = entityKey && contentState.getEntity(entityKey);

    // 若获取到当前选中内容的 entity 则为修改链接
    if (entity && entity.getData()) {
      setLink(entity.getData()?.url || "");
    }
    setOpenLinkModal(true);
  };

  const handleLink = (url: string) => {
    const selectionState = editorState.getSelection();
    const contentStateWithEntity = editorState
      .getCurrentContent()
      .createEntity("LINK", "MUTABLE", { url });
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    const contentStateWithLink = Modifier.applyEntity(
      contentStateWithEntity,
      selectionState,
      entityKey
    );

    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithLink,
    });
    // 异步更新 editorState 避免 state 变更页面未渲染最新 state
    setTimeout(() => {
      setEditorState(newEditorState);
    }, 0);
  };

  const handleBeforeInput = (str: string): DraftHandleValue => {
    if (str !== "]") {
      return "not-handled";
    }
    const selection = editorState.getSelection();

    const currentBlock = editorState
      .getCurrentContent()
      .getBlockForKey(selection.getStartKey());
    const blockType = currentBlock.getType();
    const blockLength = currentBlock.getLength();
    if (blockLength === 1 && currentBlock.getText() === "[") {
      handleChange(
        resetBlockType(
          editorState,
          blockType !== TODO_TYPE ? TODO_TYPE : "unstyled"
        )
      );
      return "handled";
    }
    return "not-handled";
  };

  return (
    <div
      className={`${hidePlaceholder ? "hide-DraftEditorPlaceholder" : ""}`}
      style={{ width: "100%" }}
      onMouseDown={focus}
    >
      <Editor
        editorState={editorState}
        onChange={handleChange}
        onBlur={onBlurFn}
        // 此处只配置 readonly 的 readOnlyKeyBindingFn 的原因
        // 目的为了不让 keyBindingFnPlugin 里 keyBindingFn 依赖 readOnly 而变化
        // readonly 从 false 变成 true，InlineToolbar 会重新实例化
        // 但 Editor 内部没有对 plugins 的变化而重新做 initialize 的工作，导致 InlineToolbar 中的 getStyle 的方法报错，导致页面跳转空白
        keyBindingFn={readOnly ? readOnlyKeyBindingFn : undefined}
        handleKeyCommand={handleKeyCommand}
        plugins={plugins}
        ref={editor}
        decorators={[decorator]}
        blockRenderMap={blockRenderMap}
        blockRendererFn={getBlockRendererFn(
          () => editorState,
          handleChange,
          focus
        )}
        handleBeforeInput={handleBeforeInput}
        {...rest}
      />
      {!readOnly && allowComment && (
        <InlineToolbar>
          {(externalProps) => (
            <div className={classes.customToolbar}>
              <BoldButton {...externalProps} />
              <ItalicButton {...externalProps} />
              <UnderlineButton {...externalProps} />
              <CodeBlockButton {...externalProps} />
              <IconButton
                size="small"
                onMouseDown={(e) => e.preventDefault()}
                onClick={handleComment}
                className={classes.customerInlineToolbar}
              >
                <CommentOutlinedIcon />
              </IconButton>
              <IconButton
                size="small"
                onMouseDown={(e) => {
                  e.preventDefault();
                  handleBeforeLink();
                }}
                className={classes.customerInlineToolbar}
              >
                <LinkIcon />
              </IconButton>
            </div>
          )}
        </InlineToolbar>
      )}
      {readOnly && allowComment && (
        <InlineToolbar>
          {() => {
            return (
              <IconButton
                size="small"
                onMouseDown={(e) => e.preventDefault()}
                onClick={handleComment}
              >
                <CommentOutlinedIcon />
              </IconButton>
            );
          }}
        </InlineToolbar>
      )}
      {!readOnly && allowMention && (
        <MentionSuggestions
          open={openMention}
          onOpenChange={handleOpenChange}
          suggestions={
            data?.users.map((user) => ({
              name: user.name || "Anonymous",
              avatar: user.avatar || undefined,
              id: user.id || undefined,
            })) || []
          }
          onSearchChange={handleSearchChange}
          // 'The properties `popoverComponent` and `positionSuggestions` are deprecated and will be removed in @draft-js-plugins/mentions 6.0 . Use `popperOptions` instead'
          popoverComponent={
            <div
              style={{
                maxHeight: "220px",
                overflowX: "hidden",
                overflowY: "auto",
                // popoverComponent 是根据 transform: scale(0) => scale(1) 来控制显隐
                // 当 popoverComponent 设置了 maxHeight 与 overflowY，scale(1) 的时候会让其 y 轴偏移一定比例的距离
                // 故需要 translate(0px, 0px) 到顶部，经测试，该情况出现在 chrome 里，safari 无影响
                transform: "translate(0px, 0px)",
              }}
            />
          }
        />
      )}
      {onSubmit && (
        <Box display="flex" justifyContent="flex-end" mt={1}>
          {false && (
            <Button
              size="small"
              onClick={reset}
              onMouseDown={(e) => e.preventDefault()}
            >
              取消
            </Button>
          )}
          <Button
            color="primary"
            variant="outlined"
            size="small"
            onClick={onSubmitFn}
            onMouseDown={(e) => e.preventDefault()}
            disabled={!hasText}
          >
            发送
          </Button>
        </Box>
      )}
      {!readOnly && (
        <AddLinkDialog
          open={openLinkModal}
          setOpen={setOpenLinkModal}
          handleSubmit={handleLink}
          link={link}
          setLink={setLink}
        />
      )}
    </div>
  );
};

export default EditableText;
