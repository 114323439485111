import { IconButton, IconButtonProps } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CopyrightRoundedIcon from "@material-ui/icons/FileCopyOutlined";
import React from "react";

const useStyles = makeStyles((theme) => ({
  actionIcon: {
    color: theme.palette.text.hint,
    "&:hover": {
      color: theme.palette.error.main,
    },
  },
}));

const CopyObjectiveButton: React.FC<IconButtonProps> = (props) => {
  const classes = useStyles();

  return (
    <IconButton
      className={classes.actionIcon}
      {...props}
      onClick={(evt) => {
        props.onClick?.(evt);
      }}
    >
      <CopyrightRoundedIcon color="action" fontSize="small" />
    </IconButton>
  );
};

export default CopyObjectiveButton;
