import React, { useEffect, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  MenuItem,
  Menu,
  Box,
  Button,
  Typography,
  Avatar,
  TextField,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import {
  useUpsertOneGlanceOverHistoryMutation,
  useUsersLazyQuery,
  useViewedUserLazyQuery,
} from "../../generated/graphql";
import { useQueryParam, StringParam } from "use-query-params";
import { useAuth } from "../../lib/auth";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuItem: {
      width: 300,
    },
    avatar: {
      width: 32,
      height: 32,
      marginRight: theme.spacing(2),
    },
    viewedUserBox: {
      minWidth: 80,
      height: 40,
    },
  })
);

const ObjectiveSelect: React.FC<{}> = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { user: currentUser } = useAuth();
  const location = useLocation();
  const [authorId, setAuthorId] = useQueryParam("author", StringParam);
  const [periodId] = useQueryParam("period", StringParam);
  const [search, setSearch] = useState("");
  const [trigger, { data, loading, error }] = useUsersLazyQuery({
    variables: {
      where: {
        OR: [
          { name: { contains: search } },
          { email: { contains: search } },
          { pinyinName: { contains: search } },
        ],
      },
    },
  });

  const [
    getViewedUser,
    { data: viewedUserData, loading: loadingViewedUser },
  ] = useViewedUserLazyQuery({
    onCompleted: (data) => {
      data.user === null && setAuthorId(undefined);
    },
  });

  const [upsertGlanceOverHistory] = useUpsertOneGlanceOverHistoryMutation({
    refetchQueries: ["getUserGlanceOverHistories"],
  });

  useEffect(() => {
    const id = authorId || currentUser?.id;

    if (id) {
      getViewedUser({
        variables: { where: { id } },
      });
      handleUpsertGlanceOverHistory(id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authorId, currentUser?.id]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    trigger();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUpsertGlanceOverHistory = async (authorId: string) => {
    if (currentUser && authorId !== currentUser.id && periodId) {
      try {
        await upsertGlanceOverHistory({
          variables: {
            data: {
              okrAuthorId: authorId,
              okrPeriodId: periodId,
              pathModule: location.pathname,
            },
          },
        });
      } catch (ex) {
        console.log(ex);
      }
    }
  };

  const renderMenu = () => {
    if (error) {
      return <Typography color="error">{error}</Typography>;
    }
    if (loading) {
      return <MenuItem>Loading...</MenuItem>;
    }
    return (data?.users || []).map((user) => {
      return (
        <MenuItem
          key={user.id}
          onClick={() => {
            setAuthorId(user.id);
            handleClose();
          }}
        >
          <Box display="flex" alignItems="center" p={1}>
            <Avatar src={user.avatar || ""} className={classes.avatar}></Avatar>
            <Typography>{user.name}</Typography>
          </Box>
        </MenuItem>
      );
    });
  };

  const { name, avatar } = viewedUserData?.user || {};

  return (
    <Box>
      <Button
        aria-controls="objective-menu"
        aria-haspopup="true"
        onClick={handleClick}
        endIcon={<ArrowDropDownIcon />}
        size="small"
        disableFocusRipple
      >
        <Box
          display="flex"
          alignItems="center"
          className={classes.viewedUserBox}
        >
          {!loadingViewedUser && (
            <>
              <Avatar src={avatar || ""} />
              <Box ml={1}>
                <Typography variant="body1">{name}</Typography>
              </Box>
            </>
          )}
        </Box>
      </Button>
      <Menu
        id="objective-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 300,
            width: 300,
          },
        }}
      >
        <MenuItem disableRipple>
          <Box width="100%">
            <TextField
              value={search}
              onChange={(evt) => {
                evt.stopPropagation();
                setSearch(evt.currentTarget.value);
              }}
              variant="outlined"
              size="small"
              fullWidth
              placeholder="请搜索用户名或邮箱"
            />
          </Box>
        </MenuItem>
        {renderMenu()}
      </Menu>
    </Box>
  );
};

export default ObjectiveSelect;
