import {
  Avatar,
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Paper,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import EditableText from "../../components/EditableText";
import { COMMENT_HINT } from "../../constants";
import {
  useCreateOneCommentMutation,
  useOkrBottomCommentsQuery,
} from "../../generated/graphql";
import { useAuth } from "../../lib/auth";
import CommentToolbar from "./CommentToolbar";

const useStyles = makeStyles((theme) => ({
  commentInput: {
    background: "#fff",
  },
}));

interface BottomCommentsProps {
  readOnly: boolean;
  okrId: string;
}

const BottomComments = (props: BottomCommentsProps) => {
  const classes = useStyles();
  const { user } = useAuth();
  const { readOnly, okrId } = props;
  const [showSolved, setShowSolved] = useState(false);

  const [createOneComment] = useCreateOneCommentMutation({
    refetchQueries: ["okrBottomComments"],
  });

  const { data, loading, error } = useOkrBottomCommentsQuery({
    variables: {
      where: {
        okr: { id: { equals: okrId } },
        solvedAt: showSolved ? undefined : { equals: null },
        previousComment: null,
      },
    },
    fetchPolicy: "cache-and-network",
    pollInterval: 60_000,
  });

  if (error) {
    return <p>{error.message}</p>;
  }

  return (
    <Box p={2}>
      <Box display="flex" alignItems="center">
        <Typography variant="h6">评论</Typography>
        <Box ml={4}>
          <FormControlLabel
            control={
              <Checkbox
                checked={showSolved}
                onChange={(evt) => setShowSolved(evt.currentTarget.checked)}
              />
            }
            label="显示已解决"
          />
        </Box>
      </Box>
      <Divider />
      {data?.comments.map((comment) => {
        return (
          <Box
            key={comment.id}
            display="flex"
            alignItems="flex-start"
            paddingY={2}
          >
            <Avatar src={comment.author?.avatar || ""} />
            <Box flex="1" className={classes.commentInput} ml={2}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box display="flex">
                  <Typography variant="body2">
                    {comment.author?.name}
                  </Typography>
                  <Box ml={2}>
                    <Typography variant="caption" color="textSecondary">
                      {new Date(comment.createdAt).toLocaleString()}
                    </Typography>
                  </Box>
                </Box>
                {(comment.author?.id === user?.id || !readOnly) && (
                  <CommentToolbar comment={comment} />
                )}
              </Box>
              <Box mt={1}>
                <EditableText
                  rawStr={comment.content || ""}
                  readOnly
                  allowMention
                />
              </Box>
            </Box>
          </Box>
        );
      })}
      <Box display="flex" alignItems="center" mt={2}>
        <Avatar src={user?.avatar || ""} />
        <Box flex="1" className={classes.commentInput} ml={2}>
          <Paper>
            <Box p={2}>
              <EditableText
                rawStr=""
                placeholder={COMMENT_HINT}
                onSubmit={(raw) => {
                  createOneComment({
                    variables: {
                      data: {
                        content: JSON.stringify(raw),
                        okr: {
                          connect: {
                            id: okrId,
                          },
                        },
                      },
                    },
                  });
                }}
                resetAfterSubmit
                allowMention
              />
            </Box>
          </Paper>
        </Box>
      </Box>
    </Box>
  );
};

export default BottomComments;
