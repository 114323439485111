import { Box, IconButton, Typography } from "@material-ui/core";
import CommentOutlinedIcon from "@material-ui/icons/CommentOutlined";
import React from "react";
import { StringParam, useQueryParam } from "use-query-params";
import { OkrsQuery } from "../../generated/graphql";
import RealtimeUsers from "../user/RealtimeUsers";
import UserSelect from "../user/UserSelect";
import PeriodsTabs from "./PeriodsTabs";
import PermissionEditor from "./PermissionEditor";

interface HeaderProps {
  readOnly: boolean;
  okr?: OkrsQuery["okrs"][0];
}

const Header = (props: HeaderProps) => {
  const { readOnly, okr } = props;
  const [activeKey, setActiveKey] = useQueryParam(
    "active-comment",
    StringParam
  );

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Box display="flex" alignItems="center" p={2}>
        <Typography variant="body1">正在浏览</Typography>
        <UserSelect />
        <Typography variant="body1">的 OKR</Typography>
      </Box>
      <Box display="flex" alignItems="center" pr={2}>
        <RealtimeUsers />
        <PeriodsTabs />
        {okr ? (
          <IconButton
            size="small"
            onClick={() => setActiveKey("", "replaceIn")}
          >
            <CommentOutlinedIcon
              color={activeKey === undefined ? "inherit" : "primary"}
            />
          </IconButton>
        ) : (
          ""
        )}
        {!readOnly ? (
          <PermissionEditor
            okrId={okr?.id || ""}
            objectives={okr?.objectives || []}
          />
        ) : (
          ""
        )}
      </Box>
    </Box>
  );
};

export default Header;
