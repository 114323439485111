import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  Json: any;
};

export type BoolFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['Boolean']>;
};

export type BoolFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolFilter>;
};

export type BoolNullableFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolNullableFilter>;
};

export type Comment = {
  __typename?: 'Comment';
  author: User;
  content: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  keyResult?: Maybe<KeyResult>;
  nextComment?: Maybe<Comment>;
  objective?: Maybe<Objective>;
  okr?: Maybe<Okr>;
  previousComment?: Maybe<Comment>;
  review?: Maybe<Review>;
  solvedAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
};

export type CommentCreateEffect = {
  commentContent?: Maybe<Scalars['String']>;
};

export type CommentCreateInput = {
  comment?: Maybe<ConnectCommentInput>;
  content: Scalars['String'];
  keyResult?: Maybe<ConnectKeyResultInput>;
  objective?: Maybe<ConnectObjectiveInput>;
  okr?: Maybe<ConnectOkrInput>;
  review?: Maybe<ConnectReviewInput>;
};

export type CommentCreateManyAuthorInput = {
  content: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  keyResultId?: Maybe<Scalars['String']>;
  objectiveId?: Maybe<Scalars['String']>;
  okrId?: Maybe<Scalars['String']>;
  previousCommentId?: Maybe<Scalars['String']>;
  reviewId?: Maybe<Scalars['String']>;
  solvedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CommentCreateManyAuthorInputEnvelope = {
  data?: Maybe<Array<CommentCreateManyAuthorInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CommentCreateManyKeyResultInput = {
  authorId: Scalars['String'];
  content: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  objectiveId?: Maybe<Scalars['String']>;
  okrId?: Maybe<Scalars['String']>;
  previousCommentId?: Maybe<Scalars['String']>;
  reviewId?: Maybe<Scalars['String']>;
  solvedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CommentCreateManyKeyResultInputEnvelope = {
  data?: Maybe<Array<CommentCreateManyKeyResultInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CommentCreateManyObjectiveInput = {
  authorId: Scalars['String'];
  content: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  keyResultId?: Maybe<Scalars['String']>;
  okrId?: Maybe<Scalars['String']>;
  previousCommentId?: Maybe<Scalars['String']>;
  reviewId?: Maybe<Scalars['String']>;
  solvedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CommentCreateManyObjectiveInputEnvelope = {
  data?: Maybe<Array<CommentCreateManyObjectiveInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CommentCreateManyOkrInput = {
  authorId: Scalars['String'];
  content: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  keyResultId?: Maybe<Scalars['String']>;
  objectiveId?: Maybe<Scalars['String']>;
  previousCommentId?: Maybe<Scalars['String']>;
  reviewId?: Maybe<Scalars['String']>;
  solvedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CommentCreateManyOkrInputEnvelope = {
  data?: Maybe<Array<CommentCreateManyOkrInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CommentCreateManyReviewInput = {
  authorId: Scalars['String'];
  content: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  keyResultId?: Maybe<Scalars['String']>;
  objectiveId?: Maybe<Scalars['String']>;
  okrId?: Maybe<Scalars['String']>;
  previousCommentId?: Maybe<Scalars['String']>;
  solvedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CommentCreateManyReviewInputEnvelope = {
  data?: Maybe<Array<CommentCreateManyReviewInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CommentCreateNestedManyWithoutAuthorInput = {
  connect?: Maybe<Array<CommentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CommentCreateOrConnectWithoutAuthorInput>>;
  create?: Maybe<Array<CommentCreateWithoutAuthorInput>>;
  createMany?: Maybe<CommentCreateManyAuthorInputEnvelope>;
};

export type CommentCreateNestedManyWithoutKeyResultInput = {
  connect?: Maybe<Array<CommentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CommentCreateOrConnectWithoutKeyResultInput>>;
  create?: Maybe<Array<CommentCreateWithoutKeyResultInput>>;
  createMany?: Maybe<CommentCreateManyKeyResultInputEnvelope>;
};

export type CommentCreateNestedManyWithoutObjectiveInput = {
  connect?: Maybe<Array<CommentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CommentCreateOrConnectWithoutObjectiveInput>>;
  create?: Maybe<Array<CommentCreateWithoutObjectiveInput>>;
  createMany?: Maybe<CommentCreateManyObjectiveInputEnvelope>;
};

export type CommentCreateNestedManyWithoutOkrInput = {
  connect?: Maybe<Array<CommentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CommentCreateOrConnectWithoutOkrInput>>;
  create?: Maybe<Array<CommentCreateWithoutOkrInput>>;
  createMany?: Maybe<CommentCreateManyOkrInputEnvelope>;
};

export type CommentCreateNestedManyWithoutReviewInput = {
  connect?: Maybe<Array<CommentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CommentCreateOrConnectWithoutReviewInput>>;
  create?: Maybe<Array<CommentCreateWithoutReviewInput>>;
  createMany?: Maybe<CommentCreateManyReviewInputEnvelope>;
};

export type CommentCreateNestedOneWithoutNextCommentInput = {
  connect?: Maybe<CommentWhereUniqueInput>;
  connectOrCreate?: Maybe<CommentCreateOrConnectWithoutNextCommentInput>;
  create?: Maybe<CommentCreateWithoutNextCommentInput>;
};

export type CommentCreateNestedOneWithoutPreviousCommentInput = {
  connect?: Maybe<CommentWhereUniqueInput>;
  connectOrCreate?: Maybe<CommentCreateOrConnectWithoutPreviousCommentInput>;
  create?: Maybe<CommentCreateWithoutPreviousCommentInput>;
};

export type CommentCreateOrConnectWithoutAuthorInput = {
  create: CommentCreateWithoutAuthorInput;
  where: CommentWhereUniqueInput;
};

export type CommentCreateOrConnectWithoutKeyResultInput = {
  create: CommentCreateWithoutKeyResultInput;
  where: CommentWhereUniqueInput;
};

export type CommentCreateOrConnectWithoutNextCommentInput = {
  create: CommentCreateWithoutNextCommentInput;
  where: CommentWhereUniqueInput;
};

export type CommentCreateOrConnectWithoutObjectiveInput = {
  create: CommentCreateWithoutObjectiveInput;
  where: CommentWhereUniqueInput;
};

export type CommentCreateOrConnectWithoutOkrInput = {
  create: CommentCreateWithoutOkrInput;
  where: CommentWhereUniqueInput;
};

export type CommentCreateOrConnectWithoutPreviousCommentInput = {
  create: CommentCreateWithoutPreviousCommentInput;
  where: CommentWhereUniqueInput;
};

export type CommentCreateOrConnectWithoutReviewInput = {
  create: CommentCreateWithoutReviewInput;
  where: CommentWhereUniqueInput;
};

export type CommentCreateWithoutAuthorInput = {
  content: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  keyResult?: Maybe<KeyResultCreateNestedOneWithoutCommentsInput>;
  nextComment?: Maybe<CommentCreateNestedOneWithoutPreviousCommentInput>;
  objective?: Maybe<ObjectiveCreateNestedOneWithoutCommentsInput>;
  okr?: Maybe<OkrCreateNestedOneWithoutCommentsInput>;
  previousComment?: Maybe<CommentCreateNestedOneWithoutNextCommentInput>;
  review?: Maybe<ReviewCreateNestedOneWithoutCommentsInput>;
  solvedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CommentCreateWithoutKeyResultInput = {
  author: UserCreateNestedOneWithoutCommentInput;
  content: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  nextComment?: Maybe<CommentCreateNestedOneWithoutPreviousCommentInput>;
  objective?: Maybe<ObjectiveCreateNestedOneWithoutCommentsInput>;
  okr?: Maybe<OkrCreateNestedOneWithoutCommentsInput>;
  previousComment?: Maybe<CommentCreateNestedOneWithoutNextCommentInput>;
  review?: Maybe<ReviewCreateNestedOneWithoutCommentsInput>;
  solvedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CommentCreateWithoutNextCommentInput = {
  author: UserCreateNestedOneWithoutCommentInput;
  content: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  keyResult?: Maybe<KeyResultCreateNestedOneWithoutCommentsInput>;
  objective?: Maybe<ObjectiveCreateNestedOneWithoutCommentsInput>;
  okr?: Maybe<OkrCreateNestedOneWithoutCommentsInput>;
  previousComment?: Maybe<CommentCreateNestedOneWithoutNextCommentInput>;
  review?: Maybe<ReviewCreateNestedOneWithoutCommentsInput>;
  solvedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CommentCreateWithoutObjectiveInput = {
  author: UserCreateNestedOneWithoutCommentInput;
  content: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  keyResult?: Maybe<KeyResultCreateNestedOneWithoutCommentsInput>;
  nextComment?: Maybe<CommentCreateNestedOneWithoutPreviousCommentInput>;
  okr?: Maybe<OkrCreateNestedOneWithoutCommentsInput>;
  previousComment?: Maybe<CommentCreateNestedOneWithoutNextCommentInput>;
  review?: Maybe<ReviewCreateNestedOneWithoutCommentsInput>;
  solvedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CommentCreateWithoutOkrInput = {
  author: UserCreateNestedOneWithoutCommentInput;
  content: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  keyResult?: Maybe<KeyResultCreateNestedOneWithoutCommentsInput>;
  nextComment?: Maybe<CommentCreateNestedOneWithoutPreviousCommentInput>;
  objective?: Maybe<ObjectiveCreateNestedOneWithoutCommentsInput>;
  previousComment?: Maybe<CommentCreateNestedOneWithoutNextCommentInput>;
  review?: Maybe<ReviewCreateNestedOneWithoutCommentsInput>;
  solvedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CommentCreateWithoutPreviousCommentInput = {
  author: UserCreateNestedOneWithoutCommentInput;
  content: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  keyResult?: Maybe<KeyResultCreateNestedOneWithoutCommentsInput>;
  nextComment?: Maybe<CommentCreateNestedOneWithoutPreviousCommentInput>;
  objective?: Maybe<ObjectiveCreateNestedOneWithoutCommentsInput>;
  okr?: Maybe<OkrCreateNestedOneWithoutCommentsInput>;
  review?: Maybe<ReviewCreateNestedOneWithoutCommentsInput>;
  solvedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CommentCreateWithoutReviewInput = {
  author: UserCreateNestedOneWithoutCommentInput;
  content: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  keyResult?: Maybe<KeyResultCreateNestedOneWithoutCommentsInput>;
  nextComment?: Maybe<CommentCreateNestedOneWithoutPreviousCommentInput>;
  objective?: Maybe<ObjectiveCreateNestedOneWithoutCommentsInput>;
  okr?: Maybe<OkrCreateNestedOneWithoutCommentsInput>;
  previousComment?: Maybe<CommentCreateNestedOneWithoutNextCommentInput>;
  solvedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CommentListRelationFilter = {
  every?: Maybe<CommentWhereInput>;
  none?: Maybe<CommentWhereInput>;
  some?: Maybe<CommentWhereInput>;
};

export type CommentOrderByInput = {
  authorId?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  keyResultId?: Maybe<SortOrder>;
  objectiveId?: Maybe<SortOrder>;
  okrId?: Maybe<SortOrder>;
  previousCommentId?: Maybe<SortOrder>;
  reviewId?: Maybe<SortOrder>;
  solvedAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CommentScalarWhereInput = {
  AND?: Maybe<Array<CommentScalarWhereInput>>;
  authorId?: Maybe<StringFilter>;
  content?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  keyResultId?: Maybe<StringNullableFilter>;
  NOT?: Maybe<Array<CommentScalarWhereInput>>;
  objectiveId?: Maybe<StringNullableFilter>;
  okrId?: Maybe<StringNullableFilter>;
  OR?: Maybe<Array<CommentScalarWhereInput>>;
  previousCommentId?: Maybe<StringNullableFilter>;
  reviewId?: Maybe<StringNullableFilter>;
  solvedAt?: Maybe<DateTimeNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type CommentUpdateInput = {
  content?: Maybe<Scalars['String']>;
};

export type CommentUpdateManyMutationInput = {
  content?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  solvedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type CommentUpdateManyWithoutAuthorInput = {
  connect?: Maybe<Array<CommentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CommentCreateOrConnectWithoutAuthorInput>>;
  create?: Maybe<Array<CommentCreateWithoutAuthorInput>>;
  createMany?: Maybe<CommentCreateManyAuthorInputEnvelope>;
  delete?: Maybe<Array<CommentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CommentScalarWhereInput>>;
  disconnect?: Maybe<Array<CommentWhereUniqueInput>>;
  set?: Maybe<Array<CommentWhereUniqueInput>>;
  update?: Maybe<Array<CommentUpdateWithWhereUniqueWithoutAuthorInput>>;
  updateMany?: Maybe<Array<CommentUpdateManyWithWhereWithoutAuthorInput>>;
  upsert?: Maybe<Array<CommentUpsertWithWhereUniqueWithoutAuthorInput>>;
};

export type CommentUpdateManyWithoutKeyResultInput = {
  connect?: Maybe<Array<CommentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CommentCreateOrConnectWithoutKeyResultInput>>;
  create?: Maybe<Array<CommentCreateWithoutKeyResultInput>>;
  createMany?: Maybe<CommentCreateManyKeyResultInputEnvelope>;
  delete?: Maybe<Array<CommentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CommentScalarWhereInput>>;
  disconnect?: Maybe<Array<CommentWhereUniqueInput>>;
  set?: Maybe<Array<CommentWhereUniqueInput>>;
  update?: Maybe<Array<CommentUpdateWithWhereUniqueWithoutKeyResultInput>>;
  updateMany?: Maybe<Array<CommentUpdateManyWithWhereWithoutKeyResultInput>>;
  upsert?: Maybe<Array<CommentUpsertWithWhereUniqueWithoutKeyResultInput>>;
};

export type CommentUpdateManyWithoutObjectiveInput = {
  connect?: Maybe<Array<CommentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CommentCreateOrConnectWithoutObjectiveInput>>;
  create?: Maybe<Array<CommentCreateWithoutObjectiveInput>>;
  createMany?: Maybe<CommentCreateManyObjectiveInputEnvelope>;
  delete?: Maybe<Array<CommentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CommentScalarWhereInput>>;
  disconnect?: Maybe<Array<CommentWhereUniqueInput>>;
  set?: Maybe<Array<CommentWhereUniqueInput>>;
  update?: Maybe<Array<CommentUpdateWithWhereUniqueWithoutObjectiveInput>>;
  updateMany?: Maybe<Array<CommentUpdateManyWithWhereWithoutObjectiveInput>>;
  upsert?: Maybe<Array<CommentUpsertWithWhereUniqueWithoutObjectiveInput>>;
};

export type CommentUpdateManyWithoutOkrInput = {
  connect?: Maybe<Array<CommentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CommentCreateOrConnectWithoutOkrInput>>;
  create?: Maybe<Array<CommentCreateWithoutOkrInput>>;
  createMany?: Maybe<CommentCreateManyOkrInputEnvelope>;
  delete?: Maybe<Array<CommentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CommentScalarWhereInput>>;
  disconnect?: Maybe<Array<CommentWhereUniqueInput>>;
  set?: Maybe<Array<CommentWhereUniqueInput>>;
  update?: Maybe<Array<CommentUpdateWithWhereUniqueWithoutOkrInput>>;
  updateMany?: Maybe<Array<CommentUpdateManyWithWhereWithoutOkrInput>>;
  upsert?: Maybe<Array<CommentUpsertWithWhereUniqueWithoutOkrInput>>;
};

export type CommentUpdateManyWithoutReviewInput = {
  connect?: Maybe<Array<CommentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CommentCreateOrConnectWithoutReviewInput>>;
  create?: Maybe<Array<CommentCreateWithoutReviewInput>>;
  createMany?: Maybe<CommentCreateManyReviewInputEnvelope>;
  delete?: Maybe<Array<CommentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CommentScalarWhereInput>>;
  disconnect?: Maybe<Array<CommentWhereUniqueInput>>;
  set?: Maybe<Array<CommentWhereUniqueInput>>;
  update?: Maybe<Array<CommentUpdateWithWhereUniqueWithoutReviewInput>>;
  updateMany?: Maybe<Array<CommentUpdateManyWithWhereWithoutReviewInput>>;
  upsert?: Maybe<Array<CommentUpsertWithWhereUniqueWithoutReviewInput>>;
};

export type CommentUpdateManyWithWhereWithoutAuthorInput = {
  data: CommentUpdateManyMutationInput;
  where: CommentScalarWhereInput;
};

export type CommentUpdateManyWithWhereWithoutKeyResultInput = {
  data: CommentUpdateManyMutationInput;
  where: CommentScalarWhereInput;
};

export type CommentUpdateManyWithWhereWithoutObjectiveInput = {
  data: CommentUpdateManyMutationInput;
  where: CommentScalarWhereInput;
};

export type CommentUpdateManyWithWhereWithoutOkrInput = {
  data: CommentUpdateManyMutationInput;
  where: CommentScalarWhereInput;
};

export type CommentUpdateManyWithWhereWithoutReviewInput = {
  data: CommentUpdateManyMutationInput;
  where: CommentScalarWhereInput;
};

export type CommentUpdateOneWithoutNextCommentInput = {
  connect?: Maybe<CommentWhereUniqueInput>;
  connectOrCreate?: Maybe<CommentCreateOrConnectWithoutNextCommentInput>;
  create?: Maybe<CommentCreateWithoutNextCommentInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<CommentUpdateWithoutNextCommentInput>;
  upsert?: Maybe<CommentUpsertWithoutNextCommentInput>;
};

export type CommentUpdateOneWithoutPreviousCommentInput = {
  connect?: Maybe<CommentWhereUniqueInput>;
  connectOrCreate?: Maybe<CommentCreateOrConnectWithoutPreviousCommentInput>;
  create?: Maybe<CommentCreateWithoutPreviousCommentInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<CommentUpdateWithoutPreviousCommentInput>;
  upsert?: Maybe<CommentUpsertWithoutPreviousCommentInput>;
};

export type CommentUpdateWithoutAuthorInput = {
  content?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  keyResult?: Maybe<KeyResultUpdateOneWithoutCommentsInput>;
  nextComment?: Maybe<CommentUpdateOneWithoutPreviousCommentInput>;
  objective?: Maybe<ObjectiveUpdateOneWithoutCommentsInput>;
  okr?: Maybe<OkrUpdateOneWithoutCommentsInput>;
  previousComment?: Maybe<CommentUpdateOneWithoutNextCommentInput>;
  review?: Maybe<ReviewUpdateOneWithoutCommentsInput>;
  solvedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type CommentUpdateWithoutKeyResultInput = {
  author?: Maybe<UserUpdateOneRequiredWithoutCommentInput>;
  content?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  nextComment?: Maybe<CommentUpdateOneWithoutPreviousCommentInput>;
  objective?: Maybe<ObjectiveUpdateOneWithoutCommentsInput>;
  okr?: Maybe<OkrUpdateOneWithoutCommentsInput>;
  previousComment?: Maybe<CommentUpdateOneWithoutNextCommentInput>;
  review?: Maybe<ReviewUpdateOneWithoutCommentsInput>;
  solvedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type CommentUpdateWithoutNextCommentInput = {
  author?: Maybe<UserUpdateOneRequiredWithoutCommentInput>;
  content?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  keyResult?: Maybe<KeyResultUpdateOneWithoutCommentsInput>;
  objective?: Maybe<ObjectiveUpdateOneWithoutCommentsInput>;
  okr?: Maybe<OkrUpdateOneWithoutCommentsInput>;
  previousComment?: Maybe<CommentUpdateOneWithoutNextCommentInput>;
  review?: Maybe<ReviewUpdateOneWithoutCommentsInput>;
  solvedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type CommentUpdateWithoutObjectiveInput = {
  author?: Maybe<UserUpdateOneRequiredWithoutCommentInput>;
  content?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  keyResult?: Maybe<KeyResultUpdateOneWithoutCommentsInput>;
  nextComment?: Maybe<CommentUpdateOneWithoutPreviousCommentInput>;
  okr?: Maybe<OkrUpdateOneWithoutCommentsInput>;
  previousComment?: Maybe<CommentUpdateOneWithoutNextCommentInput>;
  review?: Maybe<ReviewUpdateOneWithoutCommentsInput>;
  solvedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type CommentUpdateWithoutOkrInput = {
  author?: Maybe<UserUpdateOneRequiredWithoutCommentInput>;
  content?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  keyResult?: Maybe<KeyResultUpdateOneWithoutCommentsInput>;
  nextComment?: Maybe<CommentUpdateOneWithoutPreviousCommentInput>;
  objective?: Maybe<ObjectiveUpdateOneWithoutCommentsInput>;
  previousComment?: Maybe<CommentUpdateOneWithoutNextCommentInput>;
  review?: Maybe<ReviewUpdateOneWithoutCommentsInput>;
  solvedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type CommentUpdateWithoutPreviousCommentInput = {
  author?: Maybe<UserUpdateOneRequiredWithoutCommentInput>;
  content?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  keyResult?: Maybe<KeyResultUpdateOneWithoutCommentsInput>;
  nextComment?: Maybe<CommentUpdateOneWithoutPreviousCommentInput>;
  objective?: Maybe<ObjectiveUpdateOneWithoutCommentsInput>;
  okr?: Maybe<OkrUpdateOneWithoutCommentsInput>;
  review?: Maybe<ReviewUpdateOneWithoutCommentsInput>;
  solvedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type CommentUpdateWithoutReviewInput = {
  author?: Maybe<UserUpdateOneRequiredWithoutCommentInput>;
  content?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  keyResult?: Maybe<KeyResultUpdateOneWithoutCommentsInput>;
  nextComment?: Maybe<CommentUpdateOneWithoutPreviousCommentInput>;
  objective?: Maybe<ObjectiveUpdateOneWithoutCommentsInput>;
  okr?: Maybe<OkrUpdateOneWithoutCommentsInput>;
  previousComment?: Maybe<CommentUpdateOneWithoutNextCommentInput>;
  solvedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type CommentUpdateWithWhereUniqueWithoutAuthorInput = {
  data: CommentUpdateWithoutAuthorInput;
  where: CommentWhereUniqueInput;
};

export type CommentUpdateWithWhereUniqueWithoutKeyResultInput = {
  data: CommentUpdateWithoutKeyResultInput;
  where: CommentWhereUniqueInput;
};

export type CommentUpdateWithWhereUniqueWithoutObjectiveInput = {
  data: CommentUpdateWithoutObjectiveInput;
  where: CommentWhereUniqueInput;
};

export type CommentUpdateWithWhereUniqueWithoutOkrInput = {
  data: CommentUpdateWithoutOkrInput;
  where: CommentWhereUniqueInput;
};

export type CommentUpdateWithWhereUniqueWithoutReviewInput = {
  data: CommentUpdateWithoutReviewInput;
  where: CommentWhereUniqueInput;
};

export type CommentUpsertWithoutNextCommentInput = {
  create: CommentCreateWithoutNextCommentInput;
  update: CommentUpdateWithoutNextCommentInput;
};

export type CommentUpsertWithoutPreviousCommentInput = {
  create: CommentCreateWithoutPreviousCommentInput;
  update: CommentUpdateWithoutPreviousCommentInput;
};

export type CommentUpsertWithWhereUniqueWithoutAuthorInput = {
  create: CommentCreateWithoutAuthorInput;
  update: CommentUpdateWithoutAuthorInput;
  where: CommentWhereUniqueInput;
};

export type CommentUpsertWithWhereUniqueWithoutKeyResultInput = {
  create: CommentCreateWithoutKeyResultInput;
  update: CommentUpdateWithoutKeyResultInput;
  where: CommentWhereUniqueInput;
};

export type CommentUpsertWithWhereUniqueWithoutObjectiveInput = {
  create: CommentCreateWithoutObjectiveInput;
  update: CommentUpdateWithoutObjectiveInput;
  where: CommentWhereUniqueInput;
};

export type CommentUpsertWithWhereUniqueWithoutOkrInput = {
  create: CommentCreateWithoutOkrInput;
  update: CommentUpdateWithoutOkrInput;
  where: CommentWhereUniqueInput;
};

export type CommentUpsertWithWhereUniqueWithoutReviewInput = {
  create: CommentCreateWithoutReviewInput;
  update: CommentUpdateWithoutReviewInput;
  where: CommentWhereUniqueInput;
};

export type CommentWhereInput = {
  AND?: Maybe<Array<CommentWhereInput>>;
  author?: Maybe<UserWhereInput>;
  authorId?: Maybe<StringFilter>;
  content?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  keyResult?: Maybe<KeyResultWhereInput>;
  keyResultId?: Maybe<StringNullableFilter>;
  nextComment?: Maybe<CommentWhereInput>;
  NOT?: Maybe<Array<CommentWhereInput>>;
  objective?: Maybe<ObjectiveWhereInput>;
  objectiveId?: Maybe<StringNullableFilter>;
  okr?: Maybe<OkrWhereInput>;
  okrId?: Maybe<StringNullableFilter>;
  OR?: Maybe<Array<CommentWhereInput>>;
  previousComment?: Maybe<CommentWhereInput>;
  previousCommentId?: Maybe<StringNullableFilter>;
  review?: Maybe<ReviewWhereInput>;
  reviewId?: Maybe<StringNullableFilter>;
  solvedAt?: Maybe<DateTimeNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type CommentWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  previousCommentId?: Maybe<Scalars['String']>;
};

export type ConnectCommentInput = {
  connect: CommentWhereUniqueInput;
};

export type ConnectKeyResultInput = {
  connect: KeyResultWhereUniqueInput;
};

export type ConnectObjectiveInput = {
  connect: ObjectiveWhereUniqueInput;
};

export type ConnectOkrInput = {
  connect: OkrWhereUniqueInput;
};

export type ConnectPeriodInput = {
  connect: PeriodWhereUniqueInput;
};

export type ConnectReviewInput = {
  connect: ReviewWhereUniqueInput;
};

export type CurrentPageUser = {
  __typename?: 'CurrentPageUser';
  avatar: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
};


export type DateTimeFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['DateTime']>;
};

export type DateTimeFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeFilter>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
};

export type DateTimeNullableFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeNullableFilter>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
};

export type EnumQuarterTypeNullableFilter = {
  equals?: Maybe<QuarterType>;
  in?: Maybe<Array<QuarterType>>;
  not?: Maybe<NestedEnumQuarterTypeNullableFilter>;
  notIn?: Maybe<Array<QuarterType>>;
};

export type EnumStatusFieldUpdateOperationsInput = {
  set?: Maybe<Status>;
};

export type EnumStatusFilter = {
  equals?: Maybe<Status>;
  in?: Maybe<Array<Status>>;
  not?: Maybe<NestedEnumStatusFilter>;
  notIn?: Maybe<Array<Status>>;
};

export type EnumUserSourceFieldUpdateOperationsInput = {
  set?: Maybe<UserSource>;
};

export type EnumUserSourceFilter = {
  equals?: Maybe<UserSource>;
  in?: Maybe<Array<UserSource>>;
  not?: Maybe<NestedEnumUserSourceFilter>;
  notIn?: Maybe<Array<UserSource>>;
};

export type FloatFieldUpdateOperationsInput = {
  decrement?: Maybe<Scalars['Float']>;
  divide?: Maybe<Scalars['Float']>;
  increment?: Maybe<Scalars['Float']>;
  multiply?: Maybe<Scalars['Float']>;
  set?: Maybe<Scalars['Float']>;
};

export type FloatFilter = {
  equals?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatFilter>;
  notIn?: Maybe<Array<Scalars['Float']>>;
};

export type GlanceOverHistory = {
  __typename?: 'GlanceOverHistory';
  belongToUser: User;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  okrAuthor: User;
  okrAuthorId: Scalars['String'];
  okrPeriod: Period;
  okrPeriodId: Scalars['String'];
  pathModule: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type GlanceOverHistoryCreateInput = {
  okrAuthorId: Scalars['String'];
  okrPeriodId: Scalars['String'];
  pathModule: Scalars['String'];
};

export type GlanceOverHistoryCreateManyBelongToUserInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  okrAuthorId: Scalars['String'];
  okrPeriodId: Scalars['String'];
  pathModule: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GlanceOverHistoryCreateManyBelongToUserInputEnvelope = {
  data?: Maybe<Array<GlanceOverHistoryCreateManyBelongToUserInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type GlanceOverHistoryCreateManyOkrAuthorInput = {
  belongToUserId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  okrPeriodId: Scalars['String'];
  pathModule: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GlanceOverHistoryCreateManyOkrAuthorInputEnvelope = {
  data?: Maybe<Array<GlanceOverHistoryCreateManyOkrAuthorInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type GlanceOverHistoryCreateManyOkrPeriodInput = {
  belongToUserId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  okrAuthorId: Scalars['String'];
  pathModule: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GlanceOverHistoryCreateManyOkrPeriodInputEnvelope = {
  data?: Maybe<Array<GlanceOverHistoryCreateManyOkrPeriodInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type GlanceOverHistoryCreateNestedManyWithoutBelongToUserInput = {
  connect?: Maybe<Array<GlanceOverHistoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<GlanceOverHistoryCreateOrConnectWithoutBelongToUserInput>>;
  create?: Maybe<Array<GlanceOverHistoryCreateWithoutBelongToUserInput>>;
  createMany?: Maybe<GlanceOverHistoryCreateManyBelongToUserInputEnvelope>;
};

export type GlanceOverHistoryCreateNestedManyWithoutOkrAuthorInput = {
  connect?: Maybe<Array<GlanceOverHistoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<GlanceOverHistoryCreateOrConnectWithoutOkrAuthorInput>>;
  create?: Maybe<Array<GlanceOverHistoryCreateWithoutOkrAuthorInput>>;
  createMany?: Maybe<GlanceOverHistoryCreateManyOkrAuthorInputEnvelope>;
};

export type GlanceOverHistoryCreateNestedManyWithoutOkrPeriodInput = {
  connect?: Maybe<Array<GlanceOverHistoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<GlanceOverHistoryCreateOrConnectWithoutOkrPeriodInput>>;
  create?: Maybe<Array<GlanceOverHistoryCreateWithoutOkrPeriodInput>>;
  createMany?: Maybe<GlanceOverHistoryCreateManyOkrPeriodInputEnvelope>;
};

export type GlanceOverHistoryCreateOrConnectWithoutBelongToUserInput = {
  create: GlanceOverHistoryCreateWithoutBelongToUserInput;
  where: GlanceOverHistoryWhereUniqueInput;
};

export type GlanceOverHistoryCreateOrConnectWithoutOkrAuthorInput = {
  create: GlanceOverHistoryCreateWithoutOkrAuthorInput;
  where: GlanceOverHistoryWhereUniqueInput;
};

export type GlanceOverHistoryCreateOrConnectWithoutOkrPeriodInput = {
  create: GlanceOverHistoryCreateWithoutOkrPeriodInput;
  where: GlanceOverHistoryWhereUniqueInput;
};

export type GlanceOverHistoryCreateWithoutBelongToUserInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  okrAuthor: UserCreateNestedOneWithoutGlanceOverOkrAuthorsInput;
  okrPeriod: PeriodCreateNestedOneWithoutGlanceOverHistoriesInput;
  pathModule: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GlanceOverHistoryCreateWithoutOkrAuthorInput = {
  belongToUser: UserCreateNestedOneWithoutGlanceOverHistoriesInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  okrPeriod: PeriodCreateNestedOneWithoutGlanceOverHistoriesInput;
  pathModule: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GlanceOverHistoryCreateWithoutOkrPeriodInput = {
  belongToUser: UserCreateNestedOneWithoutGlanceOverHistoriesInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  okrAuthor: UserCreateNestedOneWithoutGlanceOverOkrAuthorsInput;
  pathModule: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GlanceOverHistoryListRelationFilter = {
  every?: Maybe<GlanceOverHistoryWhereInput>;
  none?: Maybe<GlanceOverHistoryWhereInput>;
  some?: Maybe<GlanceOverHistoryWhereInput>;
};

export type GlanceOverHistoryScalarWhereInput = {
  AND?: Maybe<Array<GlanceOverHistoryScalarWhereInput>>;
  belongToUserId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  NOT?: Maybe<Array<GlanceOverHistoryScalarWhereInput>>;
  okrAuthorId?: Maybe<StringFilter>;
  okrPeriodId?: Maybe<StringFilter>;
  OR?: Maybe<Array<GlanceOverHistoryScalarWhereInput>>;
  pathModule?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type GlanceOverHistoryUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  pathModule?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type GlanceOverHistoryUpdateManyWithoutBelongToUserInput = {
  connect?: Maybe<Array<GlanceOverHistoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<GlanceOverHistoryCreateOrConnectWithoutBelongToUserInput>>;
  create?: Maybe<Array<GlanceOverHistoryCreateWithoutBelongToUserInput>>;
  createMany?: Maybe<GlanceOverHistoryCreateManyBelongToUserInputEnvelope>;
  delete?: Maybe<Array<GlanceOverHistoryWhereUniqueInput>>;
  deleteMany?: Maybe<Array<GlanceOverHistoryScalarWhereInput>>;
  disconnect?: Maybe<Array<GlanceOverHistoryWhereUniqueInput>>;
  set?: Maybe<Array<GlanceOverHistoryWhereUniqueInput>>;
  update?: Maybe<Array<GlanceOverHistoryUpdateWithWhereUniqueWithoutBelongToUserInput>>;
  updateMany?: Maybe<Array<GlanceOverHistoryUpdateManyWithWhereWithoutBelongToUserInput>>;
  upsert?: Maybe<Array<GlanceOverHistoryUpsertWithWhereUniqueWithoutBelongToUserInput>>;
};

export type GlanceOverHistoryUpdateManyWithoutOkrAuthorInput = {
  connect?: Maybe<Array<GlanceOverHistoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<GlanceOverHistoryCreateOrConnectWithoutOkrAuthorInput>>;
  create?: Maybe<Array<GlanceOverHistoryCreateWithoutOkrAuthorInput>>;
  createMany?: Maybe<GlanceOverHistoryCreateManyOkrAuthorInputEnvelope>;
  delete?: Maybe<Array<GlanceOverHistoryWhereUniqueInput>>;
  deleteMany?: Maybe<Array<GlanceOverHistoryScalarWhereInput>>;
  disconnect?: Maybe<Array<GlanceOverHistoryWhereUniqueInput>>;
  set?: Maybe<Array<GlanceOverHistoryWhereUniqueInput>>;
  update?: Maybe<Array<GlanceOverHistoryUpdateWithWhereUniqueWithoutOkrAuthorInput>>;
  updateMany?: Maybe<Array<GlanceOverHistoryUpdateManyWithWhereWithoutOkrAuthorInput>>;
  upsert?: Maybe<Array<GlanceOverHistoryUpsertWithWhereUniqueWithoutOkrAuthorInput>>;
};

export type GlanceOverHistoryUpdateManyWithoutOkrPeriodInput = {
  connect?: Maybe<Array<GlanceOverHistoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<GlanceOverHistoryCreateOrConnectWithoutOkrPeriodInput>>;
  create?: Maybe<Array<GlanceOverHistoryCreateWithoutOkrPeriodInput>>;
  createMany?: Maybe<GlanceOverHistoryCreateManyOkrPeriodInputEnvelope>;
  delete?: Maybe<Array<GlanceOverHistoryWhereUniqueInput>>;
  deleteMany?: Maybe<Array<GlanceOverHistoryScalarWhereInput>>;
  disconnect?: Maybe<Array<GlanceOverHistoryWhereUniqueInput>>;
  set?: Maybe<Array<GlanceOverHistoryWhereUniqueInput>>;
  update?: Maybe<Array<GlanceOverHistoryUpdateWithWhereUniqueWithoutOkrPeriodInput>>;
  updateMany?: Maybe<Array<GlanceOverHistoryUpdateManyWithWhereWithoutOkrPeriodInput>>;
  upsert?: Maybe<Array<GlanceOverHistoryUpsertWithWhereUniqueWithoutOkrPeriodInput>>;
};

export type GlanceOverHistoryUpdateManyWithWhereWithoutBelongToUserInput = {
  data: GlanceOverHistoryUpdateManyMutationInput;
  where: GlanceOverHistoryScalarWhereInput;
};

export type GlanceOverHistoryUpdateManyWithWhereWithoutOkrAuthorInput = {
  data: GlanceOverHistoryUpdateManyMutationInput;
  where: GlanceOverHistoryScalarWhereInput;
};

export type GlanceOverHistoryUpdateManyWithWhereWithoutOkrPeriodInput = {
  data: GlanceOverHistoryUpdateManyMutationInput;
  where: GlanceOverHistoryScalarWhereInput;
};

export type GlanceOverHistoryUpdateWithoutBelongToUserInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  okrAuthor?: Maybe<UserUpdateOneRequiredWithoutGlanceOverOkrAuthorsInput>;
  okrPeriod?: Maybe<PeriodUpdateOneRequiredWithoutGlanceOverHistoriesInput>;
  pathModule?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type GlanceOverHistoryUpdateWithoutOkrAuthorInput = {
  belongToUser?: Maybe<UserUpdateOneRequiredWithoutGlanceOverHistoriesInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  okrPeriod?: Maybe<PeriodUpdateOneRequiredWithoutGlanceOverHistoriesInput>;
  pathModule?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type GlanceOverHistoryUpdateWithoutOkrPeriodInput = {
  belongToUser?: Maybe<UserUpdateOneRequiredWithoutGlanceOverHistoriesInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  okrAuthor?: Maybe<UserUpdateOneRequiredWithoutGlanceOverOkrAuthorsInput>;
  pathModule?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type GlanceOverHistoryUpdateWithWhereUniqueWithoutBelongToUserInput = {
  data: GlanceOverHistoryUpdateWithoutBelongToUserInput;
  where: GlanceOverHistoryWhereUniqueInput;
};

export type GlanceOverHistoryUpdateWithWhereUniqueWithoutOkrAuthorInput = {
  data: GlanceOverHistoryUpdateWithoutOkrAuthorInput;
  where: GlanceOverHistoryWhereUniqueInput;
};

export type GlanceOverHistoryUpdateWithWhereUniqueWithoutOkrPeriodInput = {
  data: GlanceOverHistoryUpdateWithoutOkrPeriodInput;
  where: GlanceOverHistoryWhereUniqueInput;
};

export type GlanceOverHistoryUpsertWithWhereUniqueWithoutBelongToUserInput = {
  create: GlanceOverHistoryCreateWithoutBelongToUserInput;
  update: GlanceOverHistoryUpdateWithoutBelongToUserInput;
  where: GlanceOverHistoryWhereUniqueInput;
};

export type GlanceOverHistoryUpsertWithWhereUniqueWithoutOkrAuthorInput = {
  create: GlanceOverHistoryCreateWithoutOkrAuthorInput;
  update: GlanceOverHistoryUpdateWithoutOkrAuthorInput;
  where: GlanceOverHistoryWhereUniqueInput;
};

export type GlanceOverHistoryUpsertWithWhereUniqueWithoutOkrPeriodInput = {
  create: GlanceOverHistoryCreateWithoutOkrPeriodInput;
  update: GlanceOverHistoryUpdateWithoutOkrPeriodInput;
  where: GlanceOverHistoryWhereUniqueInput;
};

export type GlanceOverHistoryWhereInput = {
  AND?: Maybe<Array<GlanceOverHistoryWhereInput>>;
  belongToUser?: Maybe<UserWhereInput>;
  belongToUserId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  NOT?: Maybe<Array<GlanceOverHistoryWhereInput>>;
  okrAuthor?: Maybe<UserWhereInput>;
  okrAuthorId?: Maybe<StringFilter>;
  okrPeriod?: Maybe<PeriodWhereInput>;
  okrPeriodId?: Maybe<StringFilter>;
  OR?: Maybe<Array<GlanceOverHistoryWhereInput>>;
  pathModule?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type GlanceOverHistoryWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type IntNullableFilter = {
  equals?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntNullableFilter>;
  notIn?: Maybe<Array<Scalars['Int']>>;
};


export type KeyResult = {
  __typename?: 'KeyResult';
  comments: Array<Comment>;
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  id: Scalars['String'];
  objective: Objective;
  order: Scalars['Float'];
  private: Scalars['Boolean'];
  progress: Scalars['Float'];
  review?: Maybe<Review>;
  status: Status;
  updatedAt: Scalars['DateTime'];
  visibleToUsers: Array<User>;
  weight: Scalars['Float'];
};


export type KeyResultCommentsArgs = {
  cursor?: Maybe<CommentWhereUniqueInput>;
  orderBy?: Maybe<Array<CommentOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CommentWhereInput>;
};


export type KeyResultVisibleToUsersArgs = {
  cursor?: Maybe<UserWhereUniqueInput>;
  orderBy?: Maybe<Array<UserOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<UserWhereInput>;
};

export type KeyResultCreateInput = {
  description: Scalars['String'];
  objective: ConnectObjectiveInput;
  order: Scalars['Float'];
  progress: Scalars['Float'];
  status: Status;
  weight: Scalars['Float'];
};

export type KeyResultCreateManyObjectiveInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Float']>;
  private?: Maybe<Scalars['Boolean']>;
  progress: Scalars['Float'];
  status: Status;
  updatedAt?: Maybe<Scalars['DateTime']>;
  weight: Scalars['Float'];
};

export type KeyResultCreateManyObjectiveInputEnvelope = {
  data?: Maybe<Array<KeyResultCreateManyObjectiveInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type KeyResultCreateNestedManyWithoutObjectiveInput = {
  connect?: Maybe<Array<KeyResultWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<KeyResultCreateOrConnectWithoutObjectiveInput>>;
  create?: Maybe<Array<KeyResultCreateWithoutObjectiveInput>>;
  createMany?: Maybe<KeyResultCreateManyObjectiveInputEnvelope>;
};

export type KeyResultCreateNestedManyWithoutVisibleToUsersInput = {
  connect?: Maybe<Array<KeyResultWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<KeyResultCreateOrConnectWithoutVisibleToUsersInput>>;
  create?: Maybe<Array<KeyResultCreateWithoutVisibleToUsersInput>>;
};

export type KeyResultCreateNestedOneWithoutCommentsInput = {
  connect?: Maybe<KeyResultWhereUniqueInput>;
  connectOrCreate?: Maybe<KeyResultCreateOrConnectWithoutCommentsInput>;
  create?: Maybe<KeyResultCreateWithoutCommentsInput>;
};

export type KeyResultCreateNestedOneWithoutReviewInput = {
  connect?: Maybe<KeyResultWhereUniqueInput>;
  connectOrCreate?: Maybe<KeyResultCreateOrConnectWithoutReviewInput>;
  create?: Maybe<KeyResultCreateWithoutReviewInput>;
};

export type KeyResultCreateOrConnectWithoutCommentsInput = {
  create: KeyResultCreateWithoutCommentsInput;
  where: KeyResultWhereUniqueInput;
};

export type KeyResultCreateOrConnectWithoutObjectiveInput = {
  create: KeyResultCreateWithoutObjectiveInput;
  where: KeyResultWhereUniqueInput;
};

export type KeyResultCreateOrConnectWithoutReviewInput = {
  create: KeyResultCreateWithoutReviewInput;
  where: KeyResultWhereUniqueInput;
};

export type KeyResultCreateOrConnectWithoutVisibleToUsersInput = {
  create: KeyResultCreateWithoutVisibleToUsersInput;
  where: KeyResultWhereUniqueInput;
};

export type KeyResultCreateWithoutCommentsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  objective: ObjectiveCreateNestedOneWithoutKeyResultsInput;
  order?: Maybe<Scalars['Float']>;
  private?: Maybe<Scalars['Boolean']>;
  progress: Scalars['Float'];
  review?: Maybe<ReviewCreateNestedOneWithoutKeyResultInput>;
  status: Status;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visibleToUsers?: Maybe<UserCreateNestedManyWithoutPrivateKeyResultsThatCanSeeInput>;
  weight: Scalars['Float'];
};

export type KeyResultCreateWithoutObjectiveInput = {
  comments?: Maybe<CommentCreateNestedManyWithoutKeyResultInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Float']>;
  private?: Maybe<Scalars['Boolean']>;
  progress: Scalars['Float'];
  review?: Maybe<ReviewCreateNestedOneWithoutKeyResultInput>;
  status: Status;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visibleToUsers?: Maybe<UserCreateNestedManyWithoutPrivateKeyResultsThatCanSeeInput>;
  weight: Scalars['Float'];
};

export type KeyResultCreateWithoutReviewInput = {
  comments?: Maybe<CommentCreateNestedManyWithoutKeyResultInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  objective: ObjectiveCreateNestedOneWithoutKeyResultsInput;
  order?: Maybe<Scalars['Float']>;
  private?: Maybe<Scalars['Boolean']>;
  progress: Scalars['Float'];
  status: Status;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visibleToUsers?: Maybe<UserCreateNestedManyWithoutPrivateKeyResultsThatCanSeeInput>;
  weight: Scalars['Float'];
};

export type KeyResultCreateWithoutVisibleToUsersInput = {
  comments?: Maybe<CommentCreateNestedManyWithoutKeyResultInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  objective: ObjectiveCreateNestedOneWithoutKeyResultsInput;
  order?: Maybe<Scalars['Float']>;
  private?: Maybe<Scalars['Boolean']>;
  progress: Scalars['Float'];
  review?: Maybe<ReviewCreateNestedOneWithoutKeyResultInput>;
  status: Status;
  updatedAt?: Maybe<Scalars['DateTime']>;
  weight: Scalars['Float'];
};

export type KeyResultListRelationFilter = {
  every?: Maybe<KeyResultWhereInput>;
  none?: Maybe<KeyResultWhereInput>;
  some?: Maybe<KeyResultWhereInput>;
};

export type KeyResultOrderByInput = {
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  objectiveId?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  private?: Maybe<SortOrder>;
  progress?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  weight?: Maybe<SortOrder>;
};

export type KeyResultScalarWhereInput = {
  AND?: Maybe<Array<KeyResultScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  description?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  NOT?: Maybe<Array<KeyResultScalarWhereInput>>;
  objectiveId?: Maybe<StringFilter>;
  OR?: Maybe<Array<KeyResultScalarWhereInput>>;
  order?: Maybe<FloatFilter>;
  private?: Maybe<BoolFilter>;
  progress?: Maybe<FloatFilter>;
  status?: Maybe<EnumStatusFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  weight?: Maybe<FloatFilter>;
};

export type KeyResultsReorderInput = {
  orders: Array<OrdersInput>;
};

export type KeyResultsReweightInput = {
  weights: Array<WeightsInput>;
};

export type KeyResultUpdateInput = {
  description?: Maybe<Scalars['String']>;
  progress?: Maybe<Scalars['Float']>;
  review?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
};

export type KeyResultUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<StringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<FloatFieldUpdateOperationsInput>;
  private?: Maybe<BoolFieldUpdateOperationsInput>;
  progress?: Maybe<FloatFieldUpdateOperationsInput>;
  status?: Maybe<EnumStatusFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  weight?: Maybe<FloatFieldUpdateOperationsInput>;
};

export type KeyResultUpdateManyWithoutObjectiveInput = {
  connect?: Maybe<Array<KeyResultWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<KeyResultCreateOrConnectWithoutObjectiveInput>>;
  create?: Maybe<Array<KeyResultCreateWithoutObjectiveInput>>;
  createMany?: Maybe<KeyResultCreateManyObjectiveInputEnvelope>;
  delete?: Maybe<Array<KeyResultWhereUniqueInput>>;
  deleteMany?: Maybe<Array<KeyResultScalarWhereInput>>;
  disconnect?: Maybe<Array<KeyResultWhereUniqueInput>>;
  set?: Maybe<Array<KeyResultWhereUniqueInput>>;
  update?: Maybe<Array<KeyResultUpdateWithWhereUniqueWithoutObjectiveInput>>;
  updateMany?: Maybe<Array<KeyResultUpdateManyWithWhereWithoutObjectiveInput>>;
  upsert?: Maybe<Array<KeyResultUpsertWithWhereUniqueWithoutObjectiveInput>>;
};

export type KeyResultUpdateManyWithoutVisibleToUsersInput = {
  connect?: Maybe<Array<KeyResultWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<KeyResultCreateOrConnectWithoutVisibleToUsersInput>>;
  create?: Maybe<Array<KeyResultCreateWithoutVisibleToUsersInput>>;
  delete?: Maybe<Array<KeyResultWhereUniqueInput>>;
  deleteMany?: Maybe<Array<KeyResultScalarWhereInput>>;
  disconnect?: Maybe<Array<KeyResultWhereUniqueInput>>;
  set?: Maybe<Array<KeyResultWhereUniqueInput>>;
  update?: Maybe<Array<KeyResultUpdateWithWhereUniqueWithoutVisibleToUsersInput>>;
  updateMany?: Maybe<Array<KeyResultUpdateManyWithWhereWithoutVisibleToUsersInput>>;
  upsert?: Maybe<Array<KeyResultUpsertWithWhereUniqueWithoutVisibleToUsersInput>>;
};

export type KeyResultUpdateManyWithWhereWithoutObjectiveInput = {
  data: KeyResultUpdateManyMutationInput;
  where: KeyResultScalarWhereInput;
};

export type KeyResultUpdateManyWithWhereWithoutVisibleToUsersInput = {
  data: KeyResultUpdateManyMutationInput;
  where: KeyResultScalarWhereInput;
};

export type KeyResultUpdateOneWithoutCommentsInput = {
  connect?: Maybe<KeyResultWhereUniqueInput>;
  connectOrCreate?: Maybe<KeyResultCreateOrConnectWithoutCommentsInput>;
  create?: Maybe<KeyResultCreateWithoutCommentsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<KeyResultUpdateWithoutCommentsInput>;
  upsert?: Maybe<KeyResultUpsertWithoutCommentsInput>;
};

export type KeyResultUpdateOneWithoutReviewInput = {
  connect?: Maybe<KeyResultWhereUniqueInput>;
  connectOrCreate?: Maybe<KeyResultCreateOrConnectWithoutReviewInput>;
  create?: Maybe<KeyResultCreateWithoutReviewInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<KeyResultUpdateWithoutReviewInput>;
  upsert?: Maybe<KeyResultUpsertWithoutReviewInput>;
};

export type KeyResultUpdateWithoutCommentsInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<StringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  objective?: Maybe<ObjectiveUpdateOneRequiredWithoutKeyResultsInput>;
  order?: Maybe<FloatFieldUpdateOperationsInput>;
  private?: Maybe<BoolFieldUpdateOperationsInput>;
  progress?: Maybe<FloatFieldUpdateOperationsInput>;
  review?: Maybe<ReviewUpdateOneWithoutKeyResultInput>;
  status?: Maybe<EnumStatusFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  visibleToUsers?: Maybe<UserUpdateManyWithoutPrivateKeyResultsThatCanSeeInput>;
  weight?: Maybe<FloatFieldUpdateOperationsInput>;
};

export type KeyResultUpdateWithoutObjectiveInput = {
  comments?: Maybe<CommentUpdateManyWithoutKeyResultInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<StringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<FloatFieldUpdateOperationsInput>;
  private?: Maybe<BoolFieldUpdateOperationsInput>;
  progress?: Maybe<FloatFieldUpdateOperationsInput>;
  review?: Maybe<ReviewUpdateOneWithoutKeyResultInput>;
  status?: Maybe<EnumStatusFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  visibleToUsers?: Maybe<UserUpdateManyWithoutPrivateKeyResultsThatCanSeeInput>;
  weight?: Maybe<FloatFieldUpdateOperationsInput>;
};

export type KeyResultUpdateWithoutReviewInput = {
  comments?: Maybe<CommentUpdateManyWithoutKeyResultInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<StringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  objective?: Maybe<ObjectiveUpdateOneRequiredWithoutKeyResultsInput>;
  order?: Maybe<FloatFieldUpdateOperationsInput>;
  private?: Maybe<BoolFieldUpdateOperationsInput>;
  progress?: Maybe<FloatFieldUpdateOperationsInput>;
  status?: Maybe<EnumStatusFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  visibleToUsers?: Maybe<UserUpdateManyWithoutPrivateKeyResultsThatCanSeeInput>;
  weight?: Maybe<FloatFieldUpdateOperationsInput>;
};

export type KeyResultUpdateWithoutVisibleToUsersInput = {
  comments?: Maybe<CommentUpdateManyWithoutKeyResultInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<StringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  objective?: Maybe<ObjectiveUpdateOneRequiredWithoutKeyResultsInput>;
  order?: Maybe<FloatFieldUpdateOperationsInput>;
  private?: Maybe<BoolFieldUpdateOperationsInput>;
  progress?: Maybe<FloatFieldUpdateOperationsInput>;
  review?: Maybe<ReviewUpdateOneWithoutKeyResultInput>;
  status?: Maybe<EnumStatusFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  weight?: Maybe<FloatFieldUpdateOperationsInput>;
};

export type KeyResultUpdateWithWhereUniqueWithoutObjectiveInput = {
  data: KeyResultUpdateWithoutObjectiveInput;
  where: KeyResultWhereUniqueInput;
};

export type KeyResultUpdateWithWhereUniqueWithoutVisibleToUsersInput = {
  data: KeyResultUpdateWithoutVisibleToUsersInput;
  where: KeyResultWhereUniqueInput;
};

export type KeyResultUpsertWithoutCommentsInput = {
  create: KeyResultCreateWithoutCommentsInput;
  update: KeyResultUpdateWithoutCommentsInput;
};

export type KeyResultUpsertWithoutReviewInput = {
  create: KeyResultCreateWithoutReviewInput;
  update: KeyResultUpdateWithoutReviewInput;
};

export type KeyResultUpsertWithWhereUniqueWithoutObjectiveInput = {
  create: KeyResultCreateWithoutObjectiveInput;
  update: KeyResultUpdateWithoutObjectiveInput;
  where: KeyResultWhereUniqueInput;
};

export type KeyResultUpsertWithWhereUniqueWithoutVisibleToUsersInput = {
  create: KeyResultCreateWithoutVisibleToUsersInput;
  update: KeyResultUpdateWithoutVisibleToUsersInput;
  where: KeyResultWhereUniqueInput;
};

export type KeyResultWhereInput = {
  AND?: Maybe<Array<KeyResultWhereInput>>;
  comments?: Maybe<CommentListRelationFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  description?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  NOT?: Maybe<Array<KeyResultWhereInput>>;
  objective?: Maybe<ObjectiveWhereInput>;
  objectiveId?: Maybe<StringFilter>;
  OR?: Maybe<Array<KeyResultWhereInput>>;
  order?: Maybe<FloatFilter>;
  private?: Maybe<BoolFilter>;
  progress?: Maybe<FloatFilter>;
  review?: Maybe<ReviewWhereInput>;
  status?: Maybe<EnumStatusFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  visibleToUsers?: Maybe<UserListRelationFilter>;
  weight?: Maybe<FloatFilter>;
};

export type KeyResultWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type Login = {
  __typename?: 'Login';
  token?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  alignToOneObjective?: Maybe<Objective>;
  copyObjectiveToOkr?: Maybe<Objective>;
  createOneComment?: Maybe<Comment>;
  createOneKeyResult?: Maybe<KeyResult>;
  createOneObjective?: Maybe<Objective>;
  createOneOkr?: Maybe<Okr>;
  createOnePeriod: Period;
  createOneReview: Review;
  deleteOneComment?: Maybe<Comment>;
  deleteOneKeyResult?: Maybe<KeyResult>;
  deleteOneObjective?: Maybe<Objective>;
  deleteOneOkr?: Maybe<Okr>;
  login?: Maybe<Login>;
  removeAlignmentFromOneObjective?: Maybe<Objective>;
  reopenOneComment?: Maybe<Comment>;
  reorderKeyResults?: Maybe<Array<KeyResult>>;
  reorderObjectives?: Maybe<Array<Objective>>;
  reweightKeyResults?: Maybe<Array<KeyResult>>;
  solveOneComment?: Maybe<Comment>;
  updateOneComment?: Maybe<Comment>;
  updateOneKeyResult?: Maybe<KeyResult>;
  updateOneObjective?: Maybe<Objective>;
  updateOneOkr?: Maybe<Okr>;
  updateOneUser?: Maybe<User>;
  upsertOneGlanceOverHistory?: Maybe<GlanceOverHistory>;
};


export type MutationAlignToOneObjectiveArgs = {
  data: ObjectiveAlignInput;
  where: ObjectiveWhereUniqueInput;
};


export type MutationCopyObjectiveToOkrArgs = {
  data: ObjectiveCopyInput;
};


export type MutationCreateOneCommentArgs = {
  data: CommentCreateInput;
  effect?: Maybe<CommentCreateEffect>;
};


export type MutationCreateOneKeyResultArgs = {
  data: KeyResultCreateInput;
};


export type MutationCreateOneObjectiveArgs = {
  data: ObjectiveCreateInput;
};


export type MutationCreateOneOkrArgs = {
  data: OkrCreateInput;
};


export type MutationCreateOnePeriodArgs = {
  data: PeriodCreateInput;
};


export type MutationCreateOneReviewArgs = {
  data: ReviewCreateInput;
};


export type MutationDeleteOneCommentArgs = {
  where: CommentWhereUniqueInput;
};


export type MutationDeleteOneKeyResultArgs = {
  where: KeyResultWhereUniqueInput;
};


export type MutationDeleteOneObjectiveArgs = {
  where: ObjectiveWhereUniqueInput;
};


export type MutationDeleteOneOkrArgs = {
  where: OkrWhereUniqueInput;
};


export type MutationLoginArgs = {
  email: Scalars['String'];
};


export type MutationRemoveAlignmentFromOneObjectiveArgs = {
  data: ObjectiveRemoveAlignmentInput;
  where: ObjectiveWhereUniqueInput;
};


export type MutationReopenOneCommentArgs = {
  where: CommentWhereUniqueInput;
};


export type MutationReorderKeyResultsArgs = {
  data: KeyResultsReorderInput;
  where: ObjectiveWhereUniqueInput;
};


export type MutationReorderObjectivesArgs = {
  data: ObjectivesReorderInput;
  where: OkrWhereUniqueInput;
};


export type MutationReweightKeyResultsArgs = {
  data: KeyResultsReweightInput;
  where: ObjectiveWhereUniqueInput;
};


export type MutationSolveOneCommentArgs = {
  where: CommentWhereUniqueInput;
};


export type MutationUpdateOneCommentArgs = {
  data: CommentUpdateInput;
  where: CommentWhereUniqueInput;
};


export type MutationUpdateOneKeyResultArgs = {
  data: KeyResultUpdateInput;
  where: KeyResultWhereUniqueInput;
};


export type MutationUpdateOneObjectiveArgs = {
  data: ObjectiveUpdateInput;
  where: ObjectiveWhereUniqueInput;
};


export type MutationUpdateOneOkrArgs = {
  data: OkrUpdateInput;
  where: OkrWhereUniqueInput;
};


export type MutationUpdateOneUserArgs = {
  data: UserUpdateInput;
  where: UserWhereUniqueInput;
};


export type MutationUpsertOneGlanceOverHistoryArgs = {
  data: GlanceOverHistoryCreateInput;
};

export type NestedBoolFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolFilter>;
};

export type NestedBoolNullableFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolNullableFilter>;
};

export type NestedDateTimeFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeFilter>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
};

export type NestedDateTimeNullableFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeNullableFilter>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
};

export type NestedEnumQuarterTypeNullableFilter = {
  equals?: Maybe<QuarterType>;
  in?: Maybe<Array<QuarterType>>;
  not?: Maybe<NestedEnumQuarterTypeNullableFilter>;
  notIn?: Maybe<Array<QuarterType>>;
};

export type NestedEnumStatusFilter = {
  equals?: Maybe<Status>;
  in?: Maybe<Array<Status>>;
  not?: Maybe<NestedEnumStatusFilter>;
  notIn?: Maybe<Array<Status>>;
};

export type NestedEnumUserSourceFilter = {
  equals?: Maybe<UserSource>;
  in?: Maybe<Array<UserSource>>;
  not?: Maybe<NestedEnumUserSourceFilter>;
  notIn?: Maybe<Array<UserSource>>;
};

export type NestedFloatFilter = {
  equals?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatFilter>;
  notIn?: Maybe<Array<Scalars['Float']>>;
};

export type NestedIntNullableFilter = {
  equals?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntNullableFilter>;
  notIn?: Maybe<Array<Scalars['Int']>>;
};

export type NestedStringFilter = {
  contains?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  equals?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  not?: Maybe<NestedStringFilter>;
  notIn?: Maybe<Array<Scalars['String']>>;
  startsWith?: Maybe<Scalars['String']>;
};

export type NestedStringNullableFilter = {
  contains?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  equals?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  not?: Maybe<NestedStringNullableFilter>;
  notIn?: Maybe<Array<Scalars['String']>>;
  startsWith?: Maybe<Scalars['String']>;
};

export type NullableBoolFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['Boolean']>;
};

export type NullableDateTimeFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['DateTime']>;
};

export type NullableEnumQuarterTypeFieldUpdateOperationsInput = {
  set?: Maybe<QuarterType>;
};

export type NullableIntFieldUpdateOperationsInput = {
  decrement?: Maybe<Scalars['Int']>;
  divide?: Maybe<Scalars['Int']>;
  increment?: Maybe<Scalars['Int']>;
  multiply?: Maybe<Scalars['Int']>;
  set?: Maybe<Scalars['Int']>;
};

export type NullableStringFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['String']>;
};

export type Objective = {
  __typename?: 'Objective';
  alignedTo: Array<Objective>;
  alignedWith: Array<Objective>;
  comments: Array<Comment>;
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  id: Scalars['String'];
  keyResults: Array<KeyResult>;
  okr: Okr;
  order: Scalars['Float'];
  private: Scalars['Boolean'];
  review?: Maybe<Review>;
  status: Status;
  updatedAt: Scalars['DateTime'];
  visibleToUsers: Array<User>;
};


export type ObjectiveAlignedToArgs = {
  cursor?: Maybe<ObjectiveWhereUniqueInput>;
  orderBy?: Maybe<Array<ObjectiveOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ObjectiveWhereInput>;
};


export type ObjectiveAlignedWithArgs = {
  cursor?: Maybe<ObjectiveWhereUniqueInput>;
  orderBy?: Maybe<Array<ObjectiveOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ObjectiveWhereInput>;
};


export type ObjectiveCommentsArgs = {
  cursor?: Maybe<CommentWhereUniqueInput>;
  orderBy?: Maybe<Array<CommentOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CommentWhereInput>;
};


export type ObjectiveKeyResultsArgs = {
  cursor?: Maybe<KeyResultWhereUniqueInput>;
  orderBy?: Maybe<Array<KeyResultOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<KeyResultWhereInput>;
};


export type ObjectiveVisibleToUsersArgs = {
  cursor?: Maybe<UserWhereUniqueInput>;
  orderBy?: Maybe<Array<UserOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<UserWhereInput>;
};

export type ObjectiveAlignInput = {
  connect: ObjectiveWhereUniqueInput;
};

export type ObjectiveCopyInput = {
  objectiveId: Scalars['String'];
  targetOkrId: Scalars['String'];
};

export type ObjectiveCreateInput = {
  description: Scalars['String'];
  okr: ConnectOkrInput;
  order: Scalars['Float'];
  review: ReviewCreateInput;
  status: Status;
};

export type ObjectiveCreateManyOkrInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Float']>;
  private?: Maybe<Scalars['Boolean']>;
  status: Status;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ObjectiveCreateManyOkrInputEnvelope = {
  data?: Maybe<Array<ObjectiveCreateManyOkrInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ObjectiveCreateNestedManyWithoutAlignedToInput = {
  connect?: Maybe<Array<ObjectiveWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ObjectiveCreateOrConnectWithoutAlignedToInput>>;
  create?: Maybe<Array<ObjectiveCreateWithoutAlignedToInput>>;
};

export type ObjectiveCreateNestedManyWithoutAlignedWithInput = {
  connect?: Maybe<Array<ObjectiveWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ObjectiveCreateOrConnectWithoutAlignedWithInput>>;
  create?: Maybe<Array<ObjectiveCreateWithoutAlignedWithInput>>;
};

export type ObjectiveCreateNestedManyWithoutOkrInput = {
  connect?: Maybe<Array<ObjectiveWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ObjectiveCreateOrConnectWithoutOkrInput>>;
  create?: Maybe<Array<ObjectiveCreateWithoutOkrInput>>;
  createMany?: Maybe<ObjectiveCreateManyOkrInputEnvelope>;
};

export type ObjectiveCreateNestedManyWithoutVisibleToUsersInput = {
  connect?: Maybe<Array<ObjectiveWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ObjectiveCreateOrConnectWithoutVisibleToUsersInput>>;
  create?: Maybe<Array<ObjectiveCreateWithoutVisibleToUsersInput>>;
};

export type ObjectiveCreateNestedOneWithoutCommentsInput = {
  connect?: Maybe<ObjectiveWhereUniqueInput>;
  connectOrCreate?: Maybe<ObjectiveCreateOrConnectWithoutCommentsInput>;
  create?: Maybe<ObjectiveCreateWithoutCommentsInput>;
};

export type ObjectiveCreateNestedOneWithoutKeyResultsInput = {
  connect?: Maybe<ObjectiveWhereUniqueInput>;
  connectOrCreate?: Maybe<ObjectiveCreateOrConnectWithoutKeyResultsInput>;
  create?: Maybe<ObjectiveCreateWithoutKeyResultsInput>;
};

export type ObjectiveCreateNestedOneWithoutReviewInput = {
  connect?: Maybe<ObjectiveWhereUniqueInput>;
  connectOrCreate?: Maybe<ObjectiveCreateOrConnectWithoutReviewInput>;
  create?: Maybe<ObjectiveCreateWithoutReviewInput>;
};

export type ObjectiveCreateOrConnectWithoutAlignedToInput = {
  create: ObjectiveCreateWithoutAlignedToInput;
  where: ObjectiveWhereUniqueInput;
};

export type ObjectiveCreateOrConnectWithoutAlignedWithInput = {
  create: ObjectiveCreateWithoutAlignedWithInput;
  where: ObjectiveWhereUniqueInput;
};

export type ObjectiveCreateOrConnectWithoutCommentsInput = {
  create: ObjectiveCreateWithoutCommentsInput;
  where: ObjectiveWhereUniqueInput;
};

export type ObjectiveCreateOrConnectWithoutKeyResultsInput = {
  create: ObjectiveCreateWithoutKeyResultsInput;
  where: ObjectiveWhereUniqueInput;
};

export type ObjectiveCreateOrConnectWithoutOkrInput = {
  create: ObjectiveCreateWithoutOkrInput;
  where: ObjectiveWhereUniqueInput;
};

export type ObjectiveCreateOrConnectWithoutReviewInput = {
  create: ObjectiveCreateWithoutReviewInput;
  where: ObjectiveWhereUniqueInput;
};

export type ObjectiveCreateOrConnectWithoutVisibleToUsersInput = {
  create: ObjectiveCreateWithoutVisibleToUsersInput;
  where: ObjectiveWhereUniqueInput;
};

export type ObjectiveCreateWithoutAlignedToInput = {
  alignedWith?: Maybe<ObjectiveCreateNestedManyWithoutAlignedToInput>;
  comments?: Maybe<CommentCreateNestedManyWithoutObjectiveInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  keyResults?: Maybe<KeyResultCreateNestedManyWithoutObjectiveInput>;
  okr: OkrCreateNestedOneWithoutObjectivesInput;
  order?: Maybe<Scalars['Float']>;
  private?: Maybe<Scalars['Boolean']>;
  review?: Maybe<ReviewCreateNestedOneWithoutObjectiveInput>;
  status: Status;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visibleToUsers?: Maybe<UserCreateNestedManyWithoutPrivateObjectivesThatCanSeeInput>;
};

export type ObjectiveCreateWithoutAlignedWithInput = {
  alignedTo?: Maybe<ObjectiveCreateNestedManyWithoutAlignedWithInput>;
  comments?: Maybe<CommentCreateNestedManyWithoutObjectiveInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  keyResults?: Maybe<KeyResultCreateNestedManyWithoutObjectiveInput>;
  okr: OkrCreateNestedOneWithoutObjectivesInput;
  order?: Maybe<Scalars['Float']>;
  private?: Maybe<Scalars['Boolean']>;
  review?: Maybe<ReviewCreateNestedOneWithoutObjectiveInput>;
  status: Status;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visibleToUsers?: Maybe<UserCreateNestedManyWithoutPrivateObjectivesThatCanSeeInput>;
};

export type ObjectiveCreateWithoutCommentsInput = {
  alignedTo?: Maybe<ObjectiveCreateNestedManyWithoutAlignedWithInput>;
  alignedWith?: Maybe<ObjectiveCreateNestedManyWithoutAlignedToInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  keyResults?: Maybe<KeyResultCreateNestedManyWithoutObjectiveInput>;
  okr: OkrCreateNestedOneWithoutObjectivesInput;
  order?: Maybe<Scalars['Float']>;
  private?: Maybe<Scalars['Boolean']>;
  review?: Maybe<ReviewCreateNestedOneWithoutObjectiveInput>;
  status: Status;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visibleToUsers?: Maybe<UserCreateNestedManyWithoutPrivateObjectivesThatCanSeeInput>;
};

export type ObjectiveCreateWithoutKeyResultsInput = {
  alignedTo?: Maybe<ObjectiveCreateNestedManyWithoutAlignedWithInput>;
  alignedWith?: Maybe<ObjectiveCreateNestedManyWithoutAlignedToInput>;
  comments?: Maybe<CommentCreateNestedManyWithoutObjectiveInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  okr: OkrCreateNestedOneWithoutObjectivesInput;
  order?: Maybe<Scalars['Float']>;
  private?: Maybe<Scalars['Boolean']>;
  review?: Maybe<ReviewCreateNestedOneWithoutObjectiveInput>;
  status: Status;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visibleToUsers?: Maybe<UserCreateNestedManyWithoutPrivateObjectivesThatCanSeeInput>;
};

export type ObjectiveCreateWithoutOkrInput = {
  alignedTo?: Maybe<ObjectiveCreateNestedManyWithoutAlignedWithInput>;
  alignedWith?: Maybe<ObjectiveCreateNestedManyWithoutAlignedToInput>;
  comments?: Maybe<CommentCreateNestedManyWithoutObjectiveInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  keyResults?: Maybe<KeyResultCreateNestedManyWithoutObjectiveInput>;
  order?: Maybe<Scalars['Float']>;
  private?: Maybe<Scalars['Boolean']>;
  review?: Maybe<ReviewCreateNestedOneWithoutObjectiveInput>;
  status: Status;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visibleToUsers?: Maybe<UserCreateNestedManyWithoutPrivateObjectivesThatCanSeeInput>;
};

export type ObjectiveCreateWithoutReviewInput = {
  alignedTo?: Maybe<ObjectiveCreateNestedManyWithoutAlignedWithInput>;
  alignedWith?: Maybe<ObjectiveCreateNestedManyWithoutAlignedToInput>;
  comments?: Maybe<CommentCreateNestedManyWithoutObjectiveInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  keyResults?: Maybe<KeyResultCreateNestedManyWithoutObjectiveInput>;
  okr: OkrCreateNestedOneWithoutObjectivesInput;
  order?: Maybe<Scalars['Float']>;
  private?: Maybe<Scalars['Boolean']>;
  status: Status;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visibleToUsers?: Maybe<UserCreateNestedManyWithoutPrivateObjectivesThatCanSeeInput>;
};

export type ObjectiveCreateWithoutVisibleToUsersInput = {
  alignedTo?: Maybe<ObjectiveCreateNestedManyWithoutAlignedWithInput>;
  alignedWith?: Maybe<ObjectiveCreateNestedManyWithoutAlignedToInput>;
  comments?: Maybe<CommentCreateNestedManyWithoutObjectiveInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  keyResults?: Maybe<KeyResultCreateNestedManyWithoutObjectiveInput>;
  okr: OkrCreateNestedOneWithoutObjectivesInput;
  order?: Maybe<Scalars['Float']>;
  private?: Maybe<Scalars['Boolean']>;
  review?: Maybe<ReviewCreateNestedOneWithoutObjectiveInput>;
  status: Status;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ObjectiveListRelationFilter = {
  every?: Maybe<ObjectiveWhereInput>;
  none?: Maybe<ObjectiveWhereInput>;
  some?: Maybe<ObjectiveWhereInput>;
};

export type ObjectiveOrderByInput = {
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  okrId?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  private?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ObjectiveRemoveAlignmentInput = {
  disconnect: ObjectiveWhereUniqueInput;
};

export type ObjectiveScalarWhereInput = {
  AND?: Maybe<Array<ObjectiveScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  description?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  NOT?: Maybe<Array<ObjectiveScalarWhereInput>>;
  okrId?: Maybe<StringFilter>;
  OR?: Maybe<Array<ObjectiveScalarWhereInput>>;
  order?: Maybe<FloatFilter>;
  private?: Maybe<BoolFilter>;
  status?: Maybe<EnumStatusFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type ObjectivesReorderInput = {
  orders: Array<OrdersInput>;
};

export type ObjectiveUpdateInput = {
  description?: Maybe<Scalars['String']>;
  review?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
};

export type ObjectiveUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<StringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<FloatFieldUpdateOperationsInput>;
  private?: Maybe<BoolFieldUpdateOperationsInput>;
  status?: Maybe<EnumStatusFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type ObjectiveUpdateManyWithoutAlignedToInput = {
  connect?: Maybe<Array<ObjectiveWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ObjectiveCreateOrConnectWithoutAlignedToInput>>;
  create?: Maybe<Array<ObjectiveCreateWithoutAlignedToInput>>;
  delete?: Maybe<Array<ObjectiveWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ObjectiveScalarWhereInput>>;
  disconnect?: Maybe<Array<ObjectiveWhereUniqueInput>>;
  set?: Maybe<Array<ObjectiveWhereUniqueInput>>;
  update?: Maybe<Array<ObjectiveUpdateWithWhereUniqueWithoutAlignedToInput>>;
  updateMany?: Maybe<Array<ObjectiveUpdateManyWithWhereWithoutAlignedToInput>>;
  upsert?: Maybe<Array<ObjectiveUpsertWithWhereUniqueWithoutAlignedToInput>>;
};

export type ObjectiveUpdateManyWithoutAlignedWithInput = {
  connect?: Maybe<Array<ObjectiveWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ObjectiveCreateOrConnectWithoutAlignedWithInput>>;
  create?: Maybe<Array<ObjectiveCreateWithoutAlignedWithInput>>;
  delete?: Maybe<Array<ObjectiveWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ObjectiveScalarWhereInput>>;
  disconnect?: Maybe<Array<ObjectiveWhereUniqueInput>>;
  set?: Maybe<Array<ObjectiveWhereUniqueInput>>;
  update?: Maybe<Array<ObjectiveUpdateWithWhereUniqueWithoutAlignedWithInput>>;
  updateMany?: Maybe<Array<ObjectiveUpdateManyWithWhereWithoutAlignedWithInput>>;
  upsert?: Maybe<Array<ObjectiveUpsertWithWhereUniqueWithoutAlignedWithInput>>;
};

export type ObjectiveUpdateManyWithoutOkrInput = {
  connect?: Maybe<Array<ObjectiveWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ObjectiveCreateOrConnectWithoutOkrInput>>;
  create?: Maybe<Array<ObjectiveCreateWithoutOkrInput>>;
  createMany?: Maybe<ObjectiveCreateManyOkrInputEnvelope>;
  delete?: Maybe<Array<ObjectiveWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ObjectiveScalarWhereInput>>;
  disconnect?: Maybe<Array<ObjectiveWhereUniqueInput>>;
  set?: Maybe<Array<ObjectiveWhereUniqueInput>>;
  update?: Maybe<Array<ObjectiveUpdateWithWhereUniqueWithoutOkrInput>>;
  updateMany?: Maybe<Array<ObjectiveUpdateManyWithWhereWithoutOkrInput>>;
  upsert?: Maybe<Array<ObjectiveUpsertWithWhereUniqueWithoutOkrInput>>;
};

export type ObjectiveUpdateManyWithoutVisibleToUsersInput = {
  connect?: Maybe<Array<ObjectiveWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ObjectiveCreateOrConnectWithoutVisibleToUsersInput>>;
  create?: Maybe<Array<ObjectiveCreateWithoutVisibleToUsersInput>>;
  delete?: Maybe<Array<ObjectiveWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ObjectiveScalarWhereInput>>;
  disconnect?: Maybe<Array<ObjectiveWhereUniqueInput>>;
  set?: Maybe<Array<ObjectiveWhereUniqueInput>>;
  update?: Maybe<Array<ObjectiveUpdateWithWhereUniqueWithoutVisibleToUsersInput>>;
  updateMany?: Maybe<Array<ObjectiveUpdateManyWithWhereWithoutVisibleToUsersInput>>;
  upsert?: Maybe<Array<ObjectiveUpsertWithWhereUniqueWithoutVisibleToUsersInput>>;
};

export type ObjectiveUpdateManyWithWhereWithoutAlignedToInput = {
  data: ObjectiveUpdateManyMutationInput;
  where: ObjectiveScalarWhereInput;
};

export type ObjectiveUpdateManyWithWhereWithoutAlignedWithInput = {
  data: ObjectiveUpdateManyMutationInput;
  where: ObjectiveScalarWhereInput;
};

export type ObjectiveUpdateManyWithWhereWithoutOkrInput = {
  data: ObjectiveUpdateManyMutationInput;
  where: ObjectiveScalarWhereInput;
};

export type ObjectiveUpdateManyWithWhereWithoutVisibleToUsersInput = {
  data: ObjectiveUpdateManyMutationInput;
  where: ObjectiveScalarWhereInput;
};

export type ObjectiveUpdateOneRequiredWithoutKeyResultsInput = {
  connect?: Maybe<ObjectiveWhereUniqueInput>;
  connectOrCreate?: Maybe<ObjectiveCreateOrConnectWithoutKeyResultsInput>;
  create?: Maybe<ObjectiveCreateWithoutKeyResultsInput>;
  update?: Maybe<ObjectiveUpdateWithoutKeyResultsInput>;
  upsert?: Maybe<ObjectiveUpsertWithoutKeyResultsInput>;
};

export type ObjectiveUpdateOneWithoutCommentsInput = {
  connect?: Maybe<ObjectiveWhereUniqueInput>;
  connectOrCreate?: Maybe<ObjectiveCreateOrConnectWithoutCommentsInput>;
  create?: Maybe<ObjectiveCreateWithoutCommentsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ObjectiveUpdateWithoutCommentsInput>;
  upsert?: Maybe<ObjectiveUpsertWithoutCommentsInput>;
};

export type ObjectiveUpdateOneWithoutReviewInput = {
  connect?: Maybe<ObjectiveWhereUniqueInput>;
  connectOrCreate?: Maybe<ObjectiveCreateOrConnectWithoutReviewInput>;
  create?: Maybe<ObjectiveCreateWithoutReviewInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ObjectiveUpdateWithoutReviewInput>;
  upsert?: Maybe<ObjectiveUpsertWithoutReviewInput>;
};

export type ObjectiveUpdateWithoutAlignedToInput = {
  alignedWith?: Maybe<ObjectiveUpdateManyWithoutAlignedToInput>;
  comments?: Maybe<CommentUpdateManyWithoutObjectiveInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<StringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  keyResults?: Maybe<KeyResultUpdateManyWithoutObjectiveInput>;
  okr?: Maybe<OkrUpdateOneRequiredWithoutObjectivesInput>;
  order?: Maybe<FloatFieldUpdateOperationsInput>;
  private?: Maybe<BoolFieldUpdateOperationsInput>;
  review?: Maybe<ReviewUpdateOneWithoutObjectiveInput>;
  status?: Maybe<EnumStatusFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  visibleToUsers?: Maybe<UserUpdateManyWithoutPrivateObjectivesThatCanSeeInput>;
};

export type ObjectiveUpdateWithoutAlignedWithInput = {
  alignedTo?: Maybe<ObjectiveUpdateManyWithoutAlignedWithInput>;
  comments?: Maybe<CommentUpdateManyWithoutObjectiveInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<StringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  keyResults?: Maybe<KeyResultUpdateManyWithoutObjectiveInput>;
  okr?: Maybe<OkrUpdateOneRequiredWithoutObjectivesInput>;
  order?: Maybe<FloatFieldUpdateOperationsInput>;
  private?: Maybe<BoolFieldUpdateOperationsInput>;
  review?: Maybe<ReviewUpdateOneWithoutObjectiveInput>;
  status?: Maybe<EnumStatusFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  visibleToUsers?: Maybe<UserUpdateManyWithoutPrivateObjectivesThatCanSeeInput>;
};

export type ObjectiveUpdateWithoutCommentsInput = {
  alignedTo?: Maybe<ObjectiveUpdateManyWithoutAlignedWithInput>;
  alignedWith?: Maybe<ObjectiveUpdateManyWithoutAlignedToInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<StringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  keyResults?: Maybe<KeyResultUpdateManyWithoutObjectiveInput>;
  okr?: Maybe<OkrUpdateOneRequiredWithoutObjectivesInput>;
  order?: Maybe<FloatFieldUpdateOperationsInput>;
  private?: Maybe<BoolFieldUpdateOperationsInput>;
  review?: Maybe<ReviewUpdateOneWithoutObjectiveInput>;
  status?: Maybe<EnumStatusFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  visibleToUsers?: Maybe<UserUpdateManyWithoutPrivateObjectivesThatCanSeeInput>;
};

export type ObjectiveUpdateWithoutKeyResultsInput = {
  alignedTo?: Maybe<ObjectiveUpdateManyWithoutAlignedWithInput>;
  alignedWith?: Maybe<ObjectiveUpdateManyWithoutAlignedToInput>;
  comments?: Maybe<CommentUpdateManyWithoutObjectiveInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<StringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  okr?: Maybe<OkrUpdateOneRequiredWithoutObjectivesInput>;
  order?: Maybe<FloatFieldUpdateOperationsInput>;
  private?: Maybe<BoolFieldUpdateOperationsInput>;
  review?: Maybe<ReviewUpdateOneWithoutObjectiveInput>;
  status?: Maybe<EnumStatusFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  visibleToUsers?: Maybe<UserUpdateManyWithoutPrivateObjectivesThatCanSeeInput>;
};

export type ObjectiveUpdateWithoutOkrInput = {
  alignedTo?: Maybe<ObjectiveUpdateManyWithoutAlignedWithInput>;
  alignedWith?: Maybe<ObjectiveUpdateManyWithoutAlignedToInput>;
  comments?: Maybe<CommentUpdateManyWithoutObjectiveInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<StringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  keyResults?: Maybe<KeyResultUpdateManyWithoutObjectiveInput>;
  order?: Maybe<FloatFieldUpdateOperationsInput>;
  private?: Maybe<BoolFieldUpdateOperationsInput>;
  review?: Maybe<ReviewUpdateOneWithoutObjectiveInput>;
  status?: Maybe<EnumStatusFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  visibleToUsers?: Maybe<UserUpdateManyWithoutPrivateObjectivesThatCanSeeInput>;
};

export type ObjectiveUpdateWithoutReviewInput = {
  alignedTo?: Maybe<ObjectiveUpdateManyWithoutAlignedWithInput>;
  alignedWith?: Maybe<ObjectiveUpdateManyWithoutAlignedToInput>;
  comments?: Maybe<CommentUpdateManyWithoutObjectiveInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<StringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  keyResults?: Maybe<KeyResultUpdateManyWithoutObjectiveInput>;
  okr?: Maybe<OkrUpdateOneRequiredWithoutObjectivesInput>;
  order?: Maybe<FloatFieldUpdateOperationsInput>;
  private?: Maybe<BoolFieldUpdateOperationsInput>;
  status?: Maybe<EnumStatusFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  visibleToUsers?: Maybe<UserUpdateManyWithoutPrivateObjectivesThatCanSeeInput>;
};

export type ObjectiveUpdateWithoutVisibleToUsersInput = {
  alignedTo?: Maybe<ObjectiveUpdateManyWithoutAlignedWithInput>;
  alignedWith?: Maybe<ObjectiveUpdateManyWithoutAlignedToInput>;
  comments?: Maybe<CommentUpdateManyWithoutObjectiveInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<StringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  keyResults?: Maybe<KeyResultUpdateManyWithoutObjectiveInput>;
  okr?: Maybe<OkrUpdateOneRequiredWithoutObjectivesInput>;
  order?: Maybe<FloatFieldUpdateOperationsInput>;
  private?: Maybe<BoolFieldUpdateOperationsInput>;
  review?: Maybe<ReviewUpdateOneWithoutObjectiveInput>;
  status?: Maybe<EnumStatusFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type ObjectiveUpdateWithWhereUniqueWithoutAlignedToInput = {
  data: ObjectiveUpdateWithoutAlignedToInput;
  where: ObjectiveWhereUniqueInput;
};

export type ObjectiveUpdateWithWhereUniqueWithoutAlignedWithInput = {
  data: ObjectiveUpdateWithoutAlignedWithInput;
  where: ObjectiveWhereUniqueInput;
};

export type ObjectiveUpdateWithWhereUniqueWithoutOkrInput = {
  data: ObjectiveUpdateWithoutOkrInput;
  where: ObjectiveWhereUniqueInput;
};

export type ObjectiveUpdateWithWhereUniqueWithoutVisibleToUsersInput = {
  data: ObjectiveUpdateWithoutVisibleToUsersInput;
  where: ObjectiveWhereUniqueInput;
};

export type ObjectiveUpsertWithoutCommentsInput = {
  create: ObjectiveCreateWithoutCommentsInput;
  update: ObjectiveUpdateWithoutCommentsInput;
};

export type ObjectiveUpsertWithoutKeyResultsInput = {
  create: ObjectiveCreateWithoutKeyResultsInput;
  update: ObjectiveUpdateWithoutKeyResultsInput;
};

export type ObjectiveUpsertWithoutReviewInput = {
  create: ObjectiveCreateWithoutReviewInput;
  update: ObjectiveUpdateWithoutReviewInput;
};

export type ObjectiveUpsertWithWhereUniqueWithoutAlignedToInput = {
  create: ObjectiveCreateWithoutAlignedToInput;
  update: ObjectiveUpdateWithoutAlignedToInput;
  where: ObjectiveWhereUniqueInput;
};

export type ObjectiveUpsertWithWhereUniqueWithoutAlignedWithInput = {
  create: ObjectiveCreateWithoutAlignedWithInput;
  update: ObjectiveUpdateWithoutAlignedWithInput;
  where: ObjectiveWhereUniqueInput;
};

export type ObjectiveUpsertWithWhereUniqueWithoutOkrInput = {
  create: ObjectiveCreateWithoutOkrInput;
  update: ObjectiveUpdateWithoutOkrInput;
  where: ObjectiveWhereUniqueInput;
};

export type ObjectiveUpsertWithWhereUniqueWithoutVisibleToUsersInput = {
  create: ObjectiveCreateWithoutVisibleToUsersInput;
  update: ObjectiveUpdateWithoutVisibleToUsersInput;
  where: ObjectiveWhereUniqueInput;
};

export type ObjectiveWhereInput = {
  alignedTo?: Maybe<ObjectiveListRelationFilter>;
  alignedWith?: Maybe<ObjectiveListRelationFilter>;
  AND?: Maybe<Array<ObjectiveWhereInput>>;
  comments?: Maybe<CommentListRelationFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  description?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  keyResults?: Maybe<KeyResultListRelationFilter>;
  NOT?: Maybe<Array<ObjectiveWhereInput>>;
  okr?: Maybe<OkrWhereInput>;
  okrId?: Maybe<StringFilter>;
  OR?: Maybe<Array<ObjectiveWhereInput>>;
  order?: Maybe<FloatFilter>;
  private?: Maybe<BoolFilter>;
  review?: Maybe<ReviewWhereInput>;
  status?: Maybe<EnumStatusFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  visibleToUsers?: Maybe<UserListRelationFilter>;
};

export type ObjectiveWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type Okr = {
  __typename?: 'Okr';
  author: User;
  comments: Array<Comment>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  name: Scalars['String'];
  objectives: Array<Objective>;
  period: Period;
  updatedAt: Scalars['DateTime'];
};


export type OkrCommentsArgs = {
  cursor?: Maybe<CommentWhereUniqueInput>;
  orderBy?: Maybe<Array<CommentOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CommentWhereInput>;
};


export type OkrObjectivesArgs = {
  cursor?: Maybe<ObjectiveWhereUniqueInput>;
  orderBy?: Maybe<Array<ObjectiveOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ObjectiveWhereInput>;
};

export type OkrCreateInput = {
  name: Scalars['String'];
  period: ConnectPeriodInput;
};

export type OkrCreateManyAuthorInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  periodId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OkrCreateManyAuthorInputEnvelope = {
  data?: Maybe<Array<OkrCreateManyAuthorInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type OkrCreateManyPeriodInput = {
  authorId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OkrCreateManyPeriodInputEnvelope = {
  data?: Maybe<Array<OkrCreateManyPeriodInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type OkrCreateNestedManyWithoutAuthorInput = {
  connect?: Maybe<Array<OkrWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OkrCreateOrConnectWithoutAuthorInput>>;
  create?: Maybe<Array<OkrCreateWithoutAuthorInput>>;
  createMany?: Maybe<OkrCreateManyAuthorInputEnvelope>;
};

export type OkrCreateNestedManyWithoutPeriodInput = {
  connect?: Maybe<Array<OkrWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OkrCreateOrConnectWithoutPeriodInput>>;
  create?: Maybe<Array<OkrCreateWithoutPeriodInput>>;
  createMany?: Maybe<OkrCreateManyPeriodInputEnvelope>;
};

export type OkrCreateNestedOneWithoutCommentsInput = {
  connect?: Maybe<OkrWhereUniqueInput>;
  connectOrCreate?: Maybe<OkrCreateOrConnectWithoutCommentsInput>;
  create?: Maybe<OkrCreateWithoutCommentsInput>;
};

export type OkrCreateNestedOneWithoutObjectivesInput = {
  connect?: Maybe<OkrWhereUniqueInput>;
  connectOrCreate?: Maybe<OkrCreateOrConnectWithoutObjectivesInput>;
  create?: Maybe<OkrCreateWithoutObjectivesInput>;
};

export type OkrCreateOrConnectWithoutAuthorInput = {
  create: OkrCreateWithoutAuthorInput;
  where: OkrWhereUniqueInput;
};

export type OkrCreateOrConnectWithoutCommentsInput = {
  create: OkrCreateWithoutCommentsInput;
  where: OkrWhereUniqueInput;
};

export type OkrCreateOrConnectWithoutObjectivesInput = {
  create: OkrCreateWithoutObjectivesInput;
  where: OkrWhereUniqueInput;
};

export type OkrCreateOrConnectWithoutPeriodInput = {
  create: OkrCreateWithoutPeriodInput;
  where: OkrWhereUniqueInput;
};

export type OkrCreateWithoutAuthorInput = {
  comments?: Maybe<CommentCreateNestedManyWithoutOkrInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  objectives?: Maybe<ObjectiveCreateNestedManyWithoutOkrInput>;
  period: PeriodCreateNestedOneWithoutOkrsInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OkrCreateWithoutCommentsInput = {
  author: UserCreateNestedOneWithoutOkrsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  objectives?: Maybe<ObjectiveCreateNestedManyWithoutOkrInput>;
  period: PeriodCreateNestedOneWithoutOkrsInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OkrCreateWithoutObjectivesInput = {
  author: UserCreateNestedOneWithoutOkrsInput;
  comments?: Maybe<CommentCreateNestedManyWithoutOkrInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  period: PeriodCreateNestedOneWithoutOkrsInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OkrCreateWithoutPeriodInput = {
  author: UserCreateNestedOneWithoutOkrsInput;
  comments?: Maybe<CommentCreateNestedManyWithoutOkrInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  objectives?: Maybe<ObjectiveCreateNestedManyWithoutOkrInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OkrListRelationFilter = {
  every?: Maybe<OkrWhereInput>;
  none?: Maybe<OkrWhereInput>;
  some?: Maybe<OkrWhereInput>;
};

export type OkrOrderByInput = {
  authorId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  periodId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type OkrScalarWhereInput = {
  AND?: Maybe<Array<OkrScalarWhereInput>>;
  authorId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  NOT?: Maybe<Array<OkrScalarWhereInput>>;
  OR?: Maybe<Array<OkrScalarWhereInput>>;
  periodId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type OkrUpdateInput = {
  name?: Maybe<Scalars['String']>;
  privacy?: Maybe<OkrUpdatePrivacyInput>;
};

export type OkrUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type OkrUpdateManyWithoutAuthorInput = {
  connect?: Maybe<Array<OkrWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OkrCreateOrConnectWithoutAuthorInput>>;
  create?: Maybe<Array<OkrCreateWithoutAuthorInput>>;
  createMany?: Maybe<OkrCreateManyAuthorInputEnvelope>;
  delete?: Maybe<Array<OkrWhereUniqueInput>>;
  deleteMany?: Maybe<Array<OkrScalarWhereInput>>;
  disconnect?: Maybe<Array<OkrWhereUniqueInput>>;
  set?: Maybe<Array<OkrWhereUniqueInput>>;
  update?: Maybe<Array<OkrUpdateWithWhereUniqueWithoutAuthorInput>>;
  updateMany?: Maybe<Array<OkrUpdateManyWithWhereWithoutAuthorInput>>;
  upsert?: Maybe<Array<OkrUpsertWithWhereUniqueWithoutAuthorInput>>;
};

export type OkrUpdateManyWithoutPeriodInput = {
  connect?: Maybe<Array<OkrWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OkrCreateOrConnectWithoutPeriodInput>>;
  create?: Maybe<Array<OkrCreateWithoutPeriodInput>>;
  createMany?: Maybe<OkrCreateManyPeriodInputEnvelope>;
  delete?: Maybe<Array<OkrWhereUniqueInput>>;
  deleteMany?: Maybe<Array<OkrScalarWhereInput>>;
  disconnect?: Maybe<Array<OkrWhereUniqueInput>>;
  set?: Maybe<Array<OkrWhereUniqueInput>>;
  update?: Maybe<Array<OkrUpdateWithWhereUniqueWithoutPeriodInput>>;
  updateMany?: Maybe<Array<OkrUpdateManyWithWhereWithoutPeriodInput>>;
  upsert?: Maybe<Array<OkrUpsertWithWhereUniqueWithoutPeriodInput>>;
};

export type OkrUpdateManyWithWhereWithoutAuthorInput = {
  data: OkrUpdateManyMutationInput;
  where: OkrScalarWhereInput;
};

export type OkrUpdateManyWithWhereWithoutPeriodInput = {
  data: OkrUpdateManyMutationInput;
  where: OkrScalarWhereInput;
};

export type OkrUpdateOneRequiredWithoutObjectivesInput = {
  connect?: Maybe<OkrWhereUniqueInput>;
  connectOrCreate?: Maybe<OkrCreateOrConnectWithoutObjectivesInput>;
  create?: Maybe<OkrCreateWithoutObjectivesInput>;
  update?: Maybe<OkrUpdateWithoutObjectivesInput>;
  upsert?: Maybe<OkrUpsertWithoutObjectivesInput>;
};

export type OkrUpdateOneWithoutCommentsInput = {
  connect?: Maybe<OkrWhereUniqueInput>;
  connectOrCreate?: Maybe<OkrCreateOrConnectWithoutCommentsInput>;
  create?: Maybe<OkrCreateWithoutCommentsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<OkrUpdateWithoutCommentsInput>;
  upsert?: Maybe<OkrUpsertWithoutCommentsInput>;
};

export type OkrUpdatePrivacyInput = {
  keyResults?: Maybe<Array<OkrUpdatePrivacyKeyResultInput>>;
  objectives?: Maybe<Array<OkrUpdatePrivacyObjectiveInput>>;
};

export type OkrUpdatePrivacyKeyResultInput = {
  id: Scalars['String'];
  isPrivate: Scalars['Boolean'];
  visibleToUserIds: Array<Scalars['String']>;
};

export type OkrUpdatePrivacyObjectiveInput = {
  id: Scalars['String'];
  isPrivate: Scalars['Boolean'];
  visibleToUserIds: Array<Scalars['String']>;
};

export type OkrUpdateWithoutAuthorInput = {
  comments?: Maybe<CommentUpdateManyWithoutOkrInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  objectives?: Maybe<ObjectiveUpdateManyWithoutOkrInput>;
  period?: Maybe<PeriodUpdateOneRequiredWithoutOkrsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type OkrUpdateWithoutCommentsInput = {
  author?: Maybe<UserUpdateOneRequiredWithoutOkrsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  objectives?: Maybe<ObjectiveUpdateManyWithoutOkrInput>;
  period?: Maybe<PeriodUpdateOneRequiredWithoutOkrsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type OkrUpdateWithoutObjectivesInput = {
  author?: Maybe<UserUpdateOneRequiredWithoutOkrsInput>;
  comments?: Maybe<CommentUpdateManyWithoutOkrInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  period?: Maybe<PeriodUpdateOneRequiredWithoutOkrsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type OkrUpdateWithoutPeriodInput = {
  author?: Maybe<UserUpdateOneRequiredWithoutOkrsInput>;
  comments?: Maybe<CommentUpdateManyWithoutOkrInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  objectives?: Maybe<ObjectiveUpdateManyWithoutOkrInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type OkrUpdateWithWhereUniqueWithoutAuthorInput = {
  data: OkrUpdateWithoutAuthorInput;
  where: OkrWhereUniqueInput;
};

export type OkrUpdateWithWhereUniqueWithoutPeriodInput = {
  data: OkrUpdateWithoutPeriodInput;
  where: OkrWhereUniqueInput;
};

export type OkrUpsertWithoutCommentsInput = {
  create: OkrCreateWithoutCommentsInput;
  update: OkrUpdateWithoutCommentsInput;
};

export type OkrUpsertWithoutObjectivesInput = {
  create: OkrCreateWithoutObjectivesInput;
  update: OkrUpdateWithoutObjectivesInput;
};

export type OkrUpsertWithWhereUniqueWithoutAuthorInput = {
  create: OkrCreateWithoutAuthorInput;
  update: OkrUpdateWithoutAuthorInput;
  where: OkrWhereUniqueInput;
};

export type OkrUpsertWithWhereUniqueWithoutPeriodInput = {
  create: OkrCreateWithoutPeriodInput;
  update: OkrUpdateWithoutPeriodInput;
  where: OkrWhereUniqueInput;
};

export type OkrWhereInput = {
  AND?: Maybe<Array<OkrWhereInput>>;
  author?: Maybe<UserWhereInput>;
  authorId?: Maybe<StringFilter>;
  comments?: Maybe<CommentListRelationFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  NOT?: Maybe<Array<OkrWhereInput>>;
  objectives?: Maybe<ObjectiveListRelationFilter>;
  OR?: Maybe<Array<OkrWhereInput>>;
  period?: Maybe<PeriodWhereInput>;
  periodId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type OkrWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type OrdersInput = {
  id: Scalars['String'];
};

export type Period = {
  __typename?: 'Period';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  name: Scalars['String'];
  okrs: Array<Okr>;
  quarter?: Maybe<QuarterType>;
  year?: Maybe<Scalars['Int']>;
};


export type PeriodOkrsArgs = {
  cursor?: Maybe<OkrWhereUniqueInput>;
  orderBy?: Maybe<Array<OkrOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<OkrWhereInput>;
};

export type PeriodCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  glanceOverHistories?: Maybe<GlanceOverHistoryCreateNestedManyWithoutOkrPeriodInput>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  okrs?: Maybe<OkrCreateNestedManyWithoutPeriodInput>;
  quarter?: Maybe<QuarterType>;
  year?: Maybe<Scalars['Int']>;
};

export type PeriodCreateNestedOneWithoutGlanceOverHistoriesInput = {
  connect?: Maybe<PeriodWhereUniqueInput>;
  connectOrCreate?: Maybe<PeriodCreateOrConnectWithoutGlanceOverHistoriesInput>;
  create?: Maybe<PeriodCreateWithoutGlanceOverHistoriesInput>;
};

export type PeriodCreateNestedOneWithoutOkrsInput = {
  connect?: Maybe<PeriodWhereUniqueInput>;
  connectOrCreate?: Maybe<PeriodCreateOrConnectWithoutOkrsInput>;
  create?: Maybe<PeriodCreateWithoutOkrsInput>;
};

export type PeriodCreateOrConnectWithoutGlanceOverHistoriesInput = {
  create: PeriodCreateWithoutGlanceOverHistoriesInput;
  where: PeriodWhereUniqueInput;
};

export type PeriodCreateOrConnectWithoutOkrsInput = {
  create: PeriodCreateWithoutOkrsInput;
  where: PeriodWhereUniqueInput;
};

export type PeriodCreateWithoutGlanceOverHistoriesInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  okrs?: Maybe<OkrCreateNestedManyWithoutPeriodInput>;
  quarter?: Maybe<QuarterType>;
  year?: Maybe<Scalars['Int']>;
};

export type PeriodCreateWithoutOkrsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  glanceOverHistories?: Maybe<GlanceOverHistoryCreateNestedManyWithoutOkrPeriodInput>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  quarter?: Maybe<QuarterType>;
  year?: Maybe<Scalars['Int']>;
};

export type PeriodOrderByInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  quarter?: Maybe<SortOrder>;
  year?: Maybe<SortOrder>;
};

export type PeriodUpdateOneRequiredWithoutGlanceOverHistoriesInput = {
  connect?: Maybe<PeriodWhereUniqueInput>;
  connectOrCreate?: Maybe<PeriodCreateOrConnectWithoutGlanceOverHistoriesInput>;
  create?: Maybe<PeriodCreateWithoutGlanceOverHistoriesInput>;
  update?: Maybe<PeriodUpdateWithoutGlanceOverHistoriesInput>;
  upsert?: Maybe<PeriodUpsertWithoutGlanceOverHistoriesInput>;
};

export type PeriodUpdateOneRequiredWithoutOkrsInput = {
  connect?: Maybe<PeriodWhereUniqueInput>;
  connectOrCreate?: Maybe<PeriodCreateOrConnectWithoutOkrsInput>;
  create?: Maybe<PeriodCreateWithoutOkrsInput>;
  update?: Maybe<PeriodUpdateWithoutOkrsInput>;
  upsert?: Maybe<PeriodUpsertWithoutOkrsInput>;
};

export type PeriodUpdateWithoutGlanceOverHistoriesInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  okrs?: Maybe<OkrUpdateManyWithoutPeriodInput>;
  quarter?: Maybe<NullableEnumQuarterTypeFieldUpdateOperationsInput>;
  year?: Maybe<NullableIntFieldUpdateOperationsInput>;
};

export type PeriodUpdateWithoutOkrsInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  glanceOverHistories?: Maybe<GlanceOverHistoryUpdateManyWithoutOkrPeriodInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  quarter?: Maybe<NullableEnumQuarterTypeFieldUpdateOperationsInput>;
  year?: Maybe<NullableIntFieldUpdateOperationsInput>;
};

export type PeriodUpsertWithoutGlanceOverHistoriesInput = {
  create: PeriodCreateWithoutGlanceOverHistoriesInput;
  update: PeriodUpdateWithoutGlanceOverHistoriesInput;
};

export type PeriodUpsertWithoutOkrsInput = {
  create: PeriodCreateWithoutOkrsInput;
  update: PeriodUpdateWithoutOkrsInput;
};

export type PeriodWhereInput = {
  AND?: Maybe<Array<PeriodWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  glanceOverHistories?: Maybe<GlanceOverHistoryListRelationFilter>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  NOT?: Maybe<Array<PeriodWhereInput>>;
  okrs?: Maybe<OkrListRelationFilter>;
  OR?: Maybe<Array<PeriodWhereInput>>;
  quarter?: Maybe<EnumQuarterTypeNullableFilter>;
  year?: Maybe<IntNullableFilter>;
};

export type PeriodWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type PingResult = {
  __typename?: 'PingResult';
  currenPageUsers: Array<CurrentPageUser>;
};

export enum QuarterType {
  FullYear = 'FULL_YEAR',
  Q1 = 'Q1',
  Q2 = 'Q2',
  Q3 = 'Q3',
  Q4 = 'Q4'
}

export type Query = {
  __typename?: 'Query';
  comment?: Maybe<Comment>;
  comments: Array<Comment>;
  glanceOverHistories: Array<GlanceOverHistory>;
  glanceOverHistory?: Maybe<GlanceOverHistory>;
  keyResult?: Maybe<KeyResult>;
  keyResults: Array<KeyResult>;
  my?: Maybe<User>;
  objective?: Maybe<Objective>;
  objectives: Array<Objective>;
  okr?: Maybe<Okr>;
  okrs: Array<Okr>;
  period?: Maybe<Period>;
  periods: Array<Period>;
  ping?: Maybe<PingResult>;
  review?: Maybe<Review>;
  reviews: Array<Review>;
  user?: Maybe<User>;
  users: Array<User>;
};


export type QueryCommentArgs = {
  where: CommentWhereUniqueInput;
};


export type QueryCommentsArgs = {
  cursor?: Maybe<CommentWhereUniqueInput>;
  orderBy?: Maybe<Array<CommentOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CommentWhereInput>;
};


export type QueryGlanceOverHistoriesArgs = {
  cursor?: Maybe<GlanceOverHistoryWhereUniqueInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<GlanceOverHistoryWhereInput>;
};


export type QueryGlanceOverHistoryArgs = {
  where: GlanceOverHistoryWhereUniqueInput;
};


export type QueryKeyResultArgs = {
  where: KeyResultWhereUniqueInput;
};


export type QueryKeyResultsArgs = {
  cursor?: Maybe<KeyResultWhereUniqueInput>;
  orderBy?: Maybe<Array<KeyResultOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<KeyResultWhereInput>;
};


export type QueryObjectiveArgs = {
  where: ObjectiveWhereUniqueInput;
};


export type QueryObjectivesArgs = {
  cursor?: Maybe<ObjectiveWhereUniqueInput>;
  orderBy?: Maybe<Array<ObjectiveOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ObjectiveWhereInput>;
};


export type QueryOkrArgs = {
  where: OkrWhereUniqueInput;
};


export type QueryOkrsArgs = {
  cursor?: Maybe<OkrWhereUniqueInput>;
  orderBy?: Maybe<Array<OkrOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<OkrWhereInput>;
};


export type QueryPeriodArgs = {
  where: PeriodWhereUniqueInput;
};


export type QueryPeriodsArgs = {
  cursor?: Maybe<PeriodWhereUniqueInput>;
  orderBy?: Maybe<Array<PeriodOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PeriodWhereInput>;
};


export type QueryPingArgs = {
  currentPage: Scalars['String'];
};


export type QueryReviewArgs = {
  where: ReviewWhereUniqueInput;
};


export type QueryReviewsArgs = {
  cursor?: Maybe<ReviewWhereUniqueInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ReviewWhereInput>;
};


export type QueryUserArgs = {
  where: UserWhereUniqueInput;
};


export type QueryUsersArgs = {
  cursor?: Maybe<UserWhereUniqueInput>;
  orderBy?: Maybe<Array<UserOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<UserWhereInput>;
};

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive'
}

export type Review = {
  __typename?: 'Review';
  comments: Array<Comment>;
  description: Scalars['String'];
  id: Scalars['String'];
  keyResult?: Maybe<KeyResult>;
  objective?: Maybe<Objective>;
};


export type ReviewCommentsArgs = {
  cursor?: Maybe<CommentWhereUniqueInput>;
  orderBy?: Maybe<Array<CommentOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CommentWhereInput>;
};

export type ReviewCreateInput = {
  comments?: Maybe<CommentCreateNestedManyWithoutReviewInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  keyResult?: Maybe<KeyResultCreateNestedOneWithoutReviewInput>;
  objective?: Maybe<ObjectiveCreateNestedOneWithoutReviewInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ReviewCreateNestedOneWithoutCommentsInput = {
  connect?: Maybe<ReviewWhereUniqueInput>;
  connectOrCreate?: Maybe<ReviewCreateOrConnectWithoutCommentsInput>;
  create?: Maybe<ReviewCreateWithoutCommentsInput>;
};

export type ReviewCreateNestedOneWithoutKeyResultInput = {
  connect?: Maybe<ReviewWhereUniqueInput>;
  connectOrCreate?: Maybe<ReviewCreateOrConnectWithoutKeyResultInput>;
  create?: Maybe<ReviewCreateWithoutKeyResultInput>;
};

export type ReviewCreateNestedOneWithoutObjectiveInput = {
  connect?: Maybe<ReviewWhereUniqueInput>;
  connectOrCreate?: Maybe<ReviewCreateOrConnectWithoutObjectiveInput>;
  create?: Maybe<ReviewCreateWithoutObjectiveInput>;
};

export type ReviewCreateOrConnectWithoutCommentsInput = {
  create: ReviewCreateWithoutCommentsInput;
  where: ReviewWhereUniqueInput;
};

export type ReviewCreateOrConnectWithoutKeyResultInput = {
  create: ReviewCreateWithoutKeyResultInput;
  where: ReviewWhereUniqueInput;
};

export type ReviewCreateOrConnectWithoutObjectiveInput = {
  create: ReviewCreateWithoutObjectiveInput;
  where: ReviewWhereUniqueInput;
};

export type ReviewCreateWithoutCommentsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  keyResult?: Maybe<KeyResultCreateNestedOneWithoutReviewInput>;
  objective?: Maybe<ObjectiveCreateNestedOneWithoutReviewInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ReviewCreateWithoutKeyResultInput = {
  comments?: Maybe<CommentCreateNestedManyWithoutReviewInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  objective?: Maybe<ObjectiveCreateNestedOneWithoutReviewInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ReviewCreateWithoutObjectiveInput = {
  comments?: Maybe<CommentCreateNestedManyWithoutReviewInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  keyResult?: Maybe<KeyResultCreateNestedOneWithoutReviewInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ReviewUpdateOneWithoutCommentsInput = {
  connect?: Maybe<ReviewWhereUniqueInput>;
  connectOrCreate?: Maybe<ReviewCreateOrConnectWithoutCommentsInput>;
  create?: Maybe<ReviewCreateWithoutCommentsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ReviewUpdateWithoutCommentsInput>;
  upsert?: Maybe<ReviewUpsertWithoutCommentsInput>;
};

export type ReviewUpdateOneWithoutKeyResultInput = {
  connect?: Maybe<ReviewWhereUniqueInput>;
  connectOrCreate?: Maybe<ReviewCreateOrConnectWithoutKeyResultInput>;
  create?: Maybe<ReviewCreateWithoutKeyResultInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ReviewUpdateWithoutKeyResultInput>;
  upsert?: Maybe<ReviewUpsertWithoutKeyResultInput>;
};

export type ReviewUpdateOneWithoutObjectiveInput = {
  connect?: Maybe<ReviewWhereUniqueInput>;
  connectOrCreate?: Maybe<ReviewCreateOrConnectWithoutObjectiveInput>;
  create?: Maybe<ReviewCreateWithoutObjectiveInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ReviewUpdateWithoutObjectiveInput>;
  upsert?: Maybe<ReviewUpsertWithoutObjectiveInput>;
};

export type ReviewUpdateWithoutCommentsInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<StringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  keyResult?: Maybe<KeyResultUpdateOneWithoutReviewInput>;
  objective?: Maybe<ObjectiveUpdateOneWithoutReviewInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type ReviewUpdateWithoutKeyResultInput = {
  comments?: Maybe<CommentUpdateManyWithoutReviewInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<StringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  objective?: Maybe<ObjectiveUpdateOneWithoutReviewInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type ReviewUpdateWithoutObjectiveInput = {
  comments?: Maybe<CommentUpdateManyWithoutReviewInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<StringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  keyResult?: Maybe<KeyResultUpdateOneWithoutReviewInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type ReviewUpsertWithoutCommentsInput = {
  create: ReviewCreateWithoutCommentsInput;
  update: ReviewUpdateWithoutCommentsInput;
};

export type ReviewUpsertWithoutKeyResultInput = {
  create: ReviewCreateWithoutKeyResultInput;
  update: ReviewUpdateWithoutKeyResultInput;
};

export type ReviewUpsertWithoutObjectiveInput = {
  create: ReviewCreateWithoutObjectiveInput;
  update: ReviewUpdateWithoutObjectiveInput;
};

export type ReviewWhereInput = {
  AND?: Maybe<Array<ReviewWhereInput>>;
  comments?: Maybe<CommentListRelationFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  description?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  keyResult?: Maybe<KeyResultWhereInput>;
  keyResultId?: Maybe<StringNullableFilter>;
  NOT?: Maybe<Array<ReviewWhereInput>>;
  objective?: Maybe<ObjectiveWhereInput>;
  objectiveId?: Maybe<StringNullableFilter>;
  OR?: Maybe<Array<ReviewWhereInput>>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type ReviewWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  keyResultId?: Maybe<Scalars['String']>;
  objectiveId?: Maybe<Scalars['String']>;
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export enum Status {
  Auto = 'AUTO',
  Delayed = 'DELAYED',
  Normal = 'NORMAL',
  Risky = 'RISKY'
}

export type StringFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['String']>;
};

export type StringFilter = {
  contains?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  equals?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  mode?: Maybe<QueryMode>;
  not?: Maybe<NestedStringFilter>;
  notIn?: Maybe<Array<Scalars['String']>>;
  startsWith?: Maybe<Scalars['String']>;
};

export type StringNullableFilter = {
  contains?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  equals?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  mode?: Maybe<QueryMode>;
  not?: Maybe<NestedStringNullableFilter>;
  notIn?: Maybe<Array<Scalars['String']>>;
  startsWith?: Maybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  avatar?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  okrs: Array<Okr>;
  pinyinName?: Maybe<Scalars['String']>;
  privateKeyResultsThatCanSee: Array<KeyResult>;
  privateObjectivesThatCanSee: Array<Objective>;
  source: UserSource;
};


export type UserOkrsArgs = {
  cursor?: Maybe<OkrWhereUniqueInput>;
  orderBy?: Maybe<Array<OkrOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<OkrWhereInput>;
};


export type UserPrivateKeyResultsThatCanSeeArgs = {
  cursor?: Maybe<KeyResultWhereUniqueInput>;
  orderBy?: Maybe<Array<KeyResultOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<KeyResultWhereInput>;
};


export type UserPrivateObjectivesThatCanSeeArgs = {
  cursor?: Maybe<ObjectiveWhereUniqueInput>;
  orderBy?: Maybe<Array<ObjectiveOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ObjectiveWhereInput>;
};

export type UserCreateNestedManyWithoutPrivateKeyResultsThatCanSeeInput = {
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutPrivateKeyResultsThatCanSeeInput>>;
  create?: Maybe<Array<UserCreateWithoutPrivateKeyResultsThatCanSeeInput>>;
};

export type UserCreateNestedManyWithoutPrivateObjectivesThatCanSeeInput = {
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutPrivateObjectivesThatCanSeeInput>>;
  create?: Maybe<Array<UserCreateWithoutPrivateObjectivesThatCanSeeInput>>;
};

export type UserCreateNestedOneWithoutCommentInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutCommentInput>;
  create?: Maybe<UserCreateWithoutCommentInput>;
};

export type UserCreateNestedOneWithoutGlanceOverHistoriesInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutGlanceOverHistoriesInput>;
  create?: Maybe<UserCreateWithoutGlanceOverHistoriesInput>;
};

export type UserCreateNestedOneWithoutGlanceOverOkrAuthorsInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutGlanceOverOkrAuthorsInput>;
  create?: Maybe<UserCreateWithoutGlanceOverOkrAuthorsInput>;
};

export type UserCreateNestedOneWithoutOkrsInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutOkrsInput>;
  create?: Maybe<UserCreateWithoutOkrsInput>;
};

export type UserCreateOrConnectWithoutCommentInput = {
  create: UserCreateWithoutCommentInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutGlanceOverHistoriesInput = {
  create: UserCreateWithoutGlanceOverHistoriesInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutGlanceOverOkrAuthorsInput = {
  create: UserCreateWithoutGlanceOverOkrAuthorsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutOkrsInput = {
  create: UserCreateWithoutOkrsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutPrivateKeyResultsThatCanSeeInput = {
  create: UserCreateWithoutPrivateKeyResultsThatCanSeeInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutPrivateObjectivesThatCanSeeInput = {
  create: UserCreateWithoutPrivateObjectivesThatCanSeeInput;
  where: UserWhereUniqueInput;
};

export type UserCreateWithoutCommentInput = {
  avatar?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  glanceOverHistories?: Maybe<GlanceOverHistoryCreateNestedManyWithoutBelongToUserInput>;
  glanceOverOkrAuthors?: Maybe<GlanceOverHistoryCreateNestedManyWithoutOkrAuthorInput>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  okrs?: Maybe<OkrCreateNestedManyWithoutAuthorInput>;
  pinyinName?: Maybe<Scalars['String']>;
  privateKeyResultsThatCanSee?: Maybe<KeyResultCreateNestedManyWithoutVisibleToUsersInput>;
  privateObjectivesThatCanSee?: Maybe<ObjectiveCreateNestedManyWithoutVisibleToUsersInput>;
  source: UserSource;
  updatedAt?: Maybe<Scalars['DateTime']>;
  weComId?: Maybe<Scalars['String']>;
};

export type UserCreateWithoutGlanceOverHistoriesInput = {
  avatar?: Maybe<Scalars['String']>;
  Comment?: Maybe<CommentCreateNestedManyWithoutAuthorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  glanceOverOkrAuthors?: Maybe<GlanceOverHistoryCreateNestedManyWithoutOkrAuthorInput>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  okrs?: Maybe<OkrCreateNestedManyWithoutAuthorInput>;
  pinyinName?: Maybe<Scalars['String']>;
  privateKeyResultsThatCanSee?: Maybe<KeyResultCreateNestedManyWithoutVisibleToUsersInput>;
  privateObjectivesThatCanSee?: Maybe<ObjectiveCreateNestedManyWithoutVisibleToUsersInput>;
  source: UserSource;
  updatedAt?: Maybe<Scalars['DateTime']>;
  weComId?: Maybe<Scalars['String']>;
};

export type UserCreateWithoutGlanceOverOkrAuthorsInput = {
  avatar?: Maybe<Scalars['String']>;
  Comment?: Maybe<CommentCreateNestedManyWithoutAuthorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  glanceOverHistories?: Maybe<GlanceOverHistoryCreateNestedManyWithoutBelongToUserInput>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  okrs?: Maybe<OkrCreateNestedManyWithoutAuthorInput>;
  pinyinName?: Maybe<Scalars['String']>;
  privateKeyResultsThatCanSee?: Maybe<KeyResultCreateNestedManyWithoutVisibleToUsersInput>;
  privateObjectivesThatCanSee?: Maybe<ObjectiveCreateNestedManyWithoutVisibleToUsersInput>;
  source: UserSource;
  updatedAt?: Maybe<Scalars['DateTime']>;
  weComId?: Maybe<Scalars['String']>;
};

export type UserCreateWithoutOkrsInput = {
  avatar?: Maybe<Scalars['String']>;
  Comment?: Maybe<CommentCreateNestedManyWithoutAuthorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  glanceOverHistories?: Maybe<GlanceOverHistoryCreateNestedManyWithoutBelongToUserInput>;
  glanceOverOkrAuthors?: Maybe<GlanceOverHistoryCreateNestedManyWithoutOkrAuthorInput>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pinyinName?: Maybe<Scalars['String']>;
  privateKeyResultsThatCanSee?: Maybe<KeyResultCreateNestedManyWithoutVisibleToUsersInput>;
  privateObjectivesThatCanSee?: Maybe<ObjectiveCreateNestedManyWithoutVisibleToUsersInput>;
  source: UserSource;
  updatedAt?: Maybe<Scalars['DateTime']>;
  weComId?: Maybe<Scalars['String']>;
};

export type UserCreateWithoutPrivateKeyResultsThatCanSeeInput = {
  avatar?: Maybe<Scalars['String']>;
  Comment?: Maybe<CommentCreateNestedManyWithoutAuthorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  glanceOverHistories?: Maybe<GlanceOverHistoryCreateNestedManyWithoutBelongToUserInput>;
  glanceOverOkrAuthors?: Maybe<GlanceOverHistoryCreateNestedManyWithoutOkrAuthorInput>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  okrs?: Maybe<OkrCreateNestedManyWithoutAuthorInput>;
  pinyinName?: Maybe<Scalars['String']>;
  privateObjectivesThatCanSee?: Maybe<ObjectiveCreateNestedManyWithoutVisibleToUsersInput>;
  source: UserSource;
  updatedAt?: Maybe<Scalars['DateTime']>;
  weComId?: Maybe<Scalars['String']>;
};

export type UserCreateWithoutPrivateObjectivesThatCanSeeInput = {
  avatar?: Maybe<Scalars['String']>;
  Comment?: Maybe<CommentCreateNestedManyWithoutAuthorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  glanceOverHistories?: Maybe<GlanceOverHistoryCreateNestedManyWithoutBelongToUserInput>;
  glanceOverOkrAuthors?: Maybe<GlanceOverHistoryCreateNestedManyWithoutOkrAuthorInput>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  okrs?: Maybe<OkrCreateNestedManyWithoutAuthorInput>;
  pinyinName?: Maybe<Scalars['String']>;
  privateKeyResultsThatCanSee?: Maybe<KeyResultCreateNestedManyWithoutVisibleToUsersInput>;
  source: UserSource;
  updatedAt?: Maybe<Scalars['DateTime']>;
  weComId?: Maybe<Scalars['String']>;
};

export type UserListRelationFilter = {
  every?: Maybe<UserWhereInput>;
  none?: Maybe<UserWhereInput>;
  some?: Maybe<UserWhereInput>;
};

export type UserOrderByInput = {
  avatar?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  pinyinName?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  weComId?: Maybe<SortOrder>;
};

export type UserScalarWhereInput = {
  AND?: Maybe<Array<UserScalarWhereInput>>;
  avatar?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  email?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  isActive?: Maybe<BoolNullableFilter>;
  name?: Maybe<StringFilter>;
  NOT?: Maybe<Array<UserScalarWhereInput>>;
  OR?: Maybe<Array<UserScalarWhereInput>>;
  pinyinName?: Maybe<StringNullableFilter>;
  source?: Maybe<EnumUserSourceFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  weComId?: Maybe<StringNullableFilter>;
};

export enum UserSource {
  WeCom = 'WE_COM'
}

export type UserUpdateInput = {
  avatar?: Maybe<NullableStringFieldUpdateOperationsInput>;
  Comment?: Maybe<CommentUpdateManyWithoutAuthorInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  glanceOverHistories?: Maybe<GlanceOverHistoryUpdateManyWithoutBelongToUserInput>;
  glanceOverOkrAuthors?: Maybe<GlanceOverHistoryUpdateManyWithoutOkrAuthorInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isActive?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  okrs?: Maybe<OkrUpdateManyWithoutAuthorInput>;
  pinyinName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  privateKeyResultsThatCanSee?: Maybe<KeyResultUpdateManyWithoutVisibleToUsersInput>;
  privateObjectivesThatCanSee?: Maybe<ObjectiveUpdateManyWithoutVisibleToUsersInput>;
  source?: Maybe<EnumUserSourceFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  weComId?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateManyMutationInput = {
  avatar?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isActive?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  pinyinName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  source?: Maybe<EnumUserSourceFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  weComId?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateManyWithoutPrivateKeyResultsThatCanSeeInput = {
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutPrivateKeyResultsThatCanSeeInput>>;
  create?: Maybe<Array<UserCreateWithoutPrivateKeyResultsThatCanSeeInput>>;
  delete?: Maybe<Array<UserWhereUniqueInput>>;
  deleteMany?: Maybe<Array<UserScalarWhereInput>>;
  disconnect?: Maybe<Array<UserWhereUniqueInput>>;
  set?: Maybe<Array<UserWhereUniqueInput>>;
  update?: Maybe<Array<UserUpdateWithWhereUniqueWithoutPrivateKeyResultsThatCanSeeInput>>;
  updateMany?: Maybe<Array<UserUpdateManyWithWhereWithoutPrivateKeyResultsThatCanSeeInput>>;
  upsert?: Maybe<Array<UserUpsertWithWhereUniqueWithoutPrivateKeyResultsThatCanSeeInput>>;
};

export type UserUpdateManyWithoutPrivateObjectivesThatCanSeeInput = {
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutPrivateObjectivesThatCanSeeInput>>;
  create?: Maybe<Array<UserCreateWithoutPrivateObjectivesThatCanSeeInput>>;
  delete?: Maybe<Array<UserWhereUniqueInput>>;
  deleteMany?: Maybe<Array<UserScalarWhereInput>>;
  disconnect?: Maybe<Array<UserWhereUniqueInput>>;
  set?: Maybe<Array<UserWhereUniqueInput>>;
  update?: Maybe<Array<UserUpdateWithWhereUniqueWithoutPrivateObjectivesThatCanSeeInput>>;
  updateMany?: Maybe<Array<UserUpdateManyWithWhereWithoutPrivateObjectivesThatCanSeeInput>>;
  upsert?: Maybe<Array<UserUpsertWithWhereUniqueWithoutPrivateObjectivesThatCanSeeInput>>;
};

export type UserUpdateManyWithWhereWithoutPrivateKeyResultsThatCanSeeInput = {
  data: UserUpdateManyMutationInput;
  where: UserScalarWhereInput;
};

export type UserUpdateManyWithWhereWithoutPrivateObjectivesThatCanSeeInput = {
  data: UserUpdateManyMutationInput;
  where: UserScalarWhereInput;
};

export type UserUpdateOneRequiredWithoutCommentInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutCommentInput>;
  create?: Maybe<UserCreateWithoutCommentInput>;
  update?: Maybe<UserUpdateWithoutCommentInput>;
  upsert?: Maybe<UserUpsertWithoutCommentInput>;
};

export type UserUpdateOneRequiredWithoutGlanceOverHistoriesInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutGlanceOverHistoriesInput>;
  create?: Maybe<UserCreateWithoutGlanceOverHistoriesInput>;
  update?: Maybe<UserUpdateWithoutGlanceOverHistoriesInput>;
  upsert?: Maybe<UserUpsertWithoutGlanceOverHistoriesInput>;
};

export type UserUpdateOneRequiredWithoutGlanceOverOkrAuthorsInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutGlanceOverOkrAuthorsInput>;
  create?: Maybe<UserCreateWithoutGlanceOverOkrAuthorsInput>;
  update?: Maybe<UserUpdateWithoutGlanceOverOkrAuthorsInput>;
  upsert?: Maybe<UserUpsertWithoutGlanceOverOkrAuthorsInput>;
};

export type UserUpdateOneRequiredWithoutOkrsInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutOkrsInput>;
  create?: Maybe<UserCreateWithoutOkrsInput>;
  update?: Maybe<UserUpdateWithoutOkrsInput>;
  upsert?: Maybe<UserUpsertWithoutOkrsInput>;
};

export type UserUpdateWithoutCommentInput = {
  avatar?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  glanceOverHistories?: Maybe<GlanceOverHistoryUpdateManyWithoutBelongToUserInput>;
  glanceOverOkrAuthors?: Maybe<GlanceOverHistoryUpdateManyWithoutOkrAuthorInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isActive?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  okrs?: Maybe<OkrUpdateManyWithoutAuthorInput>;
  pinyinName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  privateKeyResultsThatCanSee?: Maybe<KeyResultUpdateManyWithoutVisibleToUsersInput>;
  privateObjectivesThatCanSee?: Maybe<ObjectiveUpdateManyWithoutVisibleToUsersInput>;
  source?: Maybe<EnumUserSourceFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  weComId?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutGlanceOverHistoriesInput = {
  avatar?: Maybe<NullableStringFieldUpdateOperationsInput>;
  Comment?: Maybe<CommentUpdateManyWithoutAuthorInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  glanceOverOkrAuthors?: Maybe<GlanceOverHistoryUpdateManyWithoutOkrAuthorInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isActive?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  okrs?: Maybe<OkrUpdateManyWithoutAuthorInput>;
  pinyinName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  privateKeyResultsThatCanSee?: Maybe<KeyResultUpdateManyWithoutVisibleToUsersInput>;
  privateObjectivesThatCanSee?: Maybe<ObjectiveUpdateManyWithoutVisibleToUsersInput>;
  source?: Maybe<EnumUserSourceFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  weComId?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutGlanceOverOkrAuthorsInput = {
  avatar?: Maybe<NullableStringFieldUpdateOperationsInput>;
  Comment?: Maybe<CommentUpdateManyWithoutAuthorInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  glanceOverHistories?: Maybe<GlanceOverHistoryUpdateManyWithoutBelongToUserInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isActive?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  okrs?: Maybe<OkrUpdateManyWithoutAuthorInput>;
  pinyinName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  privateKeyResultsThatCanSee?: Maybe<KeyResultUpdateManyWithoutVisibleToUsersInput>;
  privateObjectivesThatCanSee?: Maybe<ObjectiveUpdateManyWithoutVisibleToUsersInput>;
  source?: Maybe<EnumUserSourceFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  weComId?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutOkrsInput = {
  avatar?: Maybe<NullableStringFieldUpdateOperationsInput>;
  Comment?: Maybe<CommentUpdateManyWithoutAuthorInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  glanceOverHistories?: Maybe<GlanceOverHistoryUpdateManyWithoutBelongToUserInput>;
  glanceOverOkrAuthors?: Maybe<GlanceOverHistoryUpdateManyWithoutOkrAuthorInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isActive?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  pinyinName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  privateKeyResultsThatCanSee?: Maybe<KeyResultUpdateManyWithoutVisibleToUsersInput>;
  privateObjectivesThatCanSee?: Maybe<ObjectiveUpdateManyWithoutVisibleToUsersInput>;
  source?: Maybe<EnumUserSourceFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  weComId?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutPrivateKeyResultsThatCanSeeInput = {
  avatar?: Maybe<NullableStringFieldUpdateOperationsInput>;
  Comment?: Maybe<CommentUpdateManyWithoutAuthorInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  glanceOverHistories?: Maybe<GlanceOverHistoryUpdateManyWithoutBelongToUserInput>;
  glanceOverOkrAuthors?: Maybe<GlanceOverHistoryUpdateManyWithoutOkrAuthorInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isActive?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  okrs?: Maybe<OkrUpdateManyWithoutAuthorInput>;
  pinyinName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  privateObjectivesThatCanSee?: Maybe<ObjectiveUpdateManyWithoutVisibleToUsersInput>;
  source?: Maybe<EnumUserSourceFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  weComId?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutPrivateObjectivesThatCanSeeInput = {
  avatar?: Maybe<NullableStringFieldUpdateOperationsInput>;
  Comment?: Maybe<CommentUpdateManyWithoutAuthorInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  glanceOverHistories?: Maybe<GlanceOverHistoryUpdateManyWithoutBelongToUserInput>;
  glanceOverOkrAuthors?: Maybe<GlanceOverHistoryUpdateManyWithoutOkrAuthorInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isActive?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  okrs?: Maybe<OkrUpdateManyWithoutAuthorInput>;
  pinyinName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  privateKeyResultsThatCanSee?: Maybe<KeyResultUpdateManyWithoutVisibleToUsersInput>;
  source?: Maybe<EnumUserSourceFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  weComId?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithWhereUniqueWithoutPrivateKeyResultsThatCanSeeInput = {
  data: UserUpdateWithoutPrivateKeyResultsThatCanSeeInput;
  where: UserWhereUniqueInput;
};

export type UserUpdateWithWhereUniqueWithoutPrivateObjectivesThatCanSeeInput = {
  data: UserUpdateWithoutPrivateObjectivesThatCanSeeInput;
  where: UserWhereUniqueInput;
};

export type UserUpsertWithoutCommentInput = {
  create: UserCreateWithoutCommentInput;
  update: UserUpdateWithoutCommentInput;
};

export type UserUpsertWithoutGlanceOverHistoriesInput = {
  create: UserCreateWithoutGlanceOverHistoriesInput;
  update: UserUpdateWithoutGlanceOverHistoriesInput;
};

export type UserUpsertWithoutGlanceOverOkrAuthorsInput = {
  create: UserCreateWithoutGlanceOverOkrAuthorsInput;
  update: UserUpdateWithoutGlanceOverOkrAuthorsInput;
};

export type UserUpsertWithoutOkrsInput = {
  create: UserCreateWithoutOkrsInput;
  update: UserUpdateWithoutOkrsInput;
};

export type UserUpsertWithWhereUniqueWithoutPrivateKeyResultsThatCanSeeInput = {
  create: UserCreateWithoutPrivateKeyResultsThatCanSeeInput;
  update: UserUpdateWithoutPrivateKeyResultsThatCanSeeInput;
  where: UserWhereUniqueInput;
};

export type UserUpsertWithWhereUniqueWithoutPrivateObjectivesThatCanSeeInput = {
  create: UserCreateWithoutPrivateObjectivesThatCanSeeInput;
  update: UserUpdateWithoutPrivateObjectivesThatCanSeeInput;
  where: UserWhereUniqueInput;
};

export type UserWhereInput = {
  AND?: Maybe<Array<UserWhereInput>>;
  avatar?: Maybe<StringNullableFilter>;
  Comment?: Maybe<CommentListRelationFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  email?: Maybe<StringNullableFilter>;
  glanceOverHistories?: Maybe<GlanceOverHistoryListRelationFilter>;
  glanceOverOkrAuthors?: Maybe<GlanceOverHistoryListRelationFilter>;
  id?: Maybe<StringFilter>;
  isActive?: Maybe<BoolNullableFilter>;
  name?: Maybe<StringFilter>;
  NOT?: Maybe<Array<UserWhereInput>>;
  okrs?: Maybe<OkrListRelationFilter>;
  OR?: Maybe<Array<UserWhereInput>>;
  pinyinName?: Maybe<StringNullableFilter>;
  privateKeyResultsThatCanSee?: Maybe<KeyResultListRelationFilter>;
  privateObjectivesThatCanSee?: Maybe<ObjectiveListRelationFilter>;
  source?: Maybe<EnumUserSourceFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  weComId?: Maybe<StringNullableFilter>;
};

export type UserWhereUniqueInput = {
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  weComId?: Maybe<Scalars['String']>;
};

export type WeightsInput = {
  id: Scalars['String'];
  weight: Scalars['Float'];
};

export type CreateOneCommentMutationVariables = Exact<{
  data: CommentCreateInput;
  effect?: Maybe<CommentCreateEffect>;
}>;


export type CreateOneCommentMutation = (
  { __typename?: 'Mutation' }
  & { createOneComment?: Maybe<(
    { __typename?: 'Comment' }
    & Pick<Comment, 'id' | 'content' | 'createdAt' | 'solvedAt'>
    & { author: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name' | 'avatar'>
    ), okr?: Maybe<(
      { __typename?: 'Okr' }
      & Pick<Okr, 'id'>
    )>, objective?: Maybe<(
      { __typename?: 'Objective' }
      & Pick<Objective, 'id'>
    )>, keyResult?: Maybe<(
      { __typename?: 'KeyResult' }
      & Pick<KeyResult, 'id'>
    )> }
  )> }
);

export type DeleteOneCommentMutationVariables = Exact<{
  where: CommentWhereUniqueInput;
}>;


export type DeleteOneCommentMutation = (
  { __typename?: 'Mutation' }
  & { deleteOneComment?: Maybe<(
    { __typename?: 'Comment' }
    & Pick<Comment, 'id' | 'content'>
  )> }
);

export type SolveOneCommentMutationVariables = Exact<{
  where: CommentWhereUniqueInput;
}>;


export type SolveOneCommentMutation = (
  { __typename?: 'Mutation' }
  & { solveOneComment?: Maybe<(
    { __typename?: 'Comment' }
    & Pick<Comment, 'id' | 'solvedAt'>
  )> }
);

export type ReopenOneCommentMutationVariables = Exact<{
  where: CommentWhereUniqueInput;
}>;


export type ReopenOneCommentMutation = (
  { __typename?: 'Mutation' }
  & { reopenOneComment?: Maybe<(
    { __typename?: 'Comment' }
    & Pick<Comment, 'id' | 'solvedAt'>
  )> }
);

export type OkrBottomCommentsQueryVariables = Exact<{
  where?: Maybe<CommentWhereInput>;
}>;


export type OkrBottomCommentsQuery = (
  { __typename?: 'Query' }
  & { comments: Array<(
    { __typename?: 'Comment' }
    & Pick<Comment, 'id' | 'content' | 'createdAt' | 'solvedAt'>
    & { author: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'avatar' | 'name'>
    ) }
  )> }
);

export type SolvedCommentsQueryVariables = Exact<{
  okrId: Scalars['String'];
}>;


export type SolvedCommentsQuery = (
  { __typename?: 'Query' }
  & { comments: Array<(
    { __typename?: 'Comment' }
    & Pick<Comment, 'id' | 'content' | 'createdAt' | 'solvedAt'>
    & { author: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'avatar' | 'name'>
    ) }
  )> }
);

export type CommentsQueryVariables = Exact<{
  where?: Maybe<CommentWhereInput>;
}>;


export type CommentsQuery = (
  { __typename?: 'Query' }
  & { comments: Array<(
    { __typename?: 'Comment' }
    & Pick<Comment, 'id' | 'content' | 'createdAt' | 'solvedAt'>
    & { previousComment?: Maybe<(
      { __typename?: 'Comment' }
      & Pick<Comment, 'id'>
    )>, author: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'avatar' | 'name'>
    ), objective?: Maybe<(
      { __typename?: 'Objective' }
      & Pick<Objective, 'id' | 'description' | 'order'>
    )>, keyResult?: Maybe<(
      { __typename?: 'KeyResult' }
      & Pick<KeyResult, 'id' | 'description' | 'order'>
      & { objective: (
        { __typename?: 'Objective' }
        & Pick<Objective, 'id' | 'order'>
      ) }
    )>, review?: Maybe<(
      { __typename?: 'Review' }
      & Pick<Review, 'id' | 'description'>
      & { objective?: Maybe<(
        { __typename?: 'Objective' }
        & Pick<Objective, 'id' | 'order'>
        & { keyResults: Array<(
          { __typename?: 'KeyResult' }
          & Pick<KeyResult, 'id' | 'order'>
        )> }
      )>, keyResult?: Maybe<(
        { __typename?: 'KeyResult' }
        & Pick<KeyResult, 'id' | 'order'>
        & { objective: (
          { __typename?: 'Objective' }
          & Pick<Objective, 'id' | 'order'>
        ) }
      )> }
    )> }
  )> }
);

export type UpsertOneGlanceOverHistoryMutationVariables = Exact<{
  data: GlanceOverHistoryCreateInput;
}>;


export type UpsertOneGlanceOverHistoryMutation = (
  { __typename?: 'Mutation' }
  & { upsertOneGlanceOverHistory?: Maybe<(
    { __typename?: 'GlanceOverHistory' }
    & Pick<GlanceOverHistory, 'id'>
  )> }
);

export type GetUserGlanceOverHistoriesQueryVariables = Exact<{
  where?: Maybe<GlanceOverHistoryWhereInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<GlanceOverHistoryWhereUniqueInput>;
}>;


export type GetUserGlanceOverHistoriesQuery = (
  { __typename?: 'Query' }
  & { glanceOverHistories: Array<(
    { __typename?: 'GlanceOverHistory' }
    & Pick<GlanceOverHistory, 'id' | 'okrPeriodId' | 'pathModule'>
    & { okrAuthor: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'avatar' | 'name'>
    ), okrPeriod: (
      { __typename?: 'Period' }
      & Pick<Period, 'name'>
    ) }
  )> }
);

export type CreateOneKeyResultMutationVariables = Exact<{
  data: KeyResultCreateInput;
}>;


export type CreateOneKeyResultMutation = (
  { __typename?: 'Mutation' }
  & { createOneKeyResult?: Maybe<(
    { __typename?: 'KeyResult' }
    & Pick<KeyResult, 'id' | 'order' | 'description' | 'progress' | 'weight' | 'status'>
    & { objective: (
      { __typename?: 'Objective' }
      & Pick<Objective, 'id'>
    ) }
  )> }
);

export type UpdateOneKeyResultMutationVariables = Exact<{
  data: KeyResultUpdateInput;
  where: KeyResultWhereUniqueInput;
}>;


export type UpdateOneKeyResultMutation = (
  { __typename?: 'Mutation' }
  & { updateOneKeyResult?: Maybe<(
    { __typename?: 'KeyResult' }
    & Pick<KeyResult, 'id' | 'description' | 'progress' | 'status'>
  )> }
);

export type DeleteOneKeyResultMutationVariables = Exact<{
  where: KeyResultWhereUniqueInput;
}>;


export type DeleteOneKeyResultMutation = (
  { __typename?: 'Mutation' }
  & { deleteOneKeyResult?: Maybe<(
    { __typename?: 'KeyResult' }
    & Pick<KeyResult, 'id'>
  )> }
);

export type ReweightKeyResultsMutationVariables = Exact<{
  data: KeyResultsReweightInput;
  where: ObjectiveWhereUniqueInput;
}>;


export type ReweightKeyResultsMutation = (
  { __typename?: 'Mutation' }
  & { reweightKeyResults?: Maybe<Array<(
    { __typename?: 'KeyResult' }
    & Pick<KeyResult, 'id' | 'weight' | 'progress'>
  )>> }
);

export type ReorderKeyResultsMutationVariables = Exact<{
  data: KeyResultsReorderInput;
  where: ObjectiveWhereUniqueInput;
}>;


export type ReorderKeyResultsMutation = (
  { __typename?: 'Mutation' }
  & { reorderKeyResults?: Maybe<Array<(
    { __typename?: 'KeyResult' }
    & Pick<KeyResult, 'id' | 'order' | 'progress'>
  )>> }
);

export type ObjectivesQueryVariables = Exact<{
  where?: Maybe<ObjectiveWhereInput>;
}>;


export type ObjectivesQuery = (
  { __typename?: 'Query' }
  & { objectives: Array<(
    { __typename?: 'Objective' }
    & Pick<Objective, 'id' | 'description' | 'order' | 'private'>
    & { review?: Maybe<(
      { __typename?: 'Review' }
      & Pick<Review, 'id' | 'description'>
    )>, visibleToUsers: Array<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name' | 'email' | 'pinyinName'>
    )>, keyResults: Array<(
      { __typename?: 'KeyResult' }
      & Pick<KeyResult, 'id' | 'description' | 'progress' | 'weight' | 'status' | 'order' | 'private'>
      & { review?: Maybe<(
        { __typename?: 'Review' }
        & Pick<Review, 'id' | 'description'>
      )>, visibleToUsers: Array<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'name' | 'email' | 'pinyinName'>
      )> }
    )> }
  )> }
);

export type AlignedObjectivesQueryVariables = Exact<{
  where?: Maybe<ObjectiveWhereInput>;
}>;


export type AlignedObjectivesQuery = (
  { __typename?: 'Query' }
  & { objectives: Array<(
    { __typename?: 'Objective' }
    & Pick<Objective, 'id' | 'description'>
    & { okr: (
      { __typename?: 'Okr' }
      & Pick<Okr, 'id'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'avatar' | 'name'>
      ) }
    ), keyResults: Array<(
      { __typename?: 'KeyResult' }
      & Pick<KeyResult, 'id' | 'description' | 'progress' | 'weight' | 'status' | 'order'>
    )> }
  )> }
);

export type CreateOneObjectiveMutationVariables = Exact<{
  data: ObjectiveCreateInput;
}>;


export type CreateOneObjectiveMutation = (
  { __typename?: 'Mutation' }
  & { createOneObjective?: Maybe<(
    { __typename?: 'Objective' }
    & Pick<Objective, 'id' | 'description' | 'status'>
    & { review?: Maybe<(
      { __typename?: 'Review' }
      & Pick<Review, 'id' | 'description'>
    )>, okr: (
      { __typename?: 'Okr' }
      & Pick<Okr, 'id'>
    ) }
  )> }
);

export type UpdateOneObjectiveMutationVariables = Exact<{
  data: ObjectiveUpdateInput;
  where: ObjectiveWhereUniqueInput;
}>;


export type UpdateOneObjectiveMutation = (
  { __typename?: 'Mutation' }
  & { updateOneObjective?: Maybe<(
    { __typename?: 'Objective' }
    & Pick<Objective, 'id' | 'description' | 'status'>
    & { review?: Maybe<(
      { __typename?: 'Review' }
      & Pick<Review, 'id' | 'description'>
    )> }
  )> }
);

export type DeleteOneObjectiveMutationVariables = Exact<{
  where: ObjectiveWhereUniqueInput;
}>;


export type DeleteOneObjectiveMutation = (
  { __typename?: 'Mutation' }
  & { deleteOneObjective?: Maybe<(
    { __typename?: 'Objective' }
    & Pick<Objective, 'id'>
  )> }
);

export type ObjectivesByUserQueryVariables = Exact<{
  where: UserWhereInput;
  periodWhere: PeriodWhereInput;
  alignedIds: Array<Scalars['String']> | Scalars['String'];
}>;


export type ObjectivesByUserQuery = (
  { __typename?: 'Query' }
  & { users: Array<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name' | 'avatar'>
    & { okrs: Array<(
      { __typename?: 'Okr' }
      & Pick<Okr, 'id'>
      & { objectives: Array<(
        { __typename?: 'Objective' }
        & Pick<Objective, 'id' | 'description'>
        & { okr: (
          { __typename?: 'Okr' }
          & Pick<Okr, 'id'>
          & { author: (
            { __typename?: 'User' }
            & Pick<User, 'id' | 'avatar' | 'name'>
          ) }
        ) }
      )> }
    )> }
  )> }
);

export type AlignToOneObjectiveMutationVariables = Exact<{
  data: ObjectiveAlignInput;
  where: ObjectiveWhereUniqueInput;
}>;


export type AlignToOneObjectiveMutation = (
  { __typename?: 'Mutation' }
  & { alignToOneObjective?: Maybe<(
    { __typename?: 'Objective' }
    & Pick<Objective, 'id' | 'description'>
    & { alignedTo: Array<(
      { __typename?: 'Objective' }
      & Pick<Objective, 'id' | 'description'>
    )>, alignedWith: Array<(
      { __typename?: 'Objective' }
      & Pick<Objective, 'id' | 'description'>
    )> }
  )> }
);

export type RemoveAlignmentFromOneObjectiveMutationVariables = Exact<{
  data: ObjectiveRemoveAlignmentInput;
  where: ObjectiveWhereUniqueInput;
}>;


export type RemoveAlignmentFromOneObjectiveMutation = (
  { __typename?: 'Mutation' }
  & { removeAlignmentFromOneObjective?: Maybe<(
    { __typename?: 'Objective' }
    & Pick<Objective, 'id' | 'description'>
    & { alignedTo: Array<(
      { __typename?: 'Objective' }
      & Pick<Objective, 'id' | 'description'>
    )>, alignedWith: Array<(
      { __typename?: 'Objective' }
      & Pick<Objective, 'id' | 'description'>
    )> }
  )> }
);

export type ReorderObjectivesMutationVariables = Exact<{
  data: ObjectivesReorderInput;
  where: OkrWhereUniqueInput;
}>;


export type ReorderObjectivesMutation = (
  { __typename?: 'Mutation' }
  & { reorderObjectives?: Maybe<Array<(
    { __typename?: 'Objective' }
    & Pick<Objective, 'id' | 'description' | 'order'>
    & { review?: Maybe<(
      { __typename?: 'Review' }
      & Pick<Review, 'id' | 'description'>
    )> }
  )>> }
);

export type CopyObjectiveToOkrMutationVariables = Exact<{
  data: ObjectiveCopyInput;
}>;


export type CopyObjectiveToOkrMutation = (
  { __typename?: 'Mutation' }
  & { copyObjectiveToOkr?: Maybe<(
    { __typename?: 'Objective' }
    & Pick<Objective, 'id'>
  )> }
);

export type OkrsQueryVariables = Exact<{
  where?: Maybe<OkrWhereInput>;
}>;


export type OkrsQuery = (
  { __typename?: 'Query' }
  & { okrs: Array<(
    { __typename?: 'Okr' }
    & Pick<Okr, 'id' | 'name'>
    & { author: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'avatar' | 'name'>
    ), objectives: Array<(
      { __typename?: 'Objective' }
      & Pick<Objective, 'id' | 'description' | 'order' | 'private'>
      & { review?: Maybe<(
        { __typename?: 'Review' }
        & Pick<Review, 'id' | 'description'>
      )>, visibleToUsers: Array<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'name' | 'email' | 'pinyinName'>
      )>, keyResults: Array<(
        { __typename?: 'KeyResult' }
        & Pick<KeyResult, 'id' | 'description' | 'progress' | 'weight' | 'status' | 'order' | 'private'>
        & { review?: Maybe<(
          { __typename?: 'Review' }
          & Pick<Review, 'id' | 'description'>
        )>, visibleToUsers: Array<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'name' | 'email' | 'pinyinName'>
        )> }
      )> }
    )> }
  )> }
);

export type OkrsForCopyQueryVariables = Exact<{
  where?: Maybe<OkrWhereInput>;
}>;


export type OkrsForCopyQuery = (
  { __typename?: 'Query' }
  & { okrs: Array<(
    { __typename?: 'Okr' }
    & Pick<Okr, 'id' | 'name'>
  )> }
);

export type OkrsForTreeQueryVariables = Exact<{
  where?: Maybe<OkrWhereInput>;
}>;


export type OkrsForTreeQuery = (
  { __typename?: 'Query' }
  & { okrs: Array<(
    { __typename?: 'Okr' }
    & Pick<Okr, 'id' | 'name'>
    & { author: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'avatar' | 'name'>
    ), objectives: Array<(
      { __typename?: 'Objective' }
      & Pick<Objective, 'id' | 'description'>
      & { keyResults: Array<(
        { __typename?: 'KeyResult' }
        & Pick<KeyResult, 'id' | 'description'>
      )>, alignedTo: Array<(
        { __typename?: 'Objective' }
        & Pick<Objective, 'id' | 'description'>
        & { okr: (
          { __typename?: 'Okr' }
          & Pick<Okr, 'id'>
          & { author: (
            { __typename?: 'User' }
            & Pick<User, 'id' | 'avatar' | 'name'>
          ) }
        ), keyResults: Array<(
          { __typename?: 'KeyResult' }
          & Pick<KeyResult, 'id' | 'description'>
        )> }
      )>, alignedWith: Array<(
        { __typename?: 'Objective' }
        & Pick<Objective, 'id' | 'description'>
        & { okr: (
          { __typename?: 'Okr' }
          & Pick<Okr, 'id'>
          & { author: (
            { __typename?: 'User' }
            & Pick<User, 'id' | 'avatar' | 'name'>
          ) }
        ), keyResults: Array<(
          { __typename?: 'KeyResult' }
          & Pick<KeyResult, 'id' | 'description'>
        )> }
      )> }
    )> }
  )> }
);

export type CreateOneOkrMutationVariables = Exact<{
  data: OkrCreateInput;
}>;


export type CreateOneOkrMutation = (
  { __typename?: 'Mutation' }
  & { createOneOkr?: Maybe<(
    { __typename?: 'Okr' }
    & Pick<Okr, 'id' | 'name'>
    & { author: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name'>
    ) }
  )> }
);

export type UpdateOneOkrMutationVariables = Exact<{
  data: OkrUpdateInput;
  where: OkrWhereUniqueInput;
}>;


export type UpdateOneOkrMutation = (
  { __typename?: 'Mutation' }
  & { updateOneOkr?: Maybe<(
    { __typename?: 'Okr' }
    & Pick<Okr, 'id' | 'name'>
    & { objectives: Array<(
      { __typename?: 'Objective' }
      & Pick<Objective, 'id' | 'private'>
      & { visibleToUsers: Array<(
        { __typename?: 'User' }
        & Pick<User, 'id'>
      )>, keyResults: Array<(
        { __typename?: 'KeyResult' }
        & Pick<KeyResult, 'id' | 'private'>
        & { visibleToUsers: Array<(
          { __typename?: 'User' }
          & Pick<User, 'id'>
        )> }
      )> }
    )> }
  )> }
);

export type PeriodsQueryVariables = Exact<{
  where?: Maybe<PeriodWhereInput>;
  orderBy?: Maybe<Array<PeriodOrderByInput> | PeriodOrderByInput>;
}>;


export type PeriodsQuery = (
  { __typename?: 'Query' }
  & { periods: Array<(
    { __typename?: 'Period' }
    & Pick<Period, 'id' | 'name' | 'year' | 'quarter'>
  )> }
);

export type MyQueryVariables = Exact<{ [key: string]: never; }>;


export type MyQuery = (
  { __typename?: 'Query' }
  & { my?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name' | 'avatar'>
  )> }
);

export type UsersQueryVariables = Exact<{
  where?: Maybe<UserWhereInput>;
  take?: Maybe<Scalars['Int']>;
}>;


export type UsersQuery = (
  { __typename?: 'Query' }
  & { users: Array<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name' | 'email' | 'avatar' | 'pinyinName'>
  )> }
);

export type ViewedUserQueryVariables = Exact<{
  where: UserWhereUniqueInput;
}>;


export type ViewedUserQuery = (
  { __typename?: 'Query' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name' | 'avatar'>
  )> }
);

export type PingQueryVariables = Exact<{
  currentPage: Scalars['String'];
}>;


export type PingQuery = (
  { __typename?: 'Query' }
  & { ping?: Maybe<(
    { __typename?: 'PingResult' }
    & { currenPageUsers: Array<(
      { __typename?: 'CurrentPageUser' }
      & Pick<CurrentPageUser, 'id' | 'name' | 'avatar'>
    )> }
  )> }
);


export const CreateOneCommentDocument = gql`
    mutation createOneComment($data: CommentCreateInput!, $effect: CommentCreateEffect) {
  createOneComment(data: $data, effect: $effect) {
    id
    content
    createdAt
    solvedAt
    author {
      id
      name
      avatar
    }
    okr {
      id
    }
    objective {
      id
    }
    keyResult {
      id
    }
  }
}
    `;
export type CreateOneCommentMutationFn = Apollo.MutationFunction<CreateOneCommentMutation, CreateOneCommentMutationVariables>;

/**
 * __useCreateOneCommentMutation__
 *
 * To run a mutation, you first call `useCreateOneCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneCommentMutation, { data, loading, error }] = useCreateOneCommentMutation({
 *   variables: {
 *      data: // value for 'data'
 *      effect: // value for 'effect'
 *   },
 * });
 */
export function useCreateOneCommentMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneCommentMutation, CreateOneCommentMutationVariables>) {
        return Apollo.useMutation<CreateOneCommentMutation, CreateOneCommentMutationVariables>(CreateOneCommentDocument, baseOptions);
      }
export type CreateOneCommentMutationHookResult = ReturnType<typeof useCreateOneCommentMutation>;
export type CreateOneCommentMutationResult = Apollo.MutationResult<CreateOneCommentMutation>;
export type CreateOneCommentMutationOptions = Apollo.BaseMutationOptions<CreateOneCommentMutation, CreateOneCommentMutationVariables>;
export const DeleteOneCommentDocument = gql`
    mutation deleteOneComment($where: CommentWhereUniqueInput!) {
  deleteOneComment(where: $where) {
    id
    content
  }
}
    `;
export type DeleteOneCommentMutationFn = Apollo.MutationFunction<DeleteOneCommentMutation, DeleteOneCommentMutationVariables>;

/**
 * __useDeleteOneCommentMutation__
 *
 * To run a mutation, you first call `useDeleteOneCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneCommentMutation, { data, loading, error }] = useDeleteOneCommentMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteOneCommentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOneCommentMutation, DeleteOneCommentMutationVariables>) {
        return Apollo.useMutation<DeleteOneCommentMutation, DeleteOneCommentMutationVariables>(DeleteOneCommentDocument, baseOptions);
      }
export type DeleteOneCommentMutationHookResult = ReturnType<typeof useDeleteOneCommentMutation>;
export type DeleteOneCommentMutationResult = Apollo.MutationResult<DeleteOneCommentMutation>;
export type DeleteOneCommentMutationOptions = Apollo.BaseMutationOptions<DeleteOneCommentMutation, DeleteOneCommentMutationVariables>;
export const SolveOneCommentDocument = gql`
    mutation solveOneComment($where: CommentWhereUniqueInput!) {
  solveOneComment(where: $where) {
    id
    solvedAt
  }
}
    `;
export type SolveOneCommentMutationFn = Apollo.MutationFunction<SolveOneCommentMutation, SolveOneCommentMutationVariables>;

/**
 * __useSolveOneCommentMutation__
 *
 * To run a mutation, you first call `useSolveOneCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSolveOneCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [solveOneCommentMutation, { data, loading, error }] = useSolveOneCommentMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useSolveOneCommentMutation(baseOptions?: Apollo.MutationHookOptions<SolveOneCommentMutation, SolveOneCommentMutationVariables>) {
        return Apollo.useMutation<SolveOneCommentMutation, SolveOneCommentMutationVariables>(SolveOneCommentDocument, baseOptions);
      }
export type SolveOneCommentMutationHookResult = ReturnType<typeof useSolveOneCommentMutation>;
export type SolveOneCommentMutationResult = Apollo.MutationResult<SolveOneCommentMutation>;
export type SolveOneCommentMutationOptions = Apollo.BaseMutationOptions<SolveOneCommentMutation, SolveOneCommentMutationVariables>;
export const ReopenOneCommentDocument = gql`
    mutation reopenOneComment($where: CommentWhereUniqueInput!) {
  reopenOneComment(where: $where) {
    id
    solvedAt
  }
}
    `;
export type ReopenOneCommentMutationFn = Apollo.MutationFunction<ReopenOneCommentMutation, ReopenOneCommentMutationVariables>;

/**
 * __useReopenOneCommentMutation__
 *
 * To run a mutation, you first call `useReopenOneCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReopenOneCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reopenOneCommentMutation, { data, loading, error }] = useReopenOneCommentMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useReopenOneCommentMutation(baseOptions?: Apollo.MutationHookOptions<ReopenOneCommentMutation, ReopenOneCommentMutationVariables>) {
        return Apollo.useMutation<ReopenOneCommentMutation, ReopenOneCommentMutationVariables>(ReopenOneCommentDocument, baseOptions);
      }
export type ReopenOneCommentMutationHookResult = ReturnType<typeof useReopenOneCommentMutation>;
export type ReopenOneCommentMutationResult = Apollo.MutationResult<ReopenOneCommentMutation>;
export type ReopenOneCommentMutationOptions = Apollo.BaseMutationOptions<ReopenOneCommentMutation, ReopenOneCommentMutationVariables>;
export const OkrBottomCommentsDocument = gql`
    query okrBottomComments($where: CommentWhereInput) {
  comments(where: $where, orderBy: {createdAt: asc}) {
    id
    content
    createdAt
    solvedAt
    author {
      id
      avatar
      name
    }
  }
}
    `;

/**
 * __useOkrBottomCommentsQuery__
 *
 * To run a query within a React component, call `useOkrBottomCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOkrBottomCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOkrBottomCommentsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useOkrBottomCommentsQuery(baseOptions?: Apollo.QueryHookOptions<OkrBottomCommentsQuery, OkrBottomCommentsQueryVariables>) {
        return Apollo.useQuery<OkrBottomCommentsQuery, OkrBottomCommentsQueryVariables>(OkrBottomCommentsDocument, baseOptions);
      }
export function useOkrBottomCommentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OkrBottomCommentsQuery, OkrBottomCommentsQueryVariables>) {
          return Apollo.useLazyQuery<OkrBottomCommentsQuery, OkrBottomCommentsQueryVariables>(OkrBottomCommentsDocument, baseOptions);
        }
export type OkrBottomCommentsQueryHookResult = ReturnType<typeof useOkrBottomCommentsQuery>;
export type OkrBottomCommentsLazyQueryHookResult = ReturnType<typeof useOkrBottomCommentsLazyQuery>;
export type OkrBottomCommentsQueryResult = Apollo.QueryResult<OkrBottomCommentsQuery, OkrBottomCommentsQueryVariables>;
export const SolvedCommentsDocument = gql`
    query solvedComments($okrId: String!) {
  comments(
    orderBy: {createdAt: asc}
    where: {solvedAt: {not: null}, okr: {id: {equals: $okrId}}}
  ) {
    id
    content
    createdAt
    solvedAt
    author {
      id
      avatar
      name
    }
  }
}
    `;

/**
 * __useSolvedCommentsQuery__
 *
 * To run a query within a React component, call `useSolvedCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSolvedCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSolvedCommentsQuery({
 *   variables: {
 *      okrId: // value for 'okrId'
 *   },
 * });
 */
export function useSolvedCommentsQuery(baseOptions: Apollo.QueryHookOptions<SolvedCommentsQuery, SolvedCommentsQueryVariables>) {
        return Apollo.useQuery<SolvedCommentsQuery, SolvedCommentsQueryVariables>(SolvedCommentsDocument, baseOptions);
      }
export function useSolvedCommentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SolvedCommentsQuery, SolvedCommentsQueryVariables>) {
          return Apollo.useLazyQuery<SolvedCommentsQuery, SolvedCommentsQueryVariables>(SolvedCommentsDocument, baseOptions);
        }
export type SolvedCommentsQueryHookResult = ReturnType<typeof useSolvedCommentsQuery>;
export type SolvedCommentsLazyQueryHookResult = ReturnType<typeof useSolvedCommentsLazyQuery>;
export type SolvedCommentsQueryResult = Apollo.QueryResult<SolvedCommentsQuery, SolvedCommentsQueryVariables>;
export const CommentsDocument = gql`
    query comments($where: CommentWhereInput) {
  comments(orderBy: {createdAt: asc}, where: $where) {
    id
    content
    createdAt
    solvedAt
    previousComment {
      id
    }
    author {
      id
      avatar
      name
    }
    objective {
      id
      description
      order
    }
    keyResult {
      id
      description
      order
      objective {
        id
        order
      }
    }
    review {
      id
      description
      objective {
        id
        order
        keyResults {
          id
          order
        }
      }
      keyResult {
        id
        order
        objective {
          id
          order
        }
      }
    }
  }
}
    `;

/**
 * __useCommentsQuery__
 *
 * To run a query within a React component, call `useCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommentsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCommentsQuery(baseOptions?: Apollo.QueryHookOptions<CommentsQuery, CommentsQueryVariables>) {
        return Apollo.useQuery<CommentsQuery, CommentsQueryVariables>(CommentsDocument, baseOptions);
      }
export function useCommentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CommentsQuery, CommentsQueryVariables>) {
          return Apollo.useLazyQuery<CommentsQuery, CommentsQueryVariables>(CommentsDocument, baseOptions);
        }
export type CommentsQueryHookResult = ReturnType<typeof useCommentsQuery>;
export type CommentsLazyQueryHookResult = ReturnType<typeof useCommentsLazyQuery>;
export type CommentsQueryResult = Apollo.QueryResult<CommentsQuery, CommentsQueryVariables>;
export const UpsertOneGlanceOverHistoryDocument = gql`
    mutation upsertOneGlanceOverHistory($data: GlanceOverHistoryCreateInput!) {
  upsertOneGlanceOverHistory(data: $data) {
    id
  }
}
    `;
export type UpsertOneGlanceOverHistoryMutationFn = Apollo.MutationFunction<UpsertOneGlanceOverHistoryMutation, UpsertOneGlanceOverHistoryMutationVariables>;

/**
 * __useUpsertOneGlanceOverHistoryMutation__
 *
 * To run a mutation, you first call `useUpsertOneGlanceOverHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertOneGlanceOverHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertOneGlanceOverHistoryMutation, { data, loading, error }] = useUpsertOneGlanceOverHistoryMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpsertOneGlanceOverHistoryMutation(baseOptions?: Apollo.MutationHookOptions<UpsertOneGlanceOverHistoryMutation, UpsertOneGlanceOverHistoryMutationVariables>) {
        return Apollo.useMutation<UpsertOneGlanceOverHistoryMutation, UpsertOneGlanceOverHistoryMutationVariables>(UpsertOneGlanceOverHistoryDocument, baseOptions);
      }
export type UpsertOneGlanceOverHistoryMutationHookResult = ReturnType<typeof useUpsertOneGlanceOverHistoryMutation>;
export type UpsertOneGlanceOverHistoryMutationResult = Apollo.MutationResult<UpsertOneGlanceOverHistoryMutation>;
export type UpsertOneGlanceOverHistoryMutationOptions = Apollo.BaseMutationOptions<UpsertOneGlanceOverHistoryMutation, UpsertOneGlanceOverHistoryMutationVariables>;
export const GetUserGlanceOverHistoriesDocument = gql`
    query getUserGlanceOverHistories($where: GlanceOverHistoryWhereInput, $take: Int, $skip: Int, $cursor: GlanceOverHistoryWhereUniqueInput) {
  glanceOverHistories(where: $where, take: $take, skip: $skip, cursor: $cursor) {
    id
    okrAuthor {
      id
      avatar
      name
    }
    okrPeriod {
      name
    }
    okrPeriodId
    pathModule
  }
}
    `;

/**
 * __useGetUserGlanceOverHistoriesQuery__
 *
 * To run a query within a React component, call `useGetUserGlanceOverHistoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserGlanceOverHistoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserGlanceOverHistoriesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetUserGlanceOverHistoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetUserGlanceOverHistoriesQuery, GetUserGlanceOverHistoriesQueryVariables>) {
        return Apollo.useQuery<GetUserGlanceOverHistoriesQuery, GetUserGlanceOverHistoriesQueryVariables>(GetUserGlanceOverHistoriesDocument, baseOptions);
      }
export function useGetUserGlanceOverHistoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserGlanceOverHistoriesQuery, GetUserGlanceOverHistoriesQueryVariables>) {
          return Apollo.useLazyQuery<GetUserGlanceOverHistoriesQuery, GetUserGlanceOverHistoriesQueryVariables>(GetUserGlanceOverHistoriesDocument, baseOptions);
        }
export type GetUserGlanceOverHistoriesQueryHookResult = ReturnType<typeof useGetUserGlanceOverHistoriesQuery>;
export type GetUserGlanceOverHistoriesLazyQueryHookResult = ReturnType<typeof useGetUserGlanceOverHistoriesLazyQuery>;
export type GetUserGlanceOverHistoriesQueryResult = Apollo.QueryResult<GetUserGlanceOverHistoriesQuery, GetUserGlanceOverHistoriesQueryVariables>;
export const CreateOneKeyResultDocument = gql`
    mutation createOneKeyResult($data: KeyResultCreateInput!) {
  createOneKeyResult(data: $data) {
    id
    order
    description
    progress
    weight
    status
    objective {
      id
    }
  }
}
    `;
export type CreateOneKeyResultMutationFn = Apollo.MutationFunction<CreateOneKeyResultMutation, CreateOneKeyResultMutationVariables>;

/**
 * __useCreateOneKeyResultMutation__
 *
 * To run a mutation, you first call `useCreateOneKeyResultMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneKeyResultMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneKeyResultMutation, { data, loading, error }] = useCreateOneKeyResultMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOneKeyResultMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneKeyResultMutation, CreateOneKeyResultMutationVariables>) {
        return Apollo.useMutation<CreateOneKeyResultMutation, CreateOneKeyResultMutationVariables>(CreateOneKeyResultDocument, baseOptions);
      }
export type CreateOneKeyResultMutationHookResult = ReturnType<typeof useCreateOneKeyResultMutation>;
export type CreateOneKeyResultMutationResult = Apollo.MutationResult<CreateOneKeyResultMutation>;
export type CreateOneKeyResultMutationOptions = Apollo.BaseMutationOptions<CreateOneKeyResultMutation, CreateOneKeyResultMutationVariables>;
export const UpdateOneKeyResultDocument = gql`
    mutation updateOneKeyResult($data: KeyResultUpdateInput!, $where: KeyResultWhereUniqueInput!) {
  updateOneKeyResult(where: $where, data: $data) {
    id
    description
    progress
    status
  }
}
    `;
export type UpdateOneKeyResultMutationFn = Apollo.MutationFunction<UpdateOneKeyResultMutation, UpdateOneKeyResultMutationVariables>;

/**
 * __useUpdateOneKeyResultMutation__
 *
 * To run a mutation, you first call `useUpdateOneKeyResultMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneKeyResultMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneKeyResultMutation, { data, loading, error }] = useUpdateOneKeyResultMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateOneKeyResultMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneKeyResultMutation, UpdateOneKeyResultMutationVariables>) {
        return Apollo.useMutation<UpdateOneKeyResultMutation, UpdateOneKeyResultMutationVariables>(UpdateOneKeyResultDocument, baseOptions);
      }
export type UpdateOneKeyResultMutationHookResult = ReturnType<typeof useUpdateOneKeyResultMutation>;
export type UpdateOneKeyResultMutationResult = Apollo.MutationResult<UpdateOneKeyResultMutation>;
export type UpdateOneKeyResultMutationOptions = Apollo.BaseMutationOptions<UpdateOneKeyResultMutation, UpdateOneKeyResultMutationVariables>;
export const DeleteOneKeyResultDocument = gql`
    mutation deleteOneKeyResult($where: KeyResultWhereUniqueInput!) {
  deleteOneKeyResult(where: $where) {
    id
  }
}
    `;
export type DeleteOneKeyResultMutationFn = Apollo.MutationFunction<DeleteOneKeyResultMutation, DeleteOneKeyResultMutationVariables>;

/**
 * __useDeleteOneKeyResultMutation__
 *
 * To run a mutation, you first call `useDeleteOneKeyResultMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneKeyResultMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneKeyResultMutation, { data, loading, error }] = useDeleteOneKeyResultMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteOneKeyResultMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOneKeyResultMutation, DeleteOneKeyResultMutationVariables>) {
        return Apollo.useMutation<DeleteOneKeyResultMutation, DeleteOneKeyResultMutationVariables>(DeleteOneKeyResultDocument, baseOptions);
      }
export type DeleteOneKeyResultMutationHookResult = ReturnType<typeof useDeleteOneKeyResultMutation>;
export type DeleteOneKeyResultMutationResult = Apollo.MutationResult<DeleteOneKeyResultMutation>;
export type DeleteOneKeyResultMutationOptions = Apollo.BaseMutationOptions<DeleteOneKeyResultMutation, DeleteOneKeyResultMutationVariables>;
export const ReweightKeyResultsDocument = gql`
    mutation reweightKeyResults($data: KeyResultsReweightInput!, $where: ObjectiveWhereUniqueInput!) {
  reweightKeyResults(data: $data, where: $where) {
    id
    weight
    progress
  }
}
    `;
export type ReweightKeyResultsMutationFn = Apollo.MutationFunction<ReweightKeyResultsMutation, ReweightKeyResultsMutationVariables>;

/**
 * __useReweightKeyResultsMutation__
 *
 * To run a mutation, you first call `useReweightKeyResultsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReweightKeyResultsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reweightKeyResultsMutation, { data, loading, error }] = useReweightKeyResultsMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useReweightKeyResultsMutation(baseOptions?: Apollo.MutationHookOptions<ReweightKeyResultsMutation, ReweightKeyResultsMutationVariables>) {
        return Apollo.useMutation<ReweightKeyResultsMutation, ReweightKeyResultsMutationVariables>(ReweightKeyResultsDocument, baseOptions);
      }
export type ReweightKeyResultsMutationHookResult = ReturnType<typeof useReweightKeyResultsMutation>;
export type ReweightKeyResultsMutationResult = Apollo.MutationResult<ReweightKeyResultsMutation>;
export type ReweightKeyResultsMutationOptions = Apollo.BaseMutationOptions<ReweightKeyResultsMutation, ReweightKeyResultsMutationVariables>;
export const ReorderKeyResultsDocument = gql`
    mutation reorderKeyResults($data: KeyResultsReorderInput!, $where: ObjectiveWhereUniqueInput!) {
  reorderKeyResults(data: $data, where: $where) {
    id
    order
    progress
  }
}
    `;
export type ReorderKeyResultsMutationFn = Apollo.MutationFunction<ReorderKeyResultsMutation, ReorderKeyResultsMutationVariables>;

/**
 * __useReorderKeyResultsMutation__
 *
 * To run a mutation, you first call `useReorderKeyResultsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReorderKeyResultsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reorderKeyResultsMutation, { data, loading, error }] = useReorderKeyResultsMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useReorderKeyResultsMutation(baseOptions?: Apollo.MutationHookOptions<ReorderKeyResultsMutation, ReorderKeyResultsMutationVariables>) {
        return Apollo.useMutation<ReorderKeyResultsMutation, ReorderKeyResultsMutationVariables>(ReorderKeyResultsDocument, baseOptions);
      }
export type ReorderKeyResultsMutationHookResult = ReturnType<typeof useReorderKeyResultsMutation>;
export type ReorderKeyResultsMutationResult = Apollo.MutationResult<ReorderKeyResultsMutation>;
export type ReorderKeyResultsMutationOptions = Apollo.BaseMutationOptions<ReorderKeyResultsMutation, ReorderKeyResultsMutationVariables>;
export const ObjectivesDocument = gql`
    query objectives($where: ObjectiveWhereInput) {
  objectives(where: $where, orderBy: {createdAt: asc}) {
    id
    description
    order
    review {
      id
      description
    }
    private
    visibleToUsers {
      id
      name
      email
      pinyinName
    }
    keyResults(orderBy: {createdAt: asc}) {
      id
      description
      progress
      weight
      status
      order
      review {
        id
        description
      }
      private
      visibleToUsers {
        id
        name
        email
        pinyinName
      }
    }
  }
}
    `;

/**
 * __useObjectivesQuery__
 *
 * To run a query within a React component, call `useObjectivesQuery` and pass it any options that fit your needs.
 * When your component renders, `useObjectivesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useObjectivesQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useObjectivesQuery(baseOptions?: Apollo.QueryHookOptions<ObjectivesQuery, ObjectivesQueryVariables>) {
        return Apollo.useQuery<ObjectivesQuery, ObjectivesQueryVariables>(ObjectivesDocument, baseOptions);
      }
export function useObjectivesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ObjectivesQuery, ObjectivesQueryVariables>) {
          return Apollo.useLazyQuery<ObjectivesQuery, ObjectivesQueryVariables>(ObjectivesDocument, baseOptions);
        }
export type ObjectivesQueryHookResult = ReturnType<typeof useObjectivesQuery>;
export type ObjectivesLazyQueryHookResult = ReturnType<typeof useObjectivesLazyQuery>;
export type ObjectivesQueryResult = Apollo.QueryResult<ObjectivesQuery, ObjectivesQueryVariables>;
export const AlignedObjectivesDocument = gql`
    query alignedObjectives($where: ObjectiveWhereInput) {
  objectives(where: $where, orderBy: {createdAt: asc}) {
    id
    description
    okr {
      id
      author {
        id
        avatar
        name
      }
    }
    keyResults(orderBy: {createdAt: asc}) {
      id
      description
      progress
      weight
      status
      order
    }
  }
}
    `;

/**
 * __useAlignedObjectivesQuery__
 *
 * To run a query within a React component, call `useAlignedObjectivesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAlignedObjectivesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAlignedObjectivesQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAlignedObjectivesQuery(baseOptions?: Apollo.QueryHookOptions<AlignedObjectivesQuery, AlignedObjectivesQueryVariables>) {
        return Apollo.useQuery<AlignedObjectivesQuery, AlignedObjectivesQueryVariables>(AlignedObjectivesDocument, baseOptions);
      }
export function useAlignedObjectivesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AlignedObjectivesQuery, AlignedObjectivesQueryVariables>) {
          return Apollo.useLazyQuery<AlignedObjectivesQuery, AlignedObjectivesQueryVariables>(AlignedObjectivesDocument, baseOptions);
        }
export type AlignedObjectivesQueryHookResult = ReturnType<typeof useAlignedObjectivesQuery>;
export type AlignedObjectivesLazyQueryHookResult = ReturnType<typeof useAlignedObjectivesLazyQuery>;
export type AlignedObjectivesQueryResult = Apollo.QueryResult<AlignedObjectivesQuery, AlignedObjectivesQueryVariables>;
export const CreateOneObjectiveDocument = gql`
    mutation createOneObjective($data: ObjectiveCreateInput!) {
  createOneObjective(data: $data) {
    id
    description
    review {
      id
      description
    }
    status
    okr {
      id
    }
  }
}
    `;
export type CreateOneObjectiveMutationFn = Apollo.MutationFunction<CreateOneObjectiveMutation, CreateOneObjectiveMutationVariables>;

/**
 * __useCreateOneObjectiveMutation__
 *
 * To run a mutation, you first call `useCreateOneObjectiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneObjectiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneObjectiveMutation, { data, loading, error }] = useCreateOneObjectiveMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOneObjectiveMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneObjectiveMutation, CreateOneObjectiveMutationVariables>) {
        return Apollo.useMutation<CreateOneObjectiveMutation, CreateOneObjectiveMutationVariables>(CreateOneObjectiveDocument, baseOptions);
      }
export type CreateOneObjectiveMutationHookResult = ReturnType<typeof useCreateOneObjectiveMutation>;
export type CreateOneObjectiveMutationResult = Apollo.MutationResult<CreateOneObjectiveMutation>;
export type CreateOneObjectiveMutationOptions = Apollo.BaseMutationOptions<CreateOneObjectiveMutation, CreateOneObjectiveMutationVariables>;
export const UpdateOneObjectiveDocument = gql`
    mutation updateOneObjective($data: ObjectiveUpdateInput!, $where: ObjectiveWhereUniqueInput!) {
  updateOneObjective(where: $where, data: $data) {
    id
    description
    review {
      id
      description
    }
    status
  }
}
    `;
export type UpdateOneObjectiveMutationFn = Apollo.MutationFunction<UpdateOneObjectiveMutation, UpdateOneObjectiveMutationVariables>;

/**
 * __useUpdateOneObjectiveMutation__
 *
 * To run a mutation, you first call `useUpdateOneObjectiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneObjectiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneObjectiveMutation, { data, loading, error }] = useUpdateOneObjectiveMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateOneObjectiveMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneObjectiveMutation, UpdateOneObjectiveMutationVariables>) {
        return Apollo.useMutation<UpdateOneObjectiveMutation, UpdateOneObjectiveMutationVariables>(UpdateOneObjectiveDocument, baseOptions);
      }
export type UpdateOneObjectiveMutationHookResult = ReturnType<typeof useUpdateOneObjectiveMutation>;
export type UpdateOneObjectiveMutationResult = Apollo.MutationResult<UpdateOneObjectiveMutation>;
export type UpdateOneObjectiveMutationOptions = Apollo.BaseMutationOptions<UpdateOneObjectiveMutation, UpdateOneObjectiveMutationVariables>;
export const DeleteOneObjectiveDocument = gql`
    mutation deleteOneObjective($where: ObjectiveWhereUniqueInput!) {
  deleteOneObjective(where: $where) {
    id
  }
}
    `;
export type DeleteOneObjectiveMutationFn = Apollo.MutationFunction<DeleteOneObjectiveMutation, DeleteOneObjectiveMutationVariables>;

/**
 * __useDeleteOneObjectiveMutation__
 *
 * To run a mutation, you first call `useDeleteOneObjectiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneObjectiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneObjectiveMutation, { data, loading, error }] = useDeleteOneObjectiveMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteOneObjectiveMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOneObjectiveMutation, DeleteOneObjectiveMutationVariables>) {
        return Apollo.useMutation<DeleteOneObjectiveMutation, DeleteOneObjectiveMutationVariables>(DeleteOneObjectiveDocument, baseOptions);
      }
export type DeleteOneObjectiveMutationHookResult = ReturnType<typeof useDeleteOneObjectiveMutation>;
export type DeleteOneObjectiveMutationResult = Apollo.MutationResult<DeleteOneObjectiveMutation>;
export type DeleteOneObjectiveMutationOptions = Apollo.BaseMutationOptions<DeleteOneObjectiveMutation, DeleteOneObjectiveMutationVariables>;
export const ObjectivesByUserDocument = gql`
    query objectivesByUser($where: UserWhereInput!, $periodWhere: PeriodWhereInput!, $alignedIds: [String!]!) {
  users(where: $where, take: 20) {
    id
    name
    avatar
    okrs(where: {period: $periodWhere}) {
      id
      objectives(where: {id: {not: {in: $alignedIds}}}) {
        id
        description
        okr {
          id
          author {
            id
            avatar
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useObjectivesByUserQuery__
 *
 * To run a query within a React component, call `useObjectivesByUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useObjectivesByUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useObjectivesByUserQuery({
 *   variables: {
 *      where: // value for 'where'
 *      periodWhere: // value for 'periodWhere'
 *      alignedIds: // value for 'alignedIds'
 *   },
 * });
 */
export function useObjectivesByUserQuery(baseOptions: Apollo.QueryHookOptions<ObjectivesByUserQuery, ObjectivesByUserQueryVariables>) {
        return Apollo.useQuery<ObjectivesByUserQuery, ObjectivesByUserQueryVariables>(ObjectivesByUserDocument, baseOptions);
      }
export function useObjectivesByUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ObjectivesByUserQuery, ObjectivesByUserQueryVariables>) {
          return Apollo.useLazyQuery<ObjectivesByUserQuery, ObjectivesByUserQueryVariables>(ObjectivesByUserDocument, baseOptions);
        }
export type ObjectivesByUserQueryHookResult = ReturnType<typeof useObjectivesByUserQuery>;
export type ObjectivesByUserLazyQueryHookResult = ReturnType<typeof useObjectivesByUserLazyQuery>;
export type ObjectivesByUserQueryResult = Apollo.QueryResult<ObjectivesByUserQuery, ObjectivesByUserQueryVariables>;
export const AlignToOneObjectiveDocument = gql`
    mutation alignToOneObjective($data: ObjectiveAlignInput!, $where: ObjectiveWhereUniqueInput!) {
  alignToOneObjective(where: $where, data: $data) {
    id
    description
    alignedTo {
      id
      description
    }
    alignedWith {
      id
      description
    }
  }
}
    `;
export type AlignToOneObjectiveMutationFn = Apollo.MutationFunction<AlignToOneObjectiveMutation, AlignToOneObjectiveMutationVariables>;

/**
 * __useAlignToOneObjectiveMutation__
 *
 * To run a mutation, you first call `useAlignToOneObjectiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAlignToOneObjectiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [alignToOneObjectiveMutation, { data, loading, error }] = useAlignToOneObjectiveMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAlignToOneObjectiveMutation(baseOptions?: Apollo.MutationHookOptions<AlignToOneObjectiveMutation, AlignToOneObjectiveMutationVariables>) {
        return Apollo.useMutation<AlignToOneObjectiveMutation, AlignToOneObjectiveMutationVariables>(AlignToOneObjectiveDocument, baseOptions);
      }
export type AlignToOneObjectiveMutationHookResult = ReturnType<typeof useAlignToOneObjectiveMutation>;
export type AlignToOneObjectiveMutationResult = Apollo.MutationResult<AlignToOneObjectiveMutation>;
export type AlignToOneObjectiveMutationOptions = Apollo.BaseMutationOptions<AlignToOneObjectiveMutation, AlignToOneObjectiveMutationVariables>;
export const RemoveAlignmentFromOneObjectiveDocument = gql`
    mutation removeAlignmentFromOneObjective($data: ObjectiveRemoveAlignmentInput!, $where: ObjectiveWhereUniqueInput!) {
  removeAlignmentFromOneObjective(where: $where, data: $data) {
    id
    description
    alignedTo {
      id
      description
    }
    alignedWith {
      id
      description
    }
  }
}
    `;
export type RemoveAlignmentFromOneObjectiveMutationFn = Apollo.MutationFunction<RemoveAlignmentFromOneObjectiveMutation, RemoveAlignmentFromOneObjectiveMutationVariables>;

/**
 * __useRemoveAlignmentFromOneObjectiveMutation__
 *
 * To run a mutation, you first call `useRemoveAlignmentFromOneObjectiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAlignmentFromOneObjectiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAlignmentFromOneObjectiveMutation, { data, loading, error }] = useRemoveAlignmentFromOneObjectiveMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useRemoveAlignmentFromOneObjectiveMutation(baseOptions?: Apollo.MutationHookOptions<RemoveAlignmentFromOneObjectiveMutation, RemoveAlignmentFromOneObjectiveMutationVariables>) {
        return Apollo.useMutation<RemoveAlignmentFromOneObjectiveMutation, RemoveAlignmentFromOneObjectiveMutationVariables>(RemoveAlignmentFromOneObjectiveDocument, baseOptions);
      }
export type RemoveAlignmentFromOneObjectiveMutationHookResult = ReturnType<typeof useRemoveAlignmentFromOneObjectiveMutation>;
export type RemoveAlignmentFromOneObjectiveMutationResult = Apollo.MutationResult<RemoveAlignmentFromOneObjectiveMutation>;
export type RemoveAlignmentFromOneObjectiveMutationOptions = Apollo.BaseMutationOptions<RemoveAlignmentFromOneObjectiveMutation, RemoveAlignmentFromOneObjectiveMutationVariables>;
export const ReorderObjectivesDocument = gql`
    mutation reorderObjectives($data: ObjectivesReorderInput!, $where: OkrWhereUniqueInput!) {
  reorderObjectives(data: $data, where: $where) {
    id
    description
    review {
      id
      description
    }
    order
  }
}
    `;
export type ReorderObjectivesMutationFn = Apollo.MutationFunction<ReorderObjectivesMutation, ReorderObjectivesMutationVariables>;

/**
 * __useReorderObjectivesMutation__
 *
 * To run a mutation, you first call `useReorderObjectivesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReorderObjectivesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reorderObjectivesMutation, { data, loading, error }] = useReorderObjectivesMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useReorderObjectivesMutation(baseOptions?: Apollo.MutationHookOptions<ReorderObjectivesMutation, ReorderObjectivesMutationVariables>) {
        return Apollo.useMutation<ReorderObjectivesMutation, ReorderObjectivesMutationVariables>(ReorderObjectivesDocument, baseOptions);
      }
export type ReorderObjectivesMutationHookResult = ReturnType<typeof useReorderObjectivesMutation>;
export type ReorderObjectivesMutationResult = Apollo.MutationResult<ReorderObjectivesMutation>;
export type ReorderObjectivesMutationOptions = Apollo.BaseMutationOptions<ReorderObjectivesMutation, ReorderObjectivesMutationVariables>;
export const CopyObjectiveToOkrDocument = gql`
    mutation copyObjectiveToOkr($data: ObjectiveCopyInput!) {
  copyObjectiveToOkr(data: $data) {
    id
  }
}
    `;
export type CopyObjectiveToOkrMutationFn = Apollo.MutationFunction<CopyObjectiveToOkrMutation, CopyObjectiveToOkrMutationVariables>;

/**
 * __useCopyObjectiveToOkrMutation__
 *
 * To run a mutation, you first call `useCopyObjectiveToOkrMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyObjectiveToOkrMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyObjectiveToOkrMutation, { data, loading, error }] = useCopyObjectiveToOkrMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCopyObjectiveToOkrMutation(baseOptions?: Apollo.MutationHookOptions<CopyObjectiveToOkrMutation, CopyObjectiveToOkrMutationVariables>) {
        return Apollo.useMutation<CopyObjectiveToOkrMutation, CopyObjectiveToOkrMutationVariables>(CopyObjectiveToOkrDocument, baseOptions);
      }
export type CopyObjectiveToOkrMutationHookResult = ReturnType<typeof useCopyObjectiveToOkrMutation>;
export type CopyObjectiveToOkrMutationResult = Apollo.MutationResult<CopyObjectiveToOkrMutation>;
export type CopyObjectiveToOkrMutationOptions = Apollo.BaseMutationOptions<CopyObjectiveToOkrMutation, CopyObjectiveToOkrMutationVariables>;
export const OkrsDocument = gql`
    query okrs($where: OkrWhereInput) {
  okrs(where: $where) {
    id
    name
    author {
      id
      avatar
      name
    }
    objectives(orderBy: {createdAt: asc}) {
      id
      description
      order
      review {
        id
        description
      }
      private
      visibleToUsers {
        id
        name
        email
        pinyinName
      }
      keyResults(orderBy: {createdAt: asc}) {
        id
        description
        progress
        weight
        status
        order
        review {
          id
          description
        }
        private
        visibleToUsers {
          id
          name
          email
          pinyinName
        }
      }
    }
  }
}
    `;

/**
 * __useOkrsQuery__
 *
 * To run a query within a React component, call `useOkrsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOkrsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOkrsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useOkrsQuery(baseOptions?: Apollo.QueryHookOptions<OkrsQuery, OkrsQueryVariables>) {
        return Apollo.useQuery<OkrsQuery, OkrsQueryVariables>(OkrsDocument, baseOptions);
      }
export function useOkrsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OkrsQuery, OkrsQueryVariables>) {
          return Apollo.useLazyQuery<OkrsQuery, OkrsQueryVariables>(OkrsDocument, baseOptions);
        }
export type OkrsQueryHookResult = ReturnType<typeof useOkrsQuery>;
export type OkrsLazyQueryHookResult = ReturnType<typeof useOkrsLazyQuery>;
export type OkrsQueryResult = Apollo.QueryResult<OkrsQuery, OkrsQueryVariables>;
export const OkrsForCopyDocument = gql`
    query okrsForCopy($where: OkrWhereInput) {
  okrs(where: $where) {
    id
    name
  }
}
    `;

/**
 * __useOkrsForCopyQuery__
 *
 * To run a query within a React component, call `useOkrsForCopyQuery` and pass it any options that fit your needs.
 * When your component renders, `useOkrsForCopyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOkrsForCopyQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useOkrsForCopyQuery(baseOptions?: Apollo.QueryHookOptions<OkrsForCopyQuery, OkrsForCopyQueryVariables>) {
        return Apollo.useQuery<OkrsForCopyQuery, OkrsForCopyQueryVariables>(OkrsForCopyDocument, baseOptions);
      }
export function useOkrsForCopyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OkrsForCopyQuery, OkrsForCopyQueryVariables>) {
          return Apollo.useLazyQuery<OkrsForCopyQuery, OkrsForCopyQueryVariables>(OkrsForCopyDocument, baseOptions);
        }
export type OkrsForCopyQueryHookResult = ReturnType<typeof useOkrsForCopyQuery>;
export type OkrsForCopyLazyQueryHookResult = ReturnType<typeof useOkrsForCopyLazyQuery>;
export type OkrsForCopyQueryResult = Apollo.QueryResult<OkrsForCopyQuery, OkrsForCopyQueryVariables>;
export const OkrsForTreeDocument = gql`
    query okrsForTree($where: OkrWhereInput) {
  okrs(where: $where) {
    id
    name
    author {
      id
      avatar
      name
    }
    objectives(orderBy: {createdAt: asc}) {
      id
      description
      keyResults(orderBy: {createdAt: asc}) {
        id
        description
      }
      alignedTo {
        id
        description
        okr {
          id
          author {
            id
            avatar
            name
          }
        }
        keyResults(orderBy: {createdAt: asc}) {
          id
          description
        }
      }
      alignedWith {
        id
        description
        okr {
          id
          author {
            id
            avatar
            name
          }
        }
        keyResults(orderBy: {createdAt: asc}) {
          id
          description
        }
      }
    }
  }
}
    `;

/**
 * __useOkrsForTreeQuery__
 *
 * To run a query within a React component, call `useOkrsForTreeQuery` and pass it any options that fit your needs.
 * When your component renders, `useOkrsForTreeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOkrsForTreeQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useOkrsForTreeQuery(baseOptions?: Apollo.QueryHookOptions<OkrsForTreeQuery, OkrsForTreeQueryVariables>) {
        return Apollo.useQuery<OkrsForTreeQuery, OkrsForTreeQueryVariables>(OkrsForTreeDocument, baseOptions);
      }
export function useOkrsForTreeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OkrsForTreeQuery, OkrsForTreeQueryVariables>) {
          return Apollo.useLazyQuery<OkrsForTreeQuery, OkrsForTreeQueryVariables>(OkrsForTreeDocument, baseOptions);
        }
export type OkrsForTreeQueryHookResult = ReturnType<typeof useOkrsForTreeQuery>;
export type OkrsForTreeLazyQueryHookResult = ReturnType<typeof useOkrsForTreeLazyQuery>;
export type OkrsForTreeQueryResult = Apollo.QueryResult<OkrsForTreeQuery, OkrsForTreeQueryVariables>;
export const CreateOneOkrDocument = gql`
    mutation createOneOkr($data: OkrCreateInput!) {
  createOneOkr(data: $data) {
    id
    name
    author {
      id
      name
    }
  }
}
    `;
export type CreateOneOkrMutationFn = Apollo.MutationFunction<CreateOneOkrMutation, CreateOneOkrMutationVariables>;

/**
 * __useCreateOneOkrMutation__
 *
 * To run a mutation, you first call `useCreateOneOkrMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneOkrMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneOkrMutation, { data, loading, error }] = useCreateOneOkrMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOneOkrMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneOkrMutation, CreateOneOkrMutationVariables>) {
        return Apollo.useMutation<CreateOneOkrMutation, CreateOneOkrMutationVariables>(CreateOneOkrDocument, baseOptions);
      }
export type CreateOneOkrMutationHookResult = ReturnType<typeof useCreateOneOkrMutation>;
export type CreateOneOkrMutationResult = Apollo.MutationResult<CreateOneOkrMutation>;
export type CreateOneOkrMutationOptions = Apollo.BaseMutationOptions<CreateOneOkrMutation, CreateOneOkrMutationVariables>;
export const UpdateOneOkrDocument = gql`
    mutation updateOneOkr($data: OkrUpdateInput!, $where: OkrWhereUniqueInput!) {
  updateOneOkr(data: $data, where: $where) {
    id
    name
    objectives {
      id
      private
      visibleToUsers {
        id
      }
      keyResults(orderBy: {createdAt: asc}) {
        id
        private
        visibleToUsers {
          id
        }
      }
    }
  }
}
    `;
export type UpdateOneOkrMutationFn = Apollo.MutationFunction<UpdateOneOkrMutation, UpdateOneOkrMutationVariables>;

/**
 * __useUpdateOneOkrMutation__
 *
 * To run a mutation, you first call `useUpdateOneOkrMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneOkrMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneOkrMutation, { data, loading, error }] = useUpdateOneOkrMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateOneOkrMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneOkrMutation, UpdateOneOkrMutationVariables>) {
        return Apollo.useMutation<UpdateOneOkrMutation, UpdateOneOkrMutationVariables>(UpdateOneOkrDocument, baseOptions);
      }
export type UpdateOneOkrMutationHookResult = ReturnType<typeof useUpdateOneOkrMutation>;
export type UpdateOneOkrMutationResult = Apollo.MutationResult<UpdateOneOkrMutation>;
export type UpdateOneOkrMutationOptions = Apollo.BaseMutationOptions<UpdateOneOkrMutation, UpdateOneOkrMutationVariables>;
export const PeriodsDocument = gql`
    query periods($where: PeriodWhereInput, $orderBy: [PeriodOrderByInput!]) {
  periods(where: $where, orderBy: $orderBy) {
    id
    name
    year
    quarter
  }
}
    `;

/**
 * __usePeriodsQuery__
 *
 * To run a query within a React component, call `usePeriodsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePeriodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePeriodsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function usePeriodsQuery(baseOptions?: Apollo.QueryHookOptions<PeriodsQuery, PeriodsQueryVariables>) {
        return Apollo.useQuery<PeriodsQuery, PeriodsQueryVariables>(PeriodsDocument, baseOptions);
      }
export function usePeriodsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PeriodsQuery, PeriodsQueryVariables>) {
          return Apollo.useLazyQuery<PeriodsQuery, PeriodsQueryVariables>(PeriodsDocument, baseOptions);
        }
export type PeriodsQueryHookResult = ReturnType<typeof usePeriodsQuery>;
export type PeriodsLazyQueryHookResult = ReturnType<typeof usePeriodsLazyQuery>;
export type PeriodsQueryResult = Apollo.QueryResult<PeriodsQuery, PeriodsQueryVariables>;
export const MyDocument = gql`
    query my {
  my {
    id
    name
    avatar
  }
}
    `;

/**
 * __useMyQuery__
 *
 * To run a query within a React component, call `useMyQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyQuery(baseOptions?: Apollo.QueryHookOptions<MyQuery, MyQueryVariables>) {
        return Apollo.useQuery<MyQuery, MyQueryVariables>(MyDocument, baseOptions);
      }
export function useMyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyQuery, MyQueryVariables>) {
          return Apollo.useLazyQuery<MyQuery, MyQueryVariables>(MyDocument, baseOptions);
        }
export type MyQueryHookResult = ReturnType<typeof useMyQuery>;
export type MyLazyQueryHookResult = ReturnType<typeof useMyLazyQuery>;
export type MyQueryResult = Apollo.QueryResult<MyQuery, MyQueryVariables>;
export const UsersDocument = gql`
    query users($where: UserWhereInput, $take: Int) {
  users(where: $where, take: $take) {
    id
    name
    email
    avatar
    pinyinName
  }
}
    `;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      where: // value for 'where'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, baseOptions);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, baseOptions);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;
export const ViewedUserDocument = gql`
    query viewedUser($where: UserWhereUniqueInput!) {
  user(where: $where) {
    id
    name
    avatar
  }
}
    `;

/**
 * __useViewedUserQuery__
 *
 * To run a query within a React component, call `useViewedUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewedUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewedUserQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useViewedUserQuery(baseOptions: Apollo.QueryHookOptions<ViewedUserQuery, ViewedUserQueryVariables>) {
        return Apollo.useQuery<ViewedUserQuery, ViewedUserQueryVariables>(ViewedUserDocument, baseOptions);
      }
export function useViewedUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ViewedUserQuery, ViewedUserQueryVariables>) {
          return Apollo.useLazyQuery<ViewedUserQuery, ViewedUserQueryVariables>(ViewedUserDocument, baseOptions);
        }
export type ViewedUserQueryHookResult = ReturnType<typeof useViewedUserQuery>;
export type ViewedUserLazyQueryHookResult = ReturnType<typeof useViewedUserLazyQuery>;
export type ViewedUserQueryResult = Apollo.QueryResult<ViewedUserQuery, ViewedUserQueryVariables>;
export const PingDocument = gql`
    query ping($currentPage: String!) {
  ping(currentPage: $currentPage) {
    currenPageUsers {
      id
      name
      avatar
    }
  }
}
    `;

/**
 * __usePingQuery__
 *
 * To run a query within a React component, call `usePingQuery` and pass it any options that fit your needs.
 * When your component renders, `usePingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePingQuery({
 *   variables: {
 *      currentPage: // value for 'currentPage'
 *   },
 * });
 */
export function usePingQuery(baseOptions: Apollo.QueryHookOptions<PingQuery, PingQueryVariables>) {
        return Apollo.useQuery<PingQuery, PingQueryVariables>(PingDocument, baseOptions);
      }
export function usePingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PingQuery, PingQueryVariables>) {
          return Apollo.useLazyQuery<PingQuery, PingQueryVariables>(PingDocument, baseOptions);
        }
export type PingQueryHookResult = ReturnType<typeof usePingQuery>;
export type PingLazyQueryHookResult = ReturnType<typeof usePingLazyQuery>;
export type PingQueryResult = Apollo.QueryResult<PingQuery, PingQueryVariables>;