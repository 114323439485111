import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import { StringParam, useQueryParam } from "use-query-params";
import GlobalLoading from "../../components/GlobalLoading";
import { useOkrsQuery } from "../../generated/graphql";
import { useAuth } from "../../lib/auth";
import { emitter } from "../../lib/emitter";
import BottomComments from "./BottomComments";
import CommentList, { DRAWER_WIDTH, LocalComment } from "./CommentList";
import Empty from "./Empty";
import Header from "./Header";
import ObjectiveWrapper from "./ObjectiveWrapper";

const useStyles = makeStyles((theme) => ({
  main: {
    marginRight: -DRAWER_WIDTH,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  mainShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
}));

export default function OkrList() {
  const classes = useStyles();
  const { user } = useAuth();
  const [periodId] = useQueryParam("period", StringParam);
  const [_authorId] = useQueryParam("author", StringParam);
  const authorId = _authorId || user?.id;

  const readOnly = user?.id !== authorId;

  const [localComment, setLocalComment] = useState<LocalComment | null>(null);
  const [activeKey, setActiveKey] = useQueryParam(
    "active-comment",
    StringParam
  );

  const {
    data: currentOkrsData,
    previousData: previousOkrsData,
    loading: queryOkrsLoading,
  } = useOkrsQuery({
    skip: !periodId,
    variables: {
      where: {
        period: {
          id: {
            equals: periodId,
          },
        },
        author: {
          id: {
            equals: authorId,
          },
        },
      },
    },
    fetchPolicy: "cache-and-network",
    pollInterval: 60_000,
  });

  // show previous data during a new fetch
  const okrsData = currentOkrsData || previousOkrsData;
  const okr = okrsData?.okrs[0];

  if (!previousOkrsData && queryOkrsLoading) {
    return <GlobalLoading />;
  }

  return (
    <Box display="flex" height="100%">
      <Box
        flex="1"
        className={
          activeKey !== undefined || okrsData?.okrs.length === 0
            ? classes.mainShift
            : classes.main
        }
      >
        <Header readOnly={readOnly} okr={okr} />
        {okr ? (
          <ObjectiveWrapper
            readOnly={readOnly}
            okr={okr}
            setLocalComment={setLocalComment}
          />
        ) : (
          <Empty readOnly={readOnly} />
        )}
        {okr && <BottomComments readOnly={readOnly} okrId={okr.id} />}
      </Box>
      {okr && (
        <CommentList
          open={activeKey !== undefined}
          onClose={() => setActiveKey(undefined, "replaceIn")}
          okr={{
            id: okr.id,
            authorId: okr.author.id,
          }}
          localComment={localComment}
          onCancelLocal={() => {
            setLocalComment(null);
            emitter.emit("cancel-comment");
          }}
        />
      )}
    </Box>
  );
}
