import {
  Box,
  Button,
  Collapse,
  ListItem,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import ExpandMore from "@material-ui/icons/ExpandMore";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import LockIcon from "@material-ui/icons/Lock";
import RateReviewIcon from "@material-ui/icons/RateReviewOutlined";
import clsx from "clsx";
import React, { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { StringParam, useQueryParam } from "use-query-params";
import DeleteButton from "../../components/DeleteButton";
import EditableText from "../../components/EditableText";
import EditableTextField from "../../components/EditableTextField";
import ReviewButton from "../../components/ReviewButton";
import {
  OkrsQuery,
  Status,
  useCreateOneKeyResultMutation,
  useDeleteOneKeyResultMutation,
  useReorderKeyResultsMutation,
  useUpdateOneKeyResultMutation,
} from "../../generated/graphql";
import { rawStrToPlainText } from "../../lib/draft-js";
import { LocalComment } from "./CommentList";
import { floor } from "./math";
import { Weight } from "./ObjectiveList";

const useStyles = makeStyles((theme) => ({
  list: {
    flex: 1,
  },
  listItem: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingTop: 0,
    paddingBottom: 0,
    "& .MuiSvgIcon-root": {
      opacity: 0,
      transition: "300ms",
    },
    "&:hover .MuiSvgIcon-root": {
      opacity: 1,
    },
  },
  mark: {
    opacity: "1 !important",
  },
  colloapseArrow: {
    color: "#a5a3a3",
    opacity: "1 !important",
    cursor: "pointer",
  },
  cell: {
    width: 82,
    lineHeight: "30px",
  },
  operaionCell: {
    width: 40,
  },
  listItemWrapper: {
    width: "100%",
  },
  listItemKeyResultWrapper: {
    display: "flex",
    "justify-content": "start",
    "align-items": "center",
    width: "100%",
  },
  review: {
    fontSize: "0.8rem",
    color: "#505e74",
    paddingLeft: 5,
    marginBottom: 10,
    borderLeft: "1px solid #d9d9d9",
  },
}));

interface KeyResultListProps {
  readOnly: boolean;
  objectiveId: string;
  sortedKeyResults: OkrsQuery["okrs"][0]["objectives"][0]["keyResults"];
  setLocalComment: React.Dispatch<React.SetStateAction<LocalComment | null>>;
  setKrLocalOrders: React.Dispatch<React.SetStateAction<string[] | null>>;
  weights: Weight | null;
  setWeights: React.Dispatch<React.SetStateAction<Weight | null>>;
}

const KeyResultList = (props: KeyResultListProps) => {
  const classes = useStyles();

  const {
    readOnly,
    objectiveId,
    sortedKeyResults,
    setLocalComment,
    setKrLocalOrders,
    weights,
    setWeights,
  } = props;

  const [, setActiveKey] = useQueryParam("active-comment", StringParam);

  const [
    creatingKeyResultObjectiveIds,
    setCreatingKeyResultObjectiveIds,
  ] = useState<Set<string>>(new Set());
  const [currentReviewingItems, setCurrentReviewingItems] = useState<string[]>(
    []
  );

  const [openKrList, setOpenKrList] = useState<Set<string>>(new Set());
  const [allOpenObjectiveList, setAllOpenObjectiveList] = useState<Set<string>>(
    new Set()
  );

  const openKrReivew = (id: string) => {
    setCurrentReviewingItems((prev) => prev.concat(id || ""));
    setOpenKrList((ids) => {
      ids.add(id);
      return new Set(ids);
    });
  };

  const [reorderKeyResults] = useReorderKeyResultsMutation();
  const [updateOneKeyResult] = useUpdateOneKeyResultMutation();
  const [deleteOneKeyResult] = useDeleteOneKeyResultMutation({
    refetchQueries: ["okrs"],
  });
  const [
    createOneKeyResult,
    { loading: createKeyResultLoading },
  ] = useCreateOneKeyResultMutation({
    refetchQueries: ["okrs"],
  });

  return (
    <DragDropContext
      onDragEnd={({ source, destination }) => {
        const ids = sortedKeyResults.map((kr) => ({
          id: kr.id || "",
        }));
        const [moved] = ids.splice(source.index, 1);
        ids.splice(destination?.index ?? ids.length - 1, 0, moved);
        setKrLocalOrders(ids.map((v) => v.id));
        reorderKeyResults({
          variables: {
            where: {
              id: objectiveId,
            },
            data: {
              orders: ids,
            },
          },
        }).finally(() => setKrLocalOrders(null));
      }}
    >
      <Droppable droppableId={`${objectiveId}-key-results`} type="key-result">
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {sortedKeyResults.map((kr, index) => {
              let value: string | number | null | undefined =
                weights?.weights.find((w) => w.id === kr.id)?.weight ??
                kr.weight;
              if (Number.isNaN(value)) {
                value = "";
              }
              return (
                <Draggable
                  index={index}
                  draggableId={kr.id || ""}
                  key={kr.id}
                  isDragDisabled={readOnly}
                >
                  {(dragProvided) => (
                    <ListItem
                      className={clsx(classes.listItem)}
                      innerRef={dragProvided.innerRef}
                      {...dragProvided.draggableProps}
                    >
                      <div className={classes.listItemWrapper}>
                        <div className={classes.listItemKeyResultWrapper}>
                          <Box
                            display="flex"
                            alignItems="center"
                            {...dragProvided.dragHandleProps}
                          >
                            {!readOnly && (
                              <DragIndicatorIcon color="disabled" />
                            )}
                            {kr.private ? (
                              <LockIcon className={classes.mark} />
                            ) : (
                              ""
                            )}
                            {openKrList.has(kr.id) ? (
                              <ExpandMore
                                className={classes.colloapseArrow}
                                onClick={() =>
                                  setOpenKrList((ids) => {
                                    ids.delete(kr.id);
                                    return new Set(ids);
                                  })
                                }
                              />
                            ) : (
                              <KeyboardArrowRightIcon
                                className={classes.colloapseArrow}
                                onClick={() => {
                                  openKrReivew(kr.id);
                                }}
                              />
                            )}
                          </Box>
                          <EditableText
                            rawStr={kr.description || ""}
                            readOnly={readOnly}
                            placeholder="为 Key Result 添加描述"
                            onBlur={(raw) => {
                              updateOneKeyResult({
                                variables: {
                                  where: {
                                    id: kr.id,
                                  },
                                  data: {
                                    description: JSON.stringify(raw),
                                  },
                                },
                              });
                            }}
                            allowComment
                            onComment={(rawState) => {
                              setLocalComment({
                                type: "keyResultDescription",
                                targetId: kr.id || "",
                                rawState,
                              });
                              setActiveKey("local", "replaceIn");
                            }}
                          />
                          <Box display="flex" alignItems="center">
                            <Box className={classes.cell}>
                              <EditableTextField
                                value={floor(kr.progress || 0, 1)}
                                type="number"
                                validate={(value) => {
                                  const numValue = parseInt(value);
                                  if (numValue < 0 || numValue > 100) {
                                    return "范围为 0 - 100";
                                  }
                                  return null;
                                }}
                                disabled={readOnly}
                                onChange={(e) => {
                                  updateOneKeyResult({
                                    variables: {
                                      where: {
                                        id: kr.id,
                                      },
                                      data: {
                                        progress: floor(
                                          parseInt(e.currentTarget.value),
                                          1
                                        ),
                                      },
                                    },
                                  });
                                }}
                              >
                                {floor(kr.progress || 0, 1)}%
                              </EditableTextField>
                            </Box>
                            <Box
                              className={classes.cell}
                              onClick={(evt) => {
                                if (!readOnly && !weights) {
                                  setWeights({
                                    id: objectiveId || "",
                                    weights: sortedKeyResults.map((kr) => ({
                                      id: kr.id || "",
                                      weight: String(kr.weight) || "0",
                                    })),
                                  });
                                  // try to focus
                                  const dom = evt.currentTarget;
                                  setTimeout(() => {
                                    dom.querySelector("input")?.focus();
                                  }, 0);
                                }
                              }}
                            >
                              {weights?.id !== objectiveId &&
                                `${floor(kr.weight || 0, 1)}%`}
                              {weights?.id === objectiveId && (
                                <TextField
                                  value={value}
                                  onChange={(evt) =>
                                    setWeights({
                                      id: objectiveId || "",
                                      weights: (weights?.weights || []).map(
                                        (w) => {
                                          if (w.id === kr.id) {
                                            return {
                                              id: w.id,
                                              weight: evt.currentTarget.value,
                                            };
                                          }
                                          return w;
                                        }
                                      ),
                                    })
                                  }
                                />
                              )}
                            </Box>
                            <Box className={classes.operaionCell}>
                              {!readOnly && (
                                <DeleteButton
                                  onClick={() => {
                                    deleteOneKeyResult({
                                      variables: {
                                        where: {
                                          id: kr.id,
                                        },
                                      },
                                    });
                                    setWeights({
                                      id:
                                        weights?.id === objectiveId
                                          ? objectiveId
                                          : "",
                                      weights: (weights?.weights || []).filter(
                                        (w) => w.id !== kr.id
                                      ),
                                    });
                                  }}
                                />
                              )}
                            </Box>
                            <Box className={classes.operaionCell}>
                              {!readOnly && (
                                <ReviewButton
                                  onClick={() => {
                                    openKrReivew(kr.id);
                                  }}
                                />
                              )}
                            </Box>
                          </Box>
                        </div>

                        {(rawStrToPlainText(kr.review?.description || "") ||
                          currentReviewingItems.includes(kr.id || "")) && (
                          <Collapse in={openKrList.has(kr.id)}>
                            <div>
                              <Box display="flex">
                                <Box mr={1}>
                                  <RateReviewIcon color="disabled" />
                                </Box>
                                <Box pl={2} flex="1">
                                  <Typography variant="caption">
                                    进度记录
                                  </Typography>
                                  <Box mt={1} className={classes.review}>
                                    <EditableText
                                      rawStr={kr.review?.description || ""}
                                      readOnly={readOnly}
                                      onBlur={(raw) => {
                                        updateOneKeyResult({
                                          variables: {
                                            data: {
                                              review: JSON.stringify(raw),
                                            },
                                            where: {
                                              id: kr.id,
                                            },
                                          },
                                        });
                                      }}
                                      placeholder="保持更新，让小伙伴们看到你的进度(键入 [] 开始使用多选框)"
                                      allowMention
                                      allowComment
                                      onComment={(rawState) => {
                                        setLocalComment({
                                          type: "reviewDescription",
                                          targetId: kr.review?.id || "",
                                          rawState,
                                        });
                                        setActiveKey("local", "replaceIn");
                                      }}
                                    />
                                  </Box>
                                </Box>
                              </Box>
                            </div>
                          </Collapse>
                        )}
                      </div>
                    </ListItem>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
            <ListItem>
              <Button
                size="small"
                startIcon={<FullscreenIcon />}
                onClick={() => {
                  const isAllOpen = allOpenObjectiveList.has(objectiveId);
                  if (isAllOpen) {
                    setAllOpenObjectiveList((ids) => {
                      ids.delete(objectiveId);
                      return new Set(ids);
                    });
                    for (const kr of sortedKeyResults) {
                      setOpenKrList((ids) => {
                        ids.delete(kr.id);
                        return new Set(ids);
                      });
                    }
                  } else {
                    setAllOpenObjectiveList((ids) => {
                      ids.add(objectiveId);
                      return new Set(ids);
                    });
                    for (const kr of sortedKeyResults) {
                      openKrReivew(kr.id);
                    }
                  }
                }}
              >
                {allOpenObjectiveList.has(objectiveId)
                  ? "折叠全部"
                  : "展开全部"}
              </Button>
              {!readOnly && (
                <Button
                  variant="text"
                  size="small"
                  startIcon={<AddIcon />}
                  disabled={
                    (creatingKeyResultObjectiveIds.has(objectiveId) &&
                      createKeyResultLoading) ||
                    weights?.id === objectiveId
                  }
                  onClick={() => {
                    setCreatingKeyResultObjectiveIds((prev) => {
                      prev.add(objectiveId);
                      return prev;
                    });
                    createOneKeyResult({
                      variables: {
                        data: {
                          description: "",
                          progress: 0,
                          weight: 0,
                          status: Status.Normal,
                          objective: {
                            connect: {
                              id: objectiveId,
                            },
                          },
                          order: sortedKeyResults.length + 1,
                        },
                      },
                    }).finally(() => {
                      setCreatingKeyResultObjectiveIds((prev) => {
                        prev.delete(objectiveId);
                        return prev;
                      });
                    });
                  }}
                >
                  {creatingKeyResultObjectiveIds.has(objectiveId) &&
                  createKeyResultLoading
                    ? "loading..."
                    : "添加 Key Result"}
                </Button>
              )}
            </ListItem>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default KeyResultList;
