export function floor(number: number, prec: number) {
  var tempnumber = number * Math.pow(10, prec);
  tempnumber = Math.floor(tempnumber);
  return tempnumber / Math.pow(10, prec);
}

export function getWeights(
  weights: { id: string; weight: number }[]
): {
  weights: { id: string; weight: number }[];
  newWeight: number;
} {
  if (weights.length === 0) {
    return {
      weights: [],
      newWeight: 100,
    };
  }
  let isAvgMode = true;
  for (const { weight } of weights) {
    if (weight !== weights[0].weight) {
      isAvgMode = false;
    }
  }
  if (!isAvgMode) {
    return {
      weights,
      newWeight: 0,
    };
  }

  const avgWeight = floor(100 / (weights.length + 1), 2);
  return {
    weights: weights.map((w) => ({ id: w.id, weight: avgWeight })),
    newWeight: avgWeight,
  };
}

export function calcObjectiveProgress(
  keyResults: { progress?: number | null; weight?: number | null }[]
): number {
  let objectiveProgress = 0;
  let totalWeight = 0;
  for (const kr of keyResults) {
    objectiveProgress += ((kr.progress || 0) * (kr.weight || 0)) / 100;
    totalWeight += kr.weight || 0;
  }
  // if weight is missing, let first KR take the missing part
  if (totalWeight && keyResults.length > 0) {
    const firstKr = keyResults[0];
    const missingWeight = 100 - totalWeight;
    objectiveProgress += ((firstKr.progress || 0) * missingWeight) / 100;
  }
  return objectiveProgress;
}
