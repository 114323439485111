import React from "react";
import { StringParam, useQueryParam } from "use-query-params";
import { ENDPOINT, GRAPHQL_ENDPOINT } from "../../constants";

const WeChatVerified = () => {
  const [wxCode] = useQueryParam("code", StringParam);

  fetch(`${GRAPHQL_ENDPOINT}/qy-auth?code=${wxCode}`)
    .then((r) => r.json())
    .then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        const token = data.token;
        if (token) {
          localStorage.setItem("token", token);
          window.location.href = ENDPOINT;
        }
      }
    });

  return <></>;
};

export default WeChatVerified;
