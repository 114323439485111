import { convertFromRaw, ContentState } from "draft-js";

export function convertFromRawStr(rawStr: string): ContentState {
  try {
    return convertFromRaw(JSON.parse(rawStr));
  } catch (error) {
    return ContentState.createFromText(rawStr);
  }
}

export function rawStrToPlainText(rawStr: string): string {
  return convertFromRawStr(rawStr).getPlainText();
}
