import React, { useEffect, useRef } from "react";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import backgroundImage from "../../assets/signin.svg";
import logo from "../../assets/logo.png";
import { ENDPOINT, GRAPHQL_ENDPOINT } from "../../constants";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href={ENDPOINT}>
        SmartX
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: `url("${backgroundImage}")`,
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "contain",
    backgroundPosition: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    padding: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
  },
  logo: {
    margin: theme.spacing(1),
    width: 160,
    height: 160,
  },
  iframe: {
    width: "100%",
    flex: 1,
    border: "none",
  },
}));

function handleMessage(evt: MessageEvent) {
  if (evt.origin.includes("work.weixin.qq.com")) {
    window.location.href = evt.data;
  }
}

const Login: React.FC = () => {
  const classes = useStyles();

  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    if (iframeRef.current) {
      iframeRef.current.onload = () => {
        iframeRef.current?.contentWindow?.postMessage(
          "ask_usePostMessage",
          "*"
        );
      };
    }
    window.addEventListener("message", handleMessage);
    return () => window.removeEventListener("message", handleMessage);
  }, []);

  const toUrl =
    process.env.NODE_ENV === "production"
      ? ""
      : `${window.location.origin}${window.location.pathname}`;
  console.log({ toUrl });

  return (
    <Grid container component="main" className={classes.root}>
      <Grid item xs={false} sm={4} md={7} className={classes.image}></Grid>
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <img src={logo} alt="logo" className={classes.logo} />
          <iframe
            src={`${GRAPHQL_ENDPOINT}/get-qr-connect?to=${toUrl}`}
            title="login"
            className={classes.iframe}
            ref={iframeRef}
            width={300}
            height={400}
          />
          <Box mt={5}>
            <Copyright />
          </Box>
        </div>
      </Grid>
    </Grid>
  );
};

export default Login;
