import { Box, Button, makeStyles, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import React, { useState } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import EditableText from "../../components/EditableText";
import {
  OkrsQuery,
  Status,
  useCreateOneObjectiveMutation,
  useReorderObjectivesMutation,
  useUpdateOneOkrMutation,
} from "../../generated/graphql";
import { LocalComment } from "./CommentList";
import ObjectiveList from "./ObjectiveList";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: "6px 10px",
    borderRadius: "6px",
    minWidth: "100px",
    width: "fit-content",
    transition: "0.3s",
    "&:hover": {
      background: "#cfcfcf45",
    },
    "&:focus-within": {
      border: "2px solid #0072E5",
      background: "#c2c2c250",
    },
  },
}));
interface ObjectiveWrapperProps {
  readOnly: boolean;
  okr: OkrsQuery["okrs"][0];
  setLocalComment: React.Dispatch<React.SetStateAction<LocalComment | null>>;
}

const ObjectiveWrapper = (props: ObjectiveWrapperProps) => {
  const { readOnly, okr, setLocalComment } = props;
  const classes = useStyles();
  const [objectiveLocalOrders, setObjectiveLocalOrders] = useState<
    { id: string }[] | null
  >(null);

  const [updateOneOkr] = useUpdateOneOkrMutation();
  const [reorderObjectives] = useReorderObjectivesMutation();
  const [
    createOneObjective,
    { loading: createObjectiveLoading },
  ] = useCreateOneObjectiveMutation({
    refetchQueries: ["okrs"],
  });

  const sortedObjectives = okr.objectives.slice().sort((a, b) => {
    // use local order
    if (objectiveLocalOrders) {
      return (
        objectiveLocalOrders.findIndex((v) => v.id === a.id) -
        objectiveLocalOrders.findIndex((v) => v.id === b.id)
      );
    }
    // use real order
    if (a.order && b.order) {
      return a.order - b.order;
    }
    return 1;
  });

  return (
    <Box p={2} key={okr.id}>
      <Typography variant="h5">
        <div className={classes.wrapper}>
          <EditableText
            readOnly={readOnly}
            rawStr={okr.name || ""}
            onBlur={(raw) => {
              updateOneOkr({
                variables: {
                  data: {
                    name: JSON.stringify(raw),
                  },
                  where: {
                    id: okr.id,
                  },
                },
              });
            }}
          />
        </div>
      </Typography>
      <DragDropContext
        onDragEnd={({ source, destination }) => {
          const ids = sortedObjectives.map((objective) => ({
            id: objective.id || "",
          }));
          const [moved] = ids.splice(source.index, 1);
          ids.splice(destination?.index ?? ids.length - 1, 0, moved);
          setObjectiveLocalOrders(ids);
          reorderObjectives({
            variables: {
              where: {
                id: okr.id,
              },
              data: {
                orders: ids,
              },
            },
          }).finally(() => setObjectiveLocalOrders(null));
        }}
      >
        <ObjectiveList
          readOnly={readOnly}
          okr={okr}
          sortedObjectives={sortedObjectives}
          setLocalComment={setLocalComment}
        />
      </DragDropContext>
      {!readOnly && (
        <Box mt={2}>
          <Button
            disabled={createObjectiveLoading}
            variant="outlined"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => {
              createOneObjective({
                variables: {
                  data: {
                    description: "",
                    status: Status.Auto,
                    review: {
                      description: "",
                    },
                    order: sortedObjectives.length + 1,
                    okr: {
                      connect: {
                        id: okr.id,
                      },
                    },
                  },
                },
              });
            }}
          >
            {createObjectiveLoading ? "loading..." : "添加 Objective"}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default ObjectiveWrapper;
