import React, { createContext, useContext, useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import { MyQuery, MyDocument } from "../generated/graphql";
import { client } from "../graphql";
import { makeStyles } from "@material-ui/core";

type SigninParams = {
  token: string;
};

type User = MyQuery["my"];

const authCore = {
  async getUser(): Promise<User> {
    const result = await client.query<MyQuery>({
      query: MyDocument,
    });
    return result.data.my;
  },
  async signin(params: SigninParams): Promise<string> {
    return params.token;
  },
  async signout() {
    localStorage.removeItem("token");
  },
};

const AuthContext = createContext<{
  user: User | null;
  loading: boolean;
  error: Error | null;
  signin: (params: SigninParams) => Promise<void>;
  signout: () => void;
  getUser: () => Promise<void>;
}>({
  user: null,
  loading: false,
  error: null,
  signin: () => Promise.resolve(),
  signout: () => {},
  getUser: () => Promise.resolve(),
});

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const auth = useProvideAuth();

  useEffect(() => {
    auth.getUser();
    // only trigger once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  return useContext(AuthContext);
}

function useProvideAuth() {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  const getUser = async () => {
    setError(null);
    setLoading(true);
    try {
      const user = await authCore.getUser();
      setUser(user);
    } catch (error) {
      console.log(error);
      setError(error);
      setUser(null);
    } finally {
      setLoading(false);
    }
  };

  const signin = async (params: SigninParams) => {
    setError(null);
    try {
      const token = await authCore.signin(params);
      localStorage.setItem("token", token);
      await getUser();
    } catch (error) {
      console.log(error);
      setError(error);
    }
  };

  const signout = () => {
    setUser(null);
    return authCore.signout();
  };

  return {
    user,
    loading,
    error,
    signin,
    signout,
    getUser,
  };
}

const useStyles = makeStyles(() => ({
  skeleton: {
    width: "100%",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export const RouteGuard: React.FC = ({ children, ...rest }) => {
  const classes = useStyles();
  const { user, loading, error } = useAuth();

  return (
    <Route
      {...rest}
      render={({ location }) => {
        const isLogin = location.pathname === "/login";
        if (error && !isLogin) {
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location },
              }}
            />
          );
        }
        if (loading) {
          return (
            <div className={classes.skeleton}>
              <CircularProgress />
            </div>
          );
        }
        if (!user && !isLogin) {
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location },
              }}
            />
          );
        }
        if (user && isLogin) {
          return (
            <Redirect
              to={{
                pathname: "/",
              }}
            />
          );
        }
        return children;
      }}
    />
  );
};
