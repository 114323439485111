import { Avatar, Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import {
  bindHover,
  bindMenu,
  usePopupState,
} from "material-ui-popup-state/hooks";
import Menu from "material-ui-popup-state/HoverMenu";
import React from "react";
import Dot from "../../components/Dot";
import EditableText from "../../components/EditableText";
import {
  AlignedObjectivesQuery,
  useRemoveAlignmentFromOneObjectiveMutation,
} from "../../generated/graphql";
import { calcObjectiveProgress, floor } from "../okr/math";

type Objective = AlignedObjectivesQuery["objectives"][0];

const AlignedObjectiveMenu: React.FC<{
  readOnly: boolean;
  srcObjectiveId: string | null;
  objectives: Objective[];
  onSelectUser: (
    user: Exclude<Objective["okr"], undefined | null>["author"]
  ) => void;
}> = ({ children, readOnly, objectives, srcObjectiveId, onSelectUser }) => {
  const popupState = usePopupState({
    variant: "popover",
    popupId: "alignedObjectiveMenu",
  });
  const [
    removeAlignmentFromObjective,
  ] = useRemoveAlignmentFromOneObjectiveMutation({
    refetchQueries: ["alignedObjectives"],
  });

  return (
    <React.Fragment>
      <Button size="small" {...bindHover(popupState)}>
        {children}
      </Button>
      <Menu
        {...bindMenu(popupState)}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: 10, horizontal: "left" }}
        PaperProps={{
          style: {
            width: 500,
            pointerEvents: "auto",
          },
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          p={1}
        >
          <Box display="flex" alignItems="center">
            <Avatar src={objectives[0]?.okr?.author?.avatar || ""} />
            <Box ml={1}>{objectives[0]?.okr?.author?.name}</Box>
          </Box>
          <Button
            color="primary"
            onClick={() => onSelectUser(objectives[0]?.okr?.author)}
          >
            查看
          </Button>
        </Box>
        {objectives.map((objective) => {
          const sortedKeyResults = objective.keyResults.slice().sort((a, b) => {
            if (a.order && b.order) {
              return a.order - b.order;
            }
            return 1;
          });
          return [
            <Divider key={`divider-${objective.id}`} />,
            <Box
              key={`remove-${objective.id}`}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              p={1}
            >
              <Box
                flex="1"
                display="flex"
                alignItems="center"
                justifyContent="between"
              >
                <EditableText
                  readOnly
                  rawStr={objective.description || ""}
                ></EditableText>
                <Box ml={1}>
                  <Typography variant="caption">
                    {floor(calcObjectiveProgress(objective.keyResults), 1)}%
                  </Typography>
                </Box>
              </Box>
              {!readOnly && srcObjectiveId && (
                <Button
                  color="primary"
                  onClick={() =>
                    removeAlignmentFromObjective({
                      variables: {
                        where: {
                          id: srcObjectiveId,
                        },
                        data: {
                          disconnect: {
                            id: objective.id,
                          },
                        },
                      },
                    })
                  }
                >
                  取消对齐
                </Button>
              )}
            </Box>,
            ...sortedKeyResults.map((kr) => {
              return (
                <Box
                  key={kr.id}
                  p={1}
                  display="flex"
                  alignItems="center"
                  justifyContent="between"
                >
                  <Box display="flex" flex="1" alignItems="center">
                    <Box>
                      <Dot />
                    </Box>
                    <EditableText readOnly rawStr={kr.description || ""} />
                  </Box>
                  <Box ml={1}>
                    <Typography variant="caption">
                      {floor(kr.progress || 0, 1)}%
                    </Typography>
                  </Box>
                </Box>
              );
            }),
          ];
        })}
      </Menu>
    </React.Fragment>
  );
};

export default AlignedObjectiveMenu;
