import { Box, Button, Paper, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import React, { useState } from "react";
import { StringParam, useQueryParam } from "use-query-params";
import { useCreateOneOkrMutation } from "../../generated/graphql";

const useStyles = makeStyles((theme) => ({
  newOkr: {
    width: 300,
    margin: "auto",
  },
}));

const Empty = (props: { readOnly: boolean }) => {
  const classes = useStyles();
  const [periodId] = useQueryParam("period", StringParam);
  const { readOnly } = props;
  const [newOkrTitle, setNewOkrTitle] = useState("");

  const [createOneOkr, { loading: creatingNewOkr }] = useCreateOneOkrMutation({
    refetchQueries: ["okrs", "okrsForCopy"],
  });

  return (
    <Paper className={classes.newOkr}>
      {readOnly && (
        <Box p={2}>
          <Typography align="center">暂无 OKR</Typography>
        </Box>
      )}
      {!readOnly && (
        <Box p={2} display="flex" flexDirection="column" alignItems="center">
          <Box mb={2} width="100%">
            <TextField
              label="OKR 标题"
              variant="outlined"
              fullWidth
              size="small"
              value={newOkrTitle}
              onChange={(evt) => setNewOkrTitle(evt.currentTarget.value)}
            />
          </Box>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<AddIcon />}
            disabled={!newOkrTitle || creatingNewOkr}
            onClick={() =>
              createOneOkr({
                variables: {
                  data: {
                    name: newOkrTitle,
                    period: {
                      connect: {
                        id: periodId,
                      },
                    },
                  },
                },
              })
            }
          >
            创建
          </Button>
        </Box>
      )}
    </Paper>
  );
};

export default Empty;
