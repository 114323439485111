import { createMuiTheme } from "@material-ui/core/styles";
import shadows, { Shadows } from "@material-ui/core/styles/shadows";

const customizedShadows: Shadows = [...shadows];
customizedShadows[1] = "0px 3px 6px rgba(0, 0, 0, 0.12)";
customizedShadows[2] = "1px 1px 8px rgba(0, 0, 0, 0.1);";

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#646BD9",
    },
    secondary: {
      main: "#726DA8",
    },
    success: {
      main: "#4FAB2F",
    },
    warning: {
      main: "#F2BE03",
    },
    error: {
      main: "#D96464",
    },
    grey: {
      100: "#474747",
      200: "#5C5C5C",
      300: "#7B7F82",
      400: "#BDBDBD",
      500: "#E0E0E0",
      600: "#FAFAFA",
    },
    text: {
      primary: "#212121",
      secondary: "#474747",
    },
    background: {
      default: "#fff",
    },
  },
  shadows: customizedShadows,
  typography: {
    button: {
      textTransform: "none",
    },
  },
});

export default theme;
