import React, { useEffect } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";

const useStyles = makeStyles(() => ({
  skeleton: {
    width: "100%",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default function Redirect() {
  const classes = useStyles();

  const location = useLocation();
  const history = useHistory();
  useEffect(() => {
    const token = new URLSearchParams(location.search).get("token");
    if (token) {
      localStorage.setItem("token", token);
      return history.replace("/");
    }
    const tokenInLocalStorage = localStorage.getItem("token");
    if (tokenInLocalStorage) {
      return history.replace("/login");
    }
  }, [location.search, history]);

  return (
    <div className={classes.skeleton}>
      <CircularProgress />
    </div>
  );
}
