import { Box, Typography } from "@material-ui/core";
import React from "react";
import { StringParam, useQueryParam } from "use-query-params";
import {
  AlignedObjectivesQuery,
  useAlignedObjectivesQuery,
} from "../../generated/graphql";
import AlignedObjectiveMenu from "../objective/AlignedObjectiveMenu";
import ObjectiveSelect from "../objective/ObjectiveSelect";

interface AlignedToOkrsProps {
  type: "ALIGNED_TO" | "ALIGNED_WITH";
  baseObjectiveId: string;
  readOnly: boolean;
}

const AlignedToOkrs = (props: AlignedToOkrsProps) => {
  const { type, baseObjectiveId, readOnly } = props;
  const [periodId] = useQueryParam("period", StringParam);
  const [, setAuthorId] = useQueryParam("author", StringParam);

  const { data, loading, error } = useAlignedObjectivesQuery({
    variables: {
      where:
        type === "ALIGNED_TO"
          ? {
              alignedWith: { some: { id: { equals: baseObjectiveId } } },
            }
          : { alignedTo: { some: { id: { equals: baseObjectiveId } } } },
    },
    fetchPolicy: "cache-and-network",
    pollInterval: 60_000,
  });

  if (error) {
    return <p>{error.message}</p>;
  }

  const objectives = data?.objectives || [];

  return (
    <Box display="flex" alignItems="center">
      <Typography variant="caption">
        {type === "ALIGNED_TO" ? "已对齐" : "被对齐"}：
        {type === "ALIGNED_WITH" && !objectives.length ? "暂无" : ""}
      </Typography>
      {Object.values(
        objectives.reduce<Record<string, AlignedObjectivesQuery["objectives"]>>(
          (prev, cur) => {
            if (!prev[cur.okr?.author?.id!]) {
              prev[cur.okr?.author?.id!] = [];
            }
            prev[cur.okr?.author?.id!].push(cur);
            return prev;
          },
          {}
        )
      ).map((alignedObjectives) => {
        return (
          <Box key={alignedObjectives[0].id} mr={1}>
            <AlignedObjectiveMenu
              readOnly={readOnly}
              objectives={alignedObjectives}
              srcObjectiveId={
                type === "ALIGNED_TO" ? baseObjectiveId || "" : null
              }
              onSelectUser={(targetAuthor) => setAuthorId(targetAuthor?.id)}
            >
              <Typography variant="caption">
                {alignedObjectives[0]?.okr?.author?.name}
                {alignedObjectives.length > 1
                  ? `(${alignedObjectives.length})`
                  : ""}
              </Typography>
            </AlignedObjectiveMenu>
          </Box>
        );
      })}
      {!readOnly && type === "ALIGNED_TO" && (
        <ObjectiveSelect
          periodId={periodId || ""}
          srcObjectiveId={baseObjectiveId || ""}
          alignedIds={objectives.map((o) => o.id).filter(Boolean) as string[]}
        />
      )}
    </Box>
  );
};

export default AlignedToOkrs;
