import React, { useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  MenuItem,
  Menu,
  Box,
  Button,
  Typography,
  Avatar,
  TextField,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import {
  useObjectivesByUserLazyQuery,
  ObjectivesByUserQuery,
  useAlignToOneObjectiveMutation,
} from "../../generated/graphql";
import EditableText from "../../components/EditableText";
import { useAuth } from "../../lib/auth";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuItem: {
      width: 300,
    },
    userRow: {
      background: theme.palette.grey[600],
    },
    avatar: {
      width: 32,
      height: 32,
      marginRight: theme.spacing(2),
    },
  })
);

const ObjectiveSelect: React.FC<{
  periodId: string;
  srcObjectiveId: string;
  alignedIds: string[];
}> = ({ periodId, srcObjectiveId, alignedIds }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [search, setSearch] = useState("");
  const { user } = useAuth();
  const [trigger, { data, loading, error }] = useObjectivesByUserLazyQuery({
    variables: {
      where: {
        AND: [
          { id: { not: { equals: user?.id } } },
          {
            OR: [
              { name: { contains: search } },
              { email: { contains: search } },
              { pinyinName: { contains: search } },
            ],
          },
        ],
      },
      periodWhere: {
        id: {
          equals: periodId,
        },
      },
      alignedIds,
    },
  });
  const [alignToOneObjective] = useAlignToOneObjectiveMutation({
    refetchQueries: ["alignedObjectives"],
  });

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    trigger();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderMenu = () => {
    if (error) {
      return <Typography color="error">{error}</Typography>;
    }
    if (loading) {
      return <MenuItem>Loading...</MenuItem>;
    }
    return (data?.users || []).map((user) => {
      const noObjective = user.okrs.every((okr) => okr.objectives.length === 0);
      return (
        <Box key={user.id}>
          <Box
            display="flex"
            alignItems="center"
            className={classes.userRow}
            p={1}
          >
            <Avatar src={user.avatar || ""} className={classes.avatar}></Avatar>
            <Typography>{user.name}</Typography>
          </Box>
          {noObjective && <Box p={1}>无可对齐的 Objective</Box>}
          {user.okrs
            .reduce<
              Array<
                ObjectivesByUserQuery["users"][0]["okrs"][0]["objectives"][0]
              >
            >((prev, cur) => prev.concat(cur.objectives), [])
            .map((objective) => (
              <MenuItem
                key={objective.id}
                onClick={() => {
                  alignToOneObjective({
                    variables: {
                      where: {
                        id: srcObjectiveId,
                      },
                      data: {
                        connect: {
                          id: objective.id,
                        },
                      },
                    },
                  }).then(() => handleClose());
                }}
              >
                <Box padding={1}>
                  <EditableText rawStr={objective.description || ""} readOnly />
                </Box>
              </MenuItem>
            ))}
        </Box>
      );
    });
  };

  return (
    <Box>
      <Button
        aria-controls="objective-menu"
        aria-haspopup="true"
        onClick={handleClick}
        startIcon={<AddIcon />}
        size="small"
      >
        添加对齐
      </Button>
      <Menu
        id="objective-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 300,
            width: 300,
          },
        }}
      >
        <Box padding={1}>
          <TextField
            value={search}
            onChange={(evt) => setSearch(evt.currentTarget.value)}
            variant="outlined"
            size="small"
            fullWidth
            placeholder="请搜索用户名或邮箱"
          />
        </Box>
        {renderMenu()}
      </Menu>
    </Box>
  );
};

export default ObjectiveSelect;
