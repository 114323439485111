import React from "react";
import {
  HashRouter as Router,
  Switch,
  Route,
  Redirect as RouterRedirect,
} from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { AuthProvider, RouteGuard } from "./lib/auth";
import Login from "./modules/user/Login";
import Redirect from "./modules/user/Redirect";
import Main from "./modules/dashboard/Main";
import OkrList from "./modules/okr/OkrList";
import OkrTree from "./modules/okr/OkrTree";
import WeChatVerified from "./modules/user/WeChatVerified";

export default function App() {
  return (
    <AuthProvider>
      <Router>
        <QueryParamProvider ReactRouterRoute={Route}>
          <Switch>
            <Route path="/redirect">
              <Redirect />
            </Route>
            <Route exact path="/wx-verified" component={WeChatVerified} />
            <RouteGuard>
              <Route path="/login">
                <Login />
              </Route>
              <Route path="/" exact>
                <RouterRedirect from="/" to="/okr" exact />
              </Route>
              <Route path="/okr">
                <Main>
                  <OkrList />
                </Main>
              </Route>
              <Route path="/alignment">
                <Main>
                  <OkrTree />
                </Main>
              </Route>
            </RouteGuard>
          </Switch>
        </QueryParamProvider>
      </Router>
    </AuthProvider>
  );
}
