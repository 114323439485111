import _ from "lodash";

export const objectToUrlQueryString = (
  obj?: Record<string, string | null | undefined>
): string => {
  if (!obj) return "";
  const newObj = _.pickBy(
    obj,
    (value) => value !== undefined && value !== null
  );
  const searchParams = new URLSearchParams(
    newObj as Record<string, string>
  ).toString();
  return searchParams.length ? `?${searchParams}` : "";
};
