import { IconButton, IconButtonProps } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import RateReviewIcon from "@material-ui/icons/RateReviewOutlined";
import React from "react";

const useStyles = makeStyles((theme) => ({
  actionIcon: {
    color: theme.palette.text.hint,
    "&:hover": {
      color: theme.palette.error.main,
    },
  },
}));

const ReviewButton: React.FC<IconButtonProps> = (props) => {
  const classes = useStyles();

  return (
    <IconButton
      className={classes.actionIcon}
      {...props}
      onClick={(evt) => {
        props.onClick?.(evt);
      }}
    >
      <RateReviewIcon color="action" />
    </IconButton>
  );
};

export default ReviewButton;
