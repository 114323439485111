export type CommentsList<T> = {
  value: T;
  next?: CommentsList<T>;
};

export function resolveComments<
  T extends { id: string; previousId?: string | null; solvedAt?: string | null }
>(comments: T[]): { solved: boolean; comments: T[] }[] {
  const indexMap: Map<string, CommentsList<T>> = new Map();
  const lists: CommentsList<T>[] = [];

  const resolve = (value: T) => {
    if (!value.previousId) {
      const list = { value };
      indexMap.set(value.id, list);
      lists.push(list);
      return;
    }
    const previous = indexMap.get(value.previousId);
    if (!previous) {
      console.error(`Found dangling comment "${JSON.stringify(value)}"`);
      return;
    }
    const list = {
      value,
    };
    previous.next = list;
    indexMap.set(value.id, list);
  };

  comments.forEach(resolve);

  return lists.map((root) => {
    const arr = [root.value];
    let cursor = root.next;
    while (cursor) {
      arr.push(cursor.value);
      cursor = cursor.next;
    }
    return {
      solved: !!root.value.solvedAt,
      comments: arr,
    };
  });
}
