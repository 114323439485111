import React, { useEffect } from "react";
import { Link as RouterLink, useLocation, useHistory } from "react-router-dom";
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles,
  ListItem,
} from "@material-ui/core";
import { useAuth } from "../../lib/auth";
import { useGetUserGlanceOverHistoriesQuery } from "../../generated/graphql";
import { objectToUrlQueryString } from "../../shared/utils";

export const NAV_WIDTH = 200;

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: NAV_WIDTH,
  },
  desktopDrawer: {
    width: NAV_WIDTH,
    top: 64,
    height: "calc(100% - 64px)",
  },
  userItem: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    borderRadius: "5px",
    "&:hover": {
      cursor: "pointer",
      background: theme.palette.grey[600],
    },
  },
  avatar: {
    width: 64,
    height: 64,
    marginBottom: 16,
  },
  item: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
  },
  historyAvatar: {
    width: 26,
    height: 26,
    marginRight: theme.spacing(2),
  },
  historyName: {
    margin: "0 6px",
  },
  historyDate: {
    margin: "0 6px",
    color: "#bbb",
    fontSize: "0.8rem",
  },
}));

const NavBar: React.FC<{
  onMobileClose: () => void;
  openMobile: boolean;
}> = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const { user } = useAuth();

  const { data } = useGetUserGlanceOverHistoriesQuery({
    variables: {
      where: {
        pathModule: { equals: location.pathname },
      },
      take: 10,
    },
    fetchPolicy: "cache-and-network",
  });
  const glanceOverHistories = data?.glanceOverHistories || [];

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box alignItems="center" display="flex" flexDirection="column" p={2}>
        <RouterLink to="/">
          <Avatar className={classes.avatar} src={user?.avatar || ""} />
        </RouterLink>
        <Typography color="textPrimary" variant="h6">
          {user?.name}
        </Typography>
      </Box>
      <Divider />
      <Box p={2}>
        <Typography>最近浏览</Typography>
        <List>
          {/* 只展示前面10条 */}
          {glanceOverHistories.map((item, index) => (
            <ListItem
              className={classes.item}
              disableGutters
              key={`history-${index}`}
            >
              <Box
                className={classes.userItem}
                p={1}
                onClick={() => {
                  history.push(
                    item.pathModule +
                      objectToUrlQueryString({
                        author: item.okrAuthor.id,
                        period: item.okrPeriodId,
                      })
                  );
                }}
              >
                <Avatar
                  src={item.okrAuthor.avatar || ""}
                  className={classes.historyAvatar}
                ></Avatar>
                <Typography>
                  <p className={classes.historyName}>{item.okrAuthor.name}</p>
                  <p className={classes.historyDate}>{item.okrPeriod.name}</p>
                </Typography>
              </Box>
            </ListItem>
          ))}
        </List>
      </Box>
      <Box flexGrow={1} />
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

export default NavBar;
