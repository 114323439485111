import React from "react";
import { Link as RouterLink, NavLink } from "react-router-dom";
import clsx from "clsx";
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  Button,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import InputIcon from "@material-ui/icons/Input";
import logo from "../../assets/logo.png";
import { useAuth } from "../../lib/auth";
import { useQueryParam, StringParam } from "use-query-params";
import { objectToUrlQueryString } from "../../shared/utils";

const useStyles = makeStyles(() => ({
  root: {},
  logoWrapper: {
    width: 42,
    height: 42,
    borderRadius: 20,
    background: "#fff",
  },
  logo: {
    width: 42,
    height: 42,
  },
}));

const items = [
  {
    href: "/okr",
    title: "OKR",
  },
  {
    href: "/alignment",
    title: "对齐视图",
  },
];

const useItemStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.grey[400],
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: "flex-start",
    letterSpacing: 0,
    padding: "10px 8px",
    textTransform: "none",
    width: "100%",
  },
  title: {
    marginRight: "auto",
  },
  active: {
    color: "#fff",
    "& $title": {
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
}));

const NavItem: React.FC<{
  href: string;
  query?: Record<string, string | null | undefined>;
  title: string;
}> = ({ href, query, title, ...rest }) => {
  const classes = useItemStyles();
  return (
    <Box {...rest}>
      <Button
        className={classes.button}
        component={NavLink}
        to={href + objectToUrlQueryString(query)}
        activeClassName={classes.active}
      >
        <span className={classes.title}>{title}</span>
      </Button>
    </Box>
  );
};

const TopBar: React.FC<{ className?: string; onMobileNavOpen: () => void }> = ({
  className,
  onMobileNavOpen,
  ...rest
}) => {
  const classes = useStyles();
  const { signout } = useAuth();
  const [period] = useQueryParam("period", StringParam);
  const [author] = useQueryParam("author", StringParam);
  return (
    <AppBar className={clsx(classes.root, className)} elevation={0} {...rest}>
      <Toolbar>
        <RouterLink to="/">
          <div className={classes.logoWrapper}>
            <img className={classes.logo} src={logo} alt="logo" />
          </div>
        </RouterLink>
        <Box display="flex" alignItems="center" ml={4}>
          {items.map((item) => (
            <NavItem
              href={item.href}
              query={{ period, author }}
              key={item.title}
              title={item.title}
            />
          ))}
        </Box>
        <Box flexGrow={1} />
        <Hidden mdDown>
          <IconButton color="inherit" onClick={signout}>
            <InputIcon />
          </IconButton>
        </Hidden>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onMobileNavOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
