import React, { useRef, useState } from "react";
import { Box, makeStyles, TextField, TextFieldProps } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    transition: "0.3s",
    "&:hover": {
      background: theme.palette.grey[500],
    },
  },
}));

const EditableTextField: React.FC<
  TextFieldProps & { validate?: (value: string) => string | null }
> = ({ children, value: defaultValue, validate, onChange, ...rest }) => {
  const classes = useStyles();
  const [active, setActive] = useState(false);
  const [value, setValue] = useState(defaultValue as string);
  const [error, setError] = useState<string | null>(null);
  const fieldRef = useRef<HTMLDivElement>(null);

  return (
    <Box
      className={classes.wrapper}
      onClick={() => {
        setActive(true);
      }}
      onBlur={() => {
        setActive(false);
      }}
    >
      {active && (
        <TextField
          autoFocus
          value={value}
          onChange={(e) => setValue(e.currentTarget.value)}
          onBlur={(e) => {
            const error = validate ? validate(value) : null;
            if (error) {
              e.stopPropagation();
              return setError(error);
            }
            setError(null);
            onChange?.(e);
          }}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              // setActive(false);
            }
          }}
          ref={fieldRef}
          error={Boolean(error)}
          helperText={error}
          {...rest}
        />
      )}
      {!active && <Box paddingY={1}>{children}</Box>}
    </Box>
  );
};

export default EditableTextField;
