import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Status } from "../generated/graphql";

const useDotStyles = makeStyles((theme) => ({
  dot: {
    width: 8,
    height: 8,
    borderRadius: 4,
    background: theme.palette.primary.main,
    marginRight: theme.spacing(2),
  },
}));

const Dot: React.FC<{ status?: Status | null }> = ({ status }) => {
  const classes = useDotStyles();
  return <div className={classes.dot}></div>;
};

export default Dot;
