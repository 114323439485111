import { Avatar, makeStyles, Tooltip } from "@material-ui/core";
import GroupIcon from "@material-ui/icons/Group";
import React from "react";
import { StringParam, useQueryParam } from "use-query-params";
import { usePingQuery } from "../../generated/graphql";
import { useAuth } from "../../lib/auth";

const useStyles = makeStyles(() => ({
  wrapper: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    marginRight: "10px",
  },
  avatar: {
    "& + &": {
      marginLeft: "-18px",
    },
  },
}));

const RealtimeUsers = () => {
  const [periodId] = useQueryParam("period", StringParam);
  const [authorId] = useQueryParam("author", StringParam);
  const { user } = useAuth();
  const path = window.location.hash.split("?")[0];

  const classes = useStyles();

  const { data } = usePingQuery({
    variables: { currentPage: `${path}/${periodId}/${authorId || user?.id}` },
    fetchPolicy: "no-cache",
    pollInterval: 5_000,
  });

  const users = data?.ping?.currenPageUsers || [];
  const displayedUsers = users.slice(0, 5);
  const restUsers = users.slice(5);
  return (
    <div className={classes.wrapper}>
      {displayedUsers.map((u) => (
        <Tooltip title={u.name} key={u.id}>
          <Avatar className={classes.avatar} id={u.id} src={u.avatar} />
        </Tooltip>
      ))}
      {restUsers.length ? (
        <Tooltip title={restUsers.map((u) => u.name).join("、")}>
          <Avatar className={classes.avatar} id="others">
            <GroupIcon />
          </Avatar>
        </Tooltip>
      ) : (
        ""
      )}
    </div>
  );
};

export default RealtimeUsers;
