import React, { useState, useRef } from "react";
import {
  Button,
  Dialog,
  TextField,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
} from "@material-ui/core";

interface AddLinkDialogProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmit: (link: string) => void;
  link: string;
  setLink: React.Dispatch<React.SetStateAction<string>>;
}
const AddLinkDialog = (props: AddLinkDialogProps) => {
  const { open, setOpen, handleSubmit, link, setLink } = props;

  const inputRef = useRef<HTMLDivElement>(null);
  const [linkErrorMsg, setLinkErrorMsg] = useState("");

  const resetState = () => {
    setOpen(false);
    setLink("");
    setLinkErrorMsg("");
  };

  const onSubmit = () => {
    if (
      link &&
      !/(http|ftp|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:\/~+#-]*[\w@?^=%&amp;\/~+#-])?/.test(
        link
      )
    ) {
      setLinkErrorMsg("请输入正确的链接");
      return;
    }
    handleSubmit(link);
    resetState();
  };

  const onCancelLink = () => {
    handleSubmit("");
    resetState();
  };

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={resetState}
      onClick={(e) => e.stopPropagation()}
    >
      <DialogTitle>添加链接</DialogTitle>
      <DialogContent>
        <TextField
          ref={inputRef}
          fullWidth
          autoFocus
          error={!!linkErrorMsg}
          helperText={linkErrorMsg}
          placeholder="请输入链接"
          value={link}
          onChange={(e) => {
            e.stopPropagation();
            setLinkErrorMsg("");
            setLink(e.currentTarget.value);
          }}
        ></TextField>
      </DialogContent>
      <DialogActions style={{ padding: "8px 20px" }}>
        <Box flexGrow={1} display="flex" justifyContent="space-between">
          <Box>
            {link && (
              <Button color="primary" onClick={onCancelLink}>
                取消链接
              </Button>
            )}
          </Box>
          <Box>
            <Button onClick={resetState}>取消</Button>
            <Button
              onClick={(e) => {
                e.preventDefault();
                onSubmit();
              }}
            >
              确认
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default AddLinkDialog;
