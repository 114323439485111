import React from "react";
import {
  makeStyles,
  Box,
  IconButton,
  Typography,
  BoxProps,
} from "@material-ui/core";
import DeleteButton from "../../components/DeleteButton";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import RedoIcon from "@material-ui/icons/Redo";
import {
  CommentsQuery,
  useDeleteOneCommentMutation,
  useSolveOneCommentMutation,
  useReopenOneCommentMutation,
} from "../../generated/graphql";

const useStyles = makeStyles((theme) => ({
  resolveIcon: {
    color: theme.palette.text.hint,
    "&:hover": {
      color: theme.palette.success.main,
    },
  },
}));

const CommentToolbar: React.FC<
  { comment: CommentsQuery["comments"][0] } & BoxProps
> = ({ comment, ...boxProps }) => {
  const classes = useStyles();
  const [deleteOneComment] = useDeleteOneCommentMutation({
    refetchQueries: ["okrBottomComments", "comments"],
  });
  const [solveOneComment] = useSolveOneCommentMutation({
    refetchQueries: ["okrBottomComments", "comments"],
  });
  const [reopenOneComment] = useReopenOneCommentMutation({
    refetchQueries: ["okrBottomComments", "comments"],
  });

  return (
    <Box {...boxProps}>
      {comment.solvedAt === null && comment.previousComment?.id === undefined && (
        <IconButton
          className={classes.resolveIcon}
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            solveOneComment({
              variables: {
                where: {
                  id: comment.id,
                },
              },
            });
          }}
        >
          <CheckCircleOutlineOutlinedIcon />
        </IconButton>
      )}
      {comment.solvedAt && (
        <>
          <Typography variant="caption" color="textSecondary" display="inline">
            解决于：
            {new Date(comment.solvedAt).toLocaleString()}
          </Typography>
          <IconButton
            className={classes.resolveIcon}
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              reopenOneComment({
                variables: {
                  where: {
                    id: comment.id,
                  },
                },
              });
            }}
          >
            <RedoIcon />
          </IconButton>
        </>
      )}
      <DeleteButton
        size="small"
        onClick={() =>
          deleteOneComment({
            variables: {
              where: {
                id: comment.id,
              },
            },
          })
        }
      />
    </Box>
  );
};

export default CommentToolbar;
