import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  Modal,
  Paper,
  Snackbar,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AssignmentIcon from "@material-ui/icons/AssignmentOutlined";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import KeyboardArrowDownOutlinedIcon from "@material-ui/icons/KeyboardArrowDownOutlined";
import KeyboardArrowUpOutlinedIcon from "@material-ui/icons/KeyboardArrowUpOutlined";
import LockIcon from "@material-ui/icons/Lock";
import RateReviewIcon from "@material-ui/icons/RateReviewOutlined";
import UnfoldMoreOutlinedIcon from "@material-ui/icons/UnfoldMoreOutlined";
import { Alert } from "@material-ui/lab";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { StringParam, useQueryParam } from "use-query-params";
import CopyObjectiveButton from "../../components/CopyObjectiveButton";
import DeleteButton from "../../components/DeleteButton";
import EditableText from "../../components/EditableText";
import {
  OkrsQuery,
  useCopyObjectiveToOkrMutation,
  useDeleteOneObjectiveMutation,
  useOkrsForCopyQuery,
  useReweightKeyResultsMutation,
  useUpdateOneObjectiveMutation,
} from "../../generated/graphql";
import { useAuth } from "../../lib/auth";
import { rawStrToPlainText } from "../../lib/draft-js";
import AlignedToOkrs from "./AlignedToOkrs";
import { LocalComment } from "./CommentList";
import KeyResultList from "./KeyResultList";
import { calcObjectiveProgress, floor } from "./math";

const useStyles = makeStyles((theme) => ({
  author: {
    display: "flex",
    alignItems: "center",
    marginLeft: 8,
    marginRight: 8,
    padding: 4,
  },
  objective: {
    position: "relative",
    marginTop: theme.spacing(2),
  },
  list: {
    flex: 1,
  },
  listItem: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingTop: 0,
    paddingBottom: 0,
    "& .MuiSvgIcon-root": {
      opacity: 0,
      transition: "300ms",
    },
    "&:hover .MuiSvgIcon-root": {
      opacity: 1,
    },
  },
  mark: {
    opacity: "1 !important",
  },
  objectiveItem: {
    paddingTop: 0,
    paddingBottom: 0,
    borderBottom: "none",
  },
  alignedWith: {
    height: 30,
    lineHeight: "30px",
    borderBottom: `1px solid ${theme.palette.divider}`,
    color: theme.palette.text.hint,
  },
  cell: {
    width: 82,
    lineHeight: "30px",
  },
  operaionCell: {
    width: 40,
  },
  header: {
    color: theme.palette.text.hint,
  },
  orderIcon: {
    cursor: "pointer",
  },
  saveButton: {
    marginRight: "5px",
  },
  copyModal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "400px",
    background: "#fff",
    borderRadius: "5px",
    padding: "10px",
  },
  okrItem: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    borderRadius: "5px",
    "&:hover": {
      cursor: "pointer",
      color: "#fff",
      background: theme.palette.secondary.main,
    },
  },
  modalOkrName: {
    width: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  review: {
    fontSize: "0.8rem",
    color: "#505e74",
    paddingLeft: 5,
    marginBottom: 10,
    borderLeft: "1px solid #d9d9d9",
  },
}));

export interface Weight {
  id: string;
  weights: Array<{ id: string; weight: string }>;
}

interface ObjectiveListProps {
  readOnly: boolean;
  okr: OkrsQuery["okrs"][0];
  sortedObjectives: OkrsQuery["okrs"][0]["objectives"];
  setLocalComment: React.Dispatch<React.SetStateAction<LocalComment | null>>;
}

const ObjectiveList = (props: ObjectiveListProps) => {
  const classes = useStyles();
  const { user } = useAuth();

  const [, setActiveKey] = useQueryParam("active-comment", StringParam);
  const [author, setAuthor] = useQueryParam("author", StringParam);
  const authorId = author || user?.id;

  const { readOnly, okr, sortedObjectives, setLocalComment } = props;

  const [isCopyModalVisible, setIsCopyModalVisible] = useState(false);
  const [copyMessageVisible, setCopyMessageVisible] = useState(false);
  const [copyObjectiveId, setCopyObjectiveId] = useState<string>();

  const { data: usersAllOkr } = useOkrsForCopyQuery({
    variables: { where: { author: { id: { equals: user?.id } } } },
  });

  const [
    copyObjectiveToOkr,
    { loading: copyLoading, error: copyError },
  ] = useCopyObjectiveToOkrMutation({
    refetchQueries: ["okrs"],
  });

  const [updateOneObjective] = useUpdateOneObjectiveMutation();

  const [deleteOneObjective] = useDeleteOneObjectiveMutation({
    refetchQueries: ["okrs"],
  });

  const [reweightKeyResults] = useReweightKeyResultsMutation();

  const [krWeightOrders, setKrWeightOrders] = useState<
    Record<string, "asc" | "desc" | undefined>
  >({});
  const [weights, setWeights] = useState<Weight | null>(null);
  const [krLocalOrders, setKrLocalOrders] = useState<string[] | null>(null);

  useEffect(() => {
    if (authorId) {
      setAuthor(authorId);
    }
  }, []);

  return (
    <React.Fragment>
      <Droppable droppableId={`${okr.id}-okr`} type="objective">
        {(objectiveDropProvided) => (
          <div
            ref={objectiveDropProvided.innerRef}
            {...objectiveDropProvided.droppableProps}
          >
            {sortedObjectives.map((objective, objectiveIndex) => {
              const sortedKeyResults = objective.keyResults
                .slice()
                .sort((a, b) => {
                  // use local weight order
                  const krWeightOrder = krWeightOrders[objective.id || ""];
                  if (krWeightOrder) {
                    return (
                      ((a.weight || 0) - (b.weight || 0)) *
                      (krWeightOrder === "asc" ? 1 : -1)
                    );
                  }
                  // use local order
                  if (krLocalOrders) {
                    return (
                      krLocalOrders.findIndex((v) => v === a.id) -
                      krLocalOrders.findIndex((v) => v === b.id)
                    );
                  }
                  // use real order
                  if (a.order && b.order) {
                    return a.order - b.order;
                  }
                  return 1;
                });
              const objectiveProgress = calcObjectiveProgress(sortedKeyResults);
              const currentTotalWeight = (weights?.weights || []).reduce(
                (prev, cur) => prev + (parseFloat(cur.weight) || 0),
                0
              );
              const isWeightsValid = Math.abs(100 - currentTotalWeight) < 1;

              return (
                <Draggable
                  key={objective.id}
                  index={objectiveIndex}
                  draggableId={objective.id || ""}
                  isDragDisabled={readOnly}
                >
                  {(objectiveDragProvided) => (
                    <Paper
                      elevation={2}
                      className={classes.objective}
                      innerRef={objectiveDragProvided.innerRef}
                      {...objectiveDragProvided.draggableProps}
                    >
                      <Box p={1} mt={1} display="flex">
                        <Box
                          pt={2}
                          mr={1}
                          {...objectiveDragProvided.dragHandleProps}
                        >
                          {!readOnly && <DragIndicatorIcon color="disabled" />}
                          <AssignmentIcon color="primary" />
                        </Box>
                        <List className={classes.list}>
                          <Box
                            className={classes.header}
                            pl={2}
                            pr={2}
                            display="flex"
                            justifyContent="space-between"
                          >
                            <AlignedToOkrs
                              type="ALIGNED_TO"
                              readOnly={readOnly}
                              baseObjectiveId={objective.id}
                            />
                            <Box display="flex">
                              <Box className={classes.cell}>进度</Box>
                              <Box className={classes.cell}>
                                {weights?.id === objective.id ? (
                                  <Box display="flex">
                                    <Button
                                      className={classes.saveButton}
                                      size="small"
                                      variant="contained"
                                      color="primary"
                                      disabled={!isWeightsValid}
                                      onClick={() => {
                                        reweightKeyResults({
                                          variables: {
                                            where: {
                                              id: objective.id,
                                            },
                                            data: {
                                              weights:
                                                weights?.weights.map((w) => ({
                                                  id: w.id,
                                                  weight: parseFloat(w.weight),
                                                })) || [],
                                            },
                                          },
                                        }).then(() => setWeights(null));
                                      }}
                                    >
                                      {isWeightsValid
                                        ? "保存"
                                        : `${currentTotalWeight.toFixed(
                                            2
                                          )}/100`}
                                    </Button>
                                    <Button
                                      size="small"
                                      variant="contained"
                                      color="secondary"
                                      onClick={() => {
                                        const currentWeigths =
                                          weights?.weights || [];
                                        const avgWeight = floor(
                                          100 / currentWeigths.length,
                                          2
                                        );
                                        setWeights({
                                          id: objective.id || "",
                                          weights: currentWeigths.map((w) => ({
                                            id: w.id,
                                            weight: String(avgWeight),
                                          })),
                                        });
                                      }}
                                    >
                                      均分
                                    </Button>
                                    <Button
                                      size="small"
                                      onClick={() => setWeights(null)}
                                    >
                                      取消
                                    </Button>
                                  </Box>
                                ) : (
                                  <Box
                                    className={classes.orderIcon}
                                    display="flex"
                                    alignItems="center"
                                    onClick={() => {
                                      const objectvieId = objective.id || "";
                                      switch (krWeightOrders[objectvieId]) {
                                        case undefined:
                                          setKrWeightOrders({
                                            ...krWeightOrders,
                                            [objectvieId]: "asc",
                                          });
                                          break;
                                        case "asc":
                                          setKrWeightOrders({
                                            ...krWeightOrders,
                                            [objectvieId]: "desc",
                                          });
                                          break;
                                        case "desc":
                                          setKrWeightOrders({
                                            ...krWeightOrders,
                                            [objectvieId]: undefined,
                                          });
                                          break;
                                        default:
                                          break;
                                      }
                                    }}
                                  >
                                    权重
                                    {krWeightOrders[objective.id || ""] ===
                                    undefined ? (
                                      <UnfoldMoreOutlinedIcon />
                                    ) : krWeightOrders[objective.id || ""] ===
                                      "asc" ? (
                                      <KeyboardArrowUpOutlinedIcon color="primary" />
                                    ) : (
                                      <KeyboardArrowDownOutlinedIcon color="primary" />
                                    )}
                                  </Box>
                                )}
                              </Box>
                              <Box className={classes.operaionCell}></Box>
                              <Box className={classes.operaionCell}></Box>
                            </Box>
                          </Box>
                          <ListItem
                            className={clsx(
                              classes.listItem,
                              classes.objectiveItem
                            )}
                          >
                            {objective.private ? (
                              <LockIcon className={classes.mark} />
                            ) : (
                              ""
                            )}
                            <EditableText
                              rawStr={objective.description || ""}
                              placeholder="为 Objective 添加描述"
                              readOnly={readOnly}
                              onBlur={(raw) => {
                                updateOneObjective({
                                  variables: {
                                    data: {
                                      description: JSON.stringify(raw),
                                    },
                                    where: {
                                      id: objective.id,
                                    },
                                  },
                                });
                              }}
                              allowComment
                              onComment={(rawState) => {
                                setLocalComment({
                                  type: "objectiveDescription",
                                  targetId: objective.id || "",
                                  rawState,
                                });
                                setActiveKey("local", "replaceIn");
                              }}
                            />
                            <Box display="flex" alignItems="center">
                              <Box className={classes.cell}>
                                {floor(objectiveProgress, 1)}%
                              </Box>
                              <Box className={classes.cell}>100%</Box>
                              <Box className={classes.operaionCell}>
                                {!readOnly && (
                                  <DeleteButton
                                    onClick={() =>
                                      deleteOneObjective({
                                        variables: {
                                          where: {
                                            id: objective.id,
                                          },
                                        },
                                      })
                                    }
                                  />
                                )}
                              </Box>
                              <Box className={classes.operaionCell}>
                                <CopyObjectiveButton
                                  onClick={() => {
                                    setIsCopyModalVisible(true);
                                    setCopyObjectiveId(objective.id);
                                  }}
                                />
                              </Box>
                            </Box>
                          </ListItem>
                          <Box
                            display="flex"
                            alignItems="center"
                            pl={2}
                            className={classes.alignedWith}
                          >
                            <AlignedToOkrs
                              type="ALIGNED_WITH"
                              readOnly={readOnly}
                              baseObjectiveId={objective.id}
                            />
                          </Box>
                          <KeyResultList
                            readOnly={readOnly}
                            objectiveId={objective.id}
                            sortedKeyResults={sortedKeyResults}
                            setLocalComment={setLocalComment}
                            setKrLocalOrders={setKrLocalOrders}
                            weights={weights}
                            setWeights={setWeights}
                          />
                        </List>
                      </Box>
                      <Divider />
                      <Box p={1} mt={1} display="flex">
                        <Box mr={1}>
                          <RateReviewIcon color="disabled" />
                        </Box>
                        <Box pl={2} flex="1">
                          <Typography variant="caption">进度记录</Typography>
                          <Box mt={1} className={classes.review}>
                            <EditableText
                              rawStr={objective.review?.description || ""}
                              readOnly={readOnly}
                              onBlur={(raw) => {
                                updateOneObjective({
                                  variables: {
                                    data: {
                                      review: JSON.stringify(raw),
                                    },
                                    where: {
                                      id: objective.id,
                                    },
                                  },
                                });
                              }}
                              placeholder="保持更新，让小伙伴们看到你的进度(键入 [] 开始使用多选框)"
                              allowMention
                              allowComment
                              onComment={(rawState) => {
                                setLocalComment({
                                  type: "reviewDescription",
                                  targetId: objective.review?.id || "",
                                  rawState,
                                });
                                setActiveKey("local", "replaceIn");
                              }}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Paper>
                  )}
                </Draggable>
              );
            })}
            {objectiveDropProvided.placeholder}
          </div>
        )}
      </Droppable>

      <Modal
        open={isCopyModalVisible}
        onClose={() => setIsCopyModalVisible(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box className={classes.copyModal}>
          <Typography id="modal-title" color="textPrimary">
            请选择要复制到的 OKR
          </Typography>
          <List id="modal-description">
            {usersAllOkr?.okrs?.map((okr) => (
              <ListItem
                key={okr.id}
                disabled={copyLoading}
                className={classes.okrItem}
                onClick={async () => {
                  if (copyObjectiveId) {
                    await copyObjectiveToOkr({
                      variables: {
                        data: {
                          objectiveId: copyObjectiveId,
                          targetOkrId: okr.id,
                        },
                      },
                    });
                    setCopyMessageVisible(true);
                    setIsCopyModalVisible(false);
                  }
                }}
              >
                <Typography className={classes.modalOkrName}>
                  {rawStrToPlainText(okr.name)}
                </Typography>
              </ListItem>
            ))}
          </List>
        </Box>
      </Modal>

      <Snackbar
        open={copyMessageVisible}
        autoHideDuration={3000}
        onClose={() => setCopyMessageVisible(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => setCopyMessageVisible(false)}
          severity={copyError ? "error" : "success"}
        >
          {copyError ? "复制失败" : "复制成功"}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
};

export default ObjectiveList;
