import {
  Box,
  Button,
  ButtonGroup,
  Popover,
  makeStyles,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import { StringParam, useQueryParam } from "use-query-params";
import {
  PeriodsQuery,
  QuarterType,
  SortOrder,
  usePeriodsQuery,
  useUpsertOneGlanceOverHistoryMutation,
} from "../../generated/graphql";
import { useAuth } from "../../lib/auth";
import { uniq } from "lodash";

type Period = PeriodsQuery["periods"][0];

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  popoverContent: {
    pointerEvents: "auto",
    maxHeight: 200,
    overflowY: "auto",
  },
  listItem: {
    height: 35,
  },
}));

const PeriodsTabs: React.FC = () => {
  const classes = useStyles();
  const [periodId, setPeriodId] = useQueryParam("period", StringParam);
  const [authorId] = useQueryParam("author", StringParam);
  const { user: currentUser } = useAuth();
  const location = useLocation();
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [periods, setPeriods] = useState<{
    beforeYears: number[];
    currentYearPeriods: Period[];
    afterYears: number[];
  }>({ beforeYears: [], currentYearPeriods: [], afterYears: [] });

  const [isLeftPopOpen, setIsLeftPopOpen] = useState(false);
  const [isRightPopOpen, setIsRightPopOpen] = useState(false);
  const leftButton = useRef(null);
  const rightButton = useRef(null);

  const [upsertGlanceOverHistory] = useUpsertOneGlanceOverHistoryMutation({
    refetchQueries: ["getUserGlanceOverHistories"],
  });

  const handleUpsertGlanceOverHistory = (currentPeriodId: string) => {
    if (
      currentUser &&
      authorId &&
      authorId !== currentUser.id &&
      currentPeriodId
    ) {
      upsertGlanceOverHistory({
        variables: {
          data: {
            okrAuthorId: authorId,
            okrPeriodId: currentPeriodId,
            pathModule: location.pathname,
          },
        },
      });
    }
  };

  const resetPeriodId = (periodId: string) => {
    setPeriodId(periodId);
    handleUpsertGlanceOverHistory(periodId);
  };

  const { data } = usePeriodsQuery({
    variables: {
      orderBy: [{ year: SortOrder.Desc }, { quarter: SortOrder.Desc }],
    },
  });

  const getPeriodsByTargetYear = (
    targetYear: number,
    allPeriods: PeriodsQuery["periods"]
  ) => {
    return {
      beforeYears: uniq(
        allPeriods
          .filter((p) => p.year && p.year < targetYear)
          .map((p) => p.year as number)
      ),
      currentYearPeriods: allPeriods
        .filter((p) => p.year === targetYear)
        .sort((start, next) => {
          const order = [
            QuarterType.FullYear,
            QuarterType.Q1,
            QuarterType.Q2,
            QuarterType.Q3,
            QuarterType.Q4,
          ];
          if (start.quarter && next.quarter) {
            return order.indexOf(start.quarter) - order.indexOf(next.quarter);
          }

          return 1;
        }),
      afterYears: uniq(
        allPeriods
          .filter((p) => p.year && p.year > targetYear)
          .map((p) => p.year as number)
      ),
    };
  };

  const handleYear = (year: number) => {
    setCurrentYear(year);
    const periods = getPeriodsByTargetYear(year, data?.periods || []);
    setPeriods(periods);
    periods.currentYearPeriods.length &&
      resetPeriodId(periods.currentYearPeriods[0].id);
  };

  useEffect(() => {
    const allPeriods = data?.periods || [];

    if (!periodId) {
      const periods = getPeriodsByTargetYear(currentYear, allPeriods);

      setPeriods(periods);
      // 根据当前时间计算应该展示的 period
      if (periods.currentYearPeriods.length) {
        const currentMonth = new Date().getMonth();
        let currentPeriodId: undefined | string =
          periods.currentYearPeriods[0].id;
        if (currentMonth < 3) {
          currentPeriodId = periods.currentYearPeriods.find(
            (p) => p.year === currentYear && p.quarter === QuarterType.Q1
          )?.id;
        } else if (currentMonth >= 3 && currentMonth < 6) {
          currentPeriodId = allPeriods.find(
            (p) => p.year === currentYear && p.quarter === QuarterType.Q2
          )?.id;
        } else if (currentMonth >= 6 && currentMonth < 9) {
          currentPeriodId = allPeriods.find(
            (p) => p.year === currentYear && p.quarter === QuarterType.Q3
          )?.id;
        } else if (currentMonth >= 9 && currentMonth < 12) {
          currentPeriodId = allPeriods.find(
            (p) => p.year === currentYear && p.quarter === QuarterType.Q4
          )?.id;
        }
        currentPeriodId && resetPeriodId(currentPeriodId);
      }
    } else if (data && !data.periods.map((x) => x.id).includes(periodId)) {
      setPeriodId(undefined);
    } else {
      const targetYear =
        allPeriods.find((p) => p.id === periodId)?.year || currentYear;
      const periods = getPeriodsByTargetYear(targetYear, allPeriods);
      setPeriods(periods);
      setCurrentYear(targetYear);
    }
  }, [data, periodId]);

  return (
    <Box>
      <ButtonGroup
        size="small"
        variant="outlined"
        aria-label="outlined button group"
      >
        <Button
          ref={leftButton}
          onMouseEnter={() => setIsLeftPopOpen(true)}
          onMouseLeave={() => setIsLeftPopOpen(false)}
          disabled={!periods.beforeYears.length}
          onClick={() => handleYear(currentYear - 1)}
        >
          <KeyboardArrowLeftIcon />
        </Button>
        {periods.currentYearPeriods.map((period) => (
          <Button
            key={period.id}
            color={periodId === period.id ? "primary" : "default"}
            onClick={() => resetPeriodId(period.id)}
          >
            {period.name}
          </Button>
        ))}
        <Button
          ref={rightButton}
          onMouseEnter={() => setIsRightPopOpen(true)}
          onMouseLeave={() => setIsRightPopOpen(false)}
          disabled={!periods.afterYears.length}
          onClick={() => handleYear(currentYear + 1)}
        >
          <KeyboardArrowRightIcon />
        </Button>
      </ButtonGroup>
      <Popover
        className={classes.popover}
        classes={{
          paper: classes.popoverContent,
        }}
        open={isLeftPopOpen}
        anchorEl={leftButton.current}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={{
          onMouseEnter: () => setIsLeftPopOpen(true),
          onMouseLeave: () => setIsLeftPopOpen(false),
        }}
      >
        <List component="nav" aria-label="secondary mailbox folders">
          {periods.beforeYears.map((year) => (
            <ListItem
              className={classes.listItem}
              key={`period-${year}`}
              button
              onClick={() => handleYear(year)}
            >
              <ListItemText primary={year} />
            </ListItem>
          ))}
        </List>
      </Popover>
      <Popover
        className={classes.popover}
        classes={{
          paper: classes.popoverContent,
        }}
        open={isRightPopOpen}
        anchorEl={rightButton.current}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={{
          onMouseEnter: () => setIsRightPopOpen(true),
          onMouseLeave: () => setIsRightPopOpen(false),
        }}
      >
        <List component="nav" aria-label="secondary mailbox folders">
          {periods.afterYears.map((year) => (
            <ListItem
              className={classes.listItem}
              key={`period-${year}`}
              button
              onClick={() => handleYear(year)}
            >
              <ListItemText primary={year} />
            </ListItem>
          ))}
        </List>
      </Popover>
    </Box>
  );
};

export default PeriodsTabs;
