import {
  Avatar,
  Badge,
  Box,
  makeStyles,
  Tooltip,
  Typography,
  Button,
} from "@material-ui/core";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { AnchorPosition, ArcherContainer, ArcherElement } from "react-archer";
import { StringParam, useQueryParam } from "use-query-params";
import EditableText from "../../components/EditableText";
import { useOkrsForTreeQuery } from "../../generated/graphql";
import { useAuth } from "../../lib/auth";
import { rawStrToPlainText } from "../../lib/draft-js";
import RealtimeUsers from "../user/RealtimeUsers";
import UserSelect from "../user/UserSelect";
import PeriodsTabs from "./PeriodsTabs";

const useStyles = makeStyles((theme) => ({
  objective: {
    position: "relative",
    boxShadow: theme.shadows[2],
    padding: theme.spacing(2),
    borderRadius: 4,
    marginBottom: theme.spacing(2),
    width: 350,
  },
  avatar: {
    width: 24,
    height: 24,
    marginRight: theme.spacing(1),
  },
  wrapper: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    marginRight: "10px",
  },
  leftAddButton: {
    position: "absolute",
    left: "-0px",
    top: "51%",
    color: "#3991ff",
    "&": {
      cursor: "pointer",
    },
  },
  rightAddButton: {
    position: "absolute",
    right: "-0px",
    top: "51%",
    color: "#3991ff",
    "&": {
      cursor: "pointer",
    },
  },
  leftRemoveButton: {
    position: "absolute",
    left: "-12px",
    top: "37%",
    color: "#3991ff",
    "&": {
      cursor: "pointer",
    },
  },
  rightRemoveButton: {
    position: "absolute",
    right: "-12px",
    top: "37%",
    color: "#3991ff",
    "&": {
      cursor: "pointer",
    },
  },
  krList: {
    paddingLeft: 15,
  },
  marginLeft: {
    marginLeft: "5px",
  },
}));

enum ColumnMarker {
  LEFT = "LEFT",
  RIGHT = "RIGHT",
}

const OkrTree: React.FC = () => {
  const classes = useStyles();
  const { user } = useAuth();

  const [periodId] = useQueryParam("period", StringParam);
  const [_authorId] = useQueryParam("author", StringParam);
  const authorId = _authorId || user?.id;

  const [visibleAlignedToIds, setVisibleAlignedToIds] = useState<Set<string>>(
    new Set()
  );
  const [visibleAlignedWithIds, setVisibleAlignedWithIds] = useState<
    Set<string>
  >(new Set());

  const [refresher, setRefresher] = useState(0);
  useEffect(() => {
    setRefresher(refresher + 1);
  }, [visibleAlignedToIds, visibleAlignedWithIds, periodId, _authorId]);

  const { data: okrsData } = useOkrsForTreeQuery({
    skip: !periodId,
    variables: {
      where: {
        period: {
          id: {
            equals: periodId,
          },
        },
        author: {
          id: {
            equals: authorId,
          },
        },
      },
    },
    fetchPolicy: "cache-and-network",
  });

  const openAllOkrs = () => {
    // 减少抖动
    if (
      !(
        visibleAlignedToIds.size === okr?.objectives.length &&
        visibleAlignedWithIds.size === okr?.objectives.length
      )
    ) {
      const allObjIdSet = new Set<string>();
      okr?.objectives.forEach((obj) => {
        allObjIdSet.add(obj.id);
      });
      setVisibleAlignedToIds(new Set(allObjIdSet));
      setVisibleAlignedWithIds(new Set(allObjIdSet));
    }
  };

  const closeAllOkrs = () => {
    // 减少抖动
    if (visibleAlignedToIds.size || visibleAlignedWithIds.size) {
      setVisibleAlignedToIds(new Set());
      setVisibleAlignedWithIds(new Set());
    }
  };

  const okr = okrsData?.okrs[0];

  return (
    <Box width="100%">
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <UserSelect />
        <div className={classes.wrapper}>
          <Button
            className={classes.marginLeft}
            variant="outlined"
            size="small"
            onClick={openAllOkrs}
          >
            展开全部
          </Button>
          <Button
            className={classes.marginLeft}
            variant="outlined"
            size="small"
            onClick={closeAllOkrs}
          >
            折叠全部
          </Button>
          <RealtimeUsers />
          <PeriodsTabs />
        </div>
      </Box>
      <ArcherContainer key={refresher} strokeColor="#7fa9c7">
        <Box
          p={4}
          display="flex"
          width="100%"
          justifyContent="space-around"
          position="relative"
        >
          <div>
            {_.uniqBy(
              _.flatten(
                okr?.objectives
                  .filter((v) => visibleAlignedToIds.has(v.id))
                  .map((objective) => {
                    return objective.alignedTo.map((alignedObjective) => {
                      return {
                        id: alignedObjective.id,
                        elem: (
                          <ArcherElement
                            id={ColumnMarker.LEFT + alignedObjective.id}
                          >
                            <Tooltip
                              title={
                                <ol className={classes.krList}>
                                  {alignedObjective.keyResults.map((kr) => (
                                    <li>{rawStrToPlainText(kr.description)}</li>
                                  ))}
                                </ol>
                              }
                              interactive
                              arrow
                            >
                              <Box
                                key={alignedObjective.id}
                                className={classes.objective}
                                data-objective-id={alignedObjective.id}
                              >
                                <Box display="flex" alignItems="center" mb={1}>
                                  <Avatar
                                    className={classes.avatar}
                                    src={
                                      alignedObjective.okr?.author?.avatar || ""
                                    }
                                  />
                                  <Typography>
                                    {alignedObjective.okr?.author?.name}
                                  </Typography>
                                </Box>
                                <EditableText
                                  readOnly
                                  rawStr={alignedObjective.description || ""}
                                />
                              </Box>
                            </Tooltip>
                          </ArcherElement>
                        ),
                      };
                    });
                  })
              ),
              "id"
            ).map((item) => item.elem)}
          </div>
          <div>
            {okr?.objectives.map((objective) => {
              return (
                <ArcherElement
                  id={objective.id}
                  relations={[
                    ...(visibleAlignedToIds.has(objective.id)
                      ? objective.alignedTo.map((alignedTo) => ({
                          targetId: ColumnMarker.LEFT + alignedTo.id,
                          targetAnchor: "right" as AnchorPosition,
                          sourceAnchor: "left" as AnchorPosition,
                        }))
                      : []),
                    ...(visibleAlignedWithIds.has(objective.id)
                      ? objective.alignedWith.map((alignedWith) => ({
                          targetId: ColumnMarker.RIGHT + alignedWith.id,
                          targetAnchor: "left" as AnchorPosition,
                          sourceAnchor: "right" as AnchorPosition,
                        }))
                      : []),
                  ]}
                >
                  <Tooltip
                    title={
                      <ol className={classes.krList}>
                        {objective.keyResults.map((kr) => (
                          <li>{rawStrToPlainText(kr.description)}</li>
                        ))}
                      </ol>
                    }
                    interactive
                    arrow
                  >
                    <Box
                      key={objective.id}
                      className={classes.objective}
                      data-objective-id={objective.id}
                    >
                      <Box display="flex" alignItems="center" mb={1}>
                        <Avatar
                          className={classes.avatar}
                          src={okr.author?.avatar || ""}
                        />
                        <Typography>{okr.author?.name}</Typography>
                      </Box>
                      <EditableText
                        readOnly
                        rawStr={objective.description || ""}
                      />
                      {visibleAlignedToIds.has(objective.id) ? (
                        <RemoveCircleIcon
                          className={classes.leftRemoveButton}
                          onClick={() => {
                            setVisibleAlignedToIds((ids) => {
                              ids.delete(objective.id);
                              return new Set(ids);
                            });
                          }}
                        />
                      ) : (
                        <Badge
                          className={classes.leftAddButton}
                          badgeContent={objective.alignedTo.length}
                          color="secondary"
                          onClick={() => {
                            setVisibleAlignedToIds((ids) => {
                              ids.add(objective.id);
                              return new Set(ids);
                            });
                          }}
                        />
                      )}
                      {visibleAlignedWithIds.has(objective.id) ? (
                        <RemoveCircleIcon
                          className={classes.rightRemoveButton}
                          onClick={() => {
                            setVisibleAlignedWithIds((ids) => {
                              ids.delete(objective.id);
                              return new Set(ids);
                            });
                          }}
                        />
                      ) : (
                        <Badge
                          className={classes.rightAddButton}
                          badgeContent={objective.alignedWith.length}
                          color="secondary"
                          onClick={() => {
                            setVisibleAlignedWithIds((ids) => {
                              ids.add(objective.id);
                              return new Set(ids);
                            });
                          }}
                        />
                      )}
                    </Box>
                  </Tooltip>
                </ArcherElement>
              );
            })}
          </div>
          <div>
            {_.uniqBy(
              _.flatten(
                okr?.objectives
                  .filter((v) => visibleAlignedWithIds.has(v.id))
                  .map((objective) => {
                    return objective.alignedWith.map((alignedObjective) => {
                      return {
                        id: alignedObjective.id,
                        elem: (
                          <ArcherElement
                            id={ColumnMarker.RIGHT + alignedObjective.id}
                          >
                            <Tooltip
                              title={
                                <ol className={classes.krList}>
                                  {alignedObjective.keyResults.map((kr) => (
                                    <li>{rawStrToPlainText(kr.description)}</li>
                                  ))}
                                </ol>
                              }
                              interactive
                              arrow
                            >
                              <Box
                                key={alignedObjective.id}
                                className={classes.objective}
                                data-objective-id={alignedObjective.id}
                              >
                                <Box display="flex" alignItems="center" mb={1}>
                                  <Avatar
                                    className={classes.avatar}
                                    src={
                                      alignedObjective.okr?.author?.avatar || ""
                                    }
                                  />
                                  <Typography>
                                    {alignedObjective.okr?.author?.name}
                                  </Typography>
                                </Box>
                                <EditableText
                                  readOnly
                                  rawStr={alignedObjective.description || ""}
                                />
                              </Box>
                            </Tooltip>
                          </ArcherElement>
                        ),
                      };
                    });
                  })
              ),
              "id"
            ).map((item) => item.elem)}
          </div>
        </Box>
      </ArcherContainer>
    </Box>
  );
};

export default OkrTree;
